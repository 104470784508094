import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { withRouter, Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useSelector } from 'react-redux'

import DrawerIcon from 'common/icons/index'
import { DropdownHover } from 'components/Dropdowns'
import { getUrlPrefix } from 'utils'
import routeByName from 'constants/routes'
import useUserRole from 'hooks/tableLibrary/useUserRole'
import useUserPermissionGroupsByType from 'hooks/api/useUserPermissionGroupsByType'
import { entityConstants, permissionGroups } from 'constants/index'
import { permissionTypes } from 'constants/permissionGroups'
import { checkPermissions } from 'utils/permissionsUtils'
import Scrollbars from 'components/Scrollbars'
import { compareByProperty } from 'utils/sort'
import { isFeatureAvailable } from 'utils/api/featureAvailability'
import featureConstants, {
  EMAIL_NOTIFICATION_APP_FEATURE,
  EULA_APP_FEATURE,
  featureNames
} from 'constants/featureConstants'
import { LIGHT, colors } from 'theme'
import useFeaturesAvailability from 'hooks/api/useFeaturesAvailability'
import EmailSettingsIcon from 'components/Icons/EmailSettingsIcon'
import CreditCardSettingsIcon from 'components/Icons/CreditCardSettingsIcon'
import { libraryViews } from 'constants/library'
import { libraryViewSelector } from 'selectors/libraryViewSelectors'
import { getLibraryRoute } from 'utils/libraryUtils'
import useProofOfPlaySettings from 'hooks/useProofOfPlaySettings'
import useUserDetails from 'hooks/useUserDetails'

const useStyles = makeStyles(
  ({ palette, type, typography, fontSize, lineHeight, colors }) => ({
    iconButtonWrapper: {
      marginRight: '9px',
      paddingRight: '0',
      borderRightWidth: 1,
      borderRightStyle: 'solid',
      borderRightColor:
        type === LIGHT ? colors.border.light : colors.border.dark
    },
    navigationSubMenu: {
      top: '80%',
      transform: 'rotateX(90deg)',
      transformOrigin: 'top center'
    },
    navigationHoverCard: {
      backgroundColor: palette[type].body.background,
      borderRadius: '10px',
      position: 'absolute',
      top: 0,
      left: 0,
      transitionDuration: '0.7s',
      width: '255px'
    },
    navigationSubMenuList: {
      padding: '0px',
      position: 'relative',
      overflowY: 'visible',
      display: 'grid',
      gridGap: '6px'
    },
    navigationSubMenuItem: {
      padding: '2px 0 0',
      margin: '0px'
    },
    navigationSubMenuText: {
      ...typography.lightText[type],
      fontSize: fontSize.primary,
      lineHeight: lineHeight.primary,
      fontWeight: 600,
      transition: 'opacity .2s',
      whiteSpace: 'nowrap',
      color: colors.highlight
    },
    navigationSubMenuDescriptionText: {
      ...typography.lightText[type],
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      lineClamp: 2,
      boxOrient: 'vertical'
    },
    subMenuItem: {
      padding: 10,
      height: 90,
      alignItems: 'flex-start',
      '&:hover $subMenuIcon': {
        opacity: 1
      }
    },
    subMenuIcon: {
      fontSize: '25px',
      opacity: 0.8,
      transition: 'opacity .2s',
      paddingTop: '5px'
    },
    subMenuIconActive: {
      opacity: 1
    },
    scrollbarRoot: {
      maxHeight: 'calc(100vh - 100px)'
    },
    navigationSubMegaMenu: {
      padding: '20px'
    }
  })
)

const dynamicStyles = makeStyles({
  navigationSubMenuList: {
    gridTemplateColumns: ({ navCols }) =>
      `repeat(${navCols},calc((100% - 12px)/${navCols}))`
  },
  navigationSubMenuText: {
    color: ({ navColor }) => navColor + ' !important'
  }
})

const dropdownFadeTransition = 500

const Drawer = ({
  location,
  customClass,
  color = colors.highlight,
  disabled
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [hidingDropdown, setHidingDropdown] = useState(false)
  const [isHovering, setHoverState] = useState(false)
  const role = useUserRole()
  const [hoverCard, setHoverCard] = useState({
    height: '90px',
    transform: 'translate(0px, 0px)'
  })
  const { client } = useUserDetails()
  const { proofOfPlayOptions } = useProofOfPlaySettings({ client, role })

  const viewSettings = useSelector(libraryViewSelector)
  const view = viewSettings[entityConstants.UserLibrary]

  const readGroups = useUserPermissionGroupsByType(permissionTypes.read)

  const contentAppHelpLibraryRootRoute = useMemo(
    () =>
      viewSettings.ContentAppHelpLibrary === libraryViews.advancedList
        ? getUrlPrefix(routeByName.contentAppHelp.advancedList)
        : getUrlPrefix(routeByName.contentAppHelp.list),
    [viewSettings.ContentAppHelpLibrary]
  )

  const [
    applicationHealthAvailable,
    videoTutorialsAvailable,
    showProofOfPlay
  ] = useFeaturesAvailability(
    featureNames.ApplicationHealth,
    featureNames.VideoTutorials,
    featureNames.ProofOfPlay
  )

  const librariesRoutes = useMemo(
    () => ({
      [entityConstants.EulaTerms]: getLibraryRoute(
        readGroups.includes(permissionGroups.EULA),
        routeByName.eula.root,
        viewSettings[entityConstants.EulaTerms]
      ),
      [entityConstants.ClientLibrary]: getLibraryRoute(
        readGroups.includes(permissionGroups.CLIENT),
        routeByName.clients.root,
        viewSettings[entityConstants.ClientLibrary]
      )
    }),
    [readGroups, viewSettings]
  )

  const menuItems = useMemo(
    () => [
      {
        url: `/${role.role}/dashboard`,
        linkText: t('Dashboard'),
        description: t(
          'Overview of your account with usage metrics, and previews.'
        ),
        iconClassName: 'fa-light fa-list',
        render: checkPermissions(readGroups, [permissionGroups.DASHBOARD])
      },
      {
        url: getUrlPrefix(
          view === libraryViews.list
            ? routeByName.users.list
            : routeByName.users.advancedList
        ),
        linkText: t('Users menu item'),
        description: t('Add new users to your account & assign permissions.'),
        iconClassName: 'fa-regular fa-user',
        render: readGroups.includes(permissionGroups.USER)
      },
      {
        url: `/${role.role}/tags-library`,
        linkText: t('Tags'),
        description: t('Add and manage tags for use in filtering & reports.'),
        iconClassName: 'fa-regular fa-tag',
        render: readGroups.includes(permissionGroups.TAG)
      },
      {
        url: librariesRoutes[entityConstants.ClientLibrary],
        linkText: t('Clients menu item'),
        description: t(
          'Create and manage clients accounts & assign features and permissions'
        ),
        iconClassName: 'fa-regular fa-user-tie',
        render: role.enterprise && readGroups.includes(permissionGroups.CLIENT)
      },
      {
        url: '/system/help-pages-library',
        linkText: t('Help Pages menu item'),
        description: t(
          'Assign knowledgebase articles to individual pages and features'
        ),
        printSystem: true,
        iconClassName: 'fa-regular fa-circle-question',
        render: role.system && readGroups.includes(permissionGroups.HELP_PAGE)
      },
      {
        url: contentAppHelpLibraryRootRoute,
        linkText: t('Content App Help Pages menu item'),
        description: t(
          'Assign knowledgebase articles to individual content app pages'
        ),
        printSystem: true,
        iconClassName: 'fa-regular fa-circle-question',
        render:
          role.system &&
          readGroups.includes(permissionGroups.CONTENT_APP_HELP_PAGE)
      },
      {
        url: '/system/packages-library',
        linkText: t('Packages menu item'),
        description: t(
          'Create and manage feature packs for accounts and devices'
        ),
        printSystem: true,
        iconClassName: 'fa-regular fa-box',
        render:
          role.system &&
          checkPermissions(readGroups, [
            permissionGroups.CLIENT_PACKAGE,
            permissionGroups.BANDWIDTH_PACKAGE,
            permissionGroups.DEVICE_PACKAGE
          ])
      },
      {
        url: routeByName.rolesPermissions.toIndex(),
        linkText: t('Roles And Permissions'),
        description: t('Create and manage user roles and feature permissions'),
        printSystem: true,
        iconClassName: 'fa-regular fa-user-lock',
        render: role.system && readGroups.includes(permissionGroups.ROLES)
      },
      {
        url: librariesRoutes[entityConstants.EulaTerms],
        linkText: t('EULA Terms'),
        description: t('Create and manage user EULA terms and conditions'),
        iconClassName: 'fa-regular fa-user-check',
        render:
          isFeatureAvailable(EULA_APP_FEATURE) &&
          role.system &&
          readGroups.includes(permissionGroups.EULA)
      },
      {
        url: getUrlPrefix(routeByName.notifications.root),
        linkText: t('Notifications Library'),
        description: t('Create and manage automated email notifications'),
        iconComponent: ({ generalStyles }) => (
          <EmailSettingsIcon
            wrapperStyles={{ marginTop: 10 }}
            generalStyles={generalStyles}
          />
        ),
        render:
          isFeatureAvailable(EMAIL_NOTIFICATION_APP_FEATURE) &&
          !role.org &&
          readGroups.includes(permissionGroups.DYNAMIC_EMAIL_NOTIFICATION)
      },
      {
        url: '/system/oem-clients-library',
        linkText: t('OEM Clients menu item'),
        iconClassName: 'fa-light fa-user-group',
        render: false // Hide the page until further notice
        // render: role.system
      },
      {
        url: '/system/channels-library',
        linkText: t('Channels menu item'),
        iconClassName: 'fa-light fa-user-group',
        render: false // Hide the page until further notice
        // render: role.system
      },
      {
        url: routeByName.reseller.client,
        linkText: t('Clients'),
        description: t(
          'Create and manage clients accounts & assign features and permissions'
        ),
        iconClassName: 'fa-regular fa-user-tie',
        render: readGroups.includes(permissionGroups.RESELLER_CLIENT)
      },
      {
        url: routeByName.reseller.clientUser,
        linkText: t('Client Users'),
        description: t(
          'Create and manage client users & assign roles and permissions'
        ),
        render: readGroups.includes(permissionGroups.RESELLER_CLIENT_USER),
        iconClassName: 'fa-regular fa-user'
      },
      {
        url: routeByName.reseller.user,
        linkText: t('Users'),
        description: t(
          'Create and manage users & assign roles and permissions'
        ),
        render: role.reseller, //readGroups.includes(permissionGroups.RESELLER_USER),
        iconClassName: 'fa-regular fa-user'
      },
      {
        url: getUrlPrefix('application-health'),
        linkText: t('Application Health'),
        description: t('Health check status of application endpoints'),
        printSystem: true,
        iconClassName: 'icon-file-statistic-settings-1',
        render:
          applicationHealthAvailable &&
          readGroups.includes(permissionGroups.APP_HEALTH)
      },
      {
        url: role.system
          ? viewSettings.MediaContentSource[featureConstants.VideoTutorials] ===
            libraryViews.advancedList
            ? routeByName[featureConstants.VideoTutorials].advancedList
            : routeByName[featureConstants.VideoTutorials].list
          : getUrlPrefix('tutorial-videos'),
        linkText: t('Tutorial Videos'),
        description: t(
          'A library of sort and informative videos to learn this CMS.'
        ),
        iconClassName: 'fa-sharp fa-regular fa-chalkboard-user',
        render:
          (role.org && videoTutorialsAvailable) ||
          role.system ||
          (role.enterprise && videoTutorialsAvailable)
      },
      {
        url: getUrlPrefix(routeByName.features.root),
        linkText: t('Feature Management'),
        description: t('Manage meta data of content app and features'),
        iconComponent: ({ generalStyles }) => (
          <CreditCardSettingsIcon
            wrapperStyles={{ marginTop: 5 }}
            generalStyles={generalStyles}
          />
        ),
        render: role.system && readGroups.includes(permissionGroups.FEATURE)
      },
      {
        url: getUrlPrefix(routeByName.report.list),
        linkText: t('Reports Library'),
        description: t('Pre-configured and exportable report views.'),
        iconClassName: 'fa-regular fa-file-lines',
        render: role.org && readGroups.includes(permissionGroups.ORG_REPORT)
      },
      {
        url: getUrlPrefix(routeByName.proofOfPlayReports.root),
        linkText: t('Proof of Play Reports'),
        description: t('Pre-configured proof of play report views.'),
        iconClassName: 'icon-folder-document',
        render: !role.system && showProofOfPlay && proofOfPlayOptions
      }
    ],
    [
      viewSettings,
      librariesRoutes,
      readGroups,
      view,
      role,
      t,
      applicationHealthAvailable,
      videoTutorialsAvailable,
      contentAppHelpLibraryRootRoute,
      showProofOfPlay,
      proofOfPlayOptions
    ]
  )

  const cols = useMemo(
    () =>
      Math.min(
        menuItems.filter(item => !item.hasOwnProperty('render') || item.render)
          .length,
        2
      ),
    [menuItems]
  )

  const dynamicClasses = dynamicStyles({
    navColor: color,
    navCols: cols
  })

  const onMouseOver = useCallback(
    index => {
      const x = (index % cols) * 255
      const y = Math.floor(index / cols) * 96
      setHoverCard({
        height: '90px',
        transform: `translate(${x}px, ${y}px)`
      })
    },
    [cols]
  )

  const onResetMouseHover = useCallback(() => {
    setHoverCard({
      height: '0px',
      transform: `translate(0px, 0px)`
    })
  }, [])

  const menuItemsSorted = useMemo(() => {
    return menuItems.sort((a, b) => compareByProperty(a, b, 'linkText'))
  }, [menuItems])

  const menuItemsToRender = useMemo(() => {
    return menuItemsSorted.filter(item =>
      item.hasOwnProperty('render') ? item.render : true
    )
  }, [menuItemsSorted])

  const handleMouseOver = useCallback(() => {
    const resultIndex = menuItemsToRender.findIndex(
      items => items.url === location.pathname
    )
    if (resultIndex !== -1) {
      onMouseOver(resultIndex)
    } else {
      onResetMouseHover()
    }
  }, [location.pathname, menuItemsToRender, onMouseOver, onResetMouseHover])

  useEffect(() => {
    handleMouseOver()
    // eslint-disable-next-line
  }, [role, location.pathname])

  const scrollHeight = useMemo(() => {
    return 96 * Math.ceil(menuItemsToRender.length / cols) + 40
    // eslint-disable-next-line
  }, [menuItemsToRender, cols])

  const onLinkClick = useCallback(() => {
    setHidingDropdown(true)
    setTimeout(() => {
      setHidingDropdown(false)
    }, 2 * dropdownFadeTransition)
  }, [])

  return (
    <div
      className={classNames(classes.iconButtonWrapper, customClass)}
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
    >
      <DropdownHover
        forceHidden={disabled || hidingDropdown || !menuItemsToRender.length}
        dropSide="bottomRight"
        menuContainerClassName={classes.navigationSubMenu}
        dropdownContainerClassName={classes.dropdownContainer}
        buttonWrapClassName={classes.dropdownButton}
        ButtonComponent={
          <IconButton>
            <DrawerIcon hovering={isHovering} />
          </IconButton>
        }
        MenuComponent={
          <Scrollbars
            className={classes.scrollbarRoot}
            style={{
              height: scrollHeight,
              width: 280 * cols + (cols === 1 ? 15 : 0)
            }}
            onMouseLeave={handleMouseOver}
          >
            <div className={classes.navigationSubMegaMenu}>
              <List
                className={classNames(
                  classes.navigationSubMenuList,
                  dynamicClasses.navigationSubMenuList
                )}
              >
                <div
                  className={classes.navigationHoverCard}
                  style={{
                    height: hoverCard.height,
                    transform: hoverCard.transform
                  }}
                />
                {menuItemsToRender.map((item, index) => {
                  const IconComponent = item?.iconComponent

                  return (
                    <ListItem
                      className={classes.subMenuItem}
                      key={item + index}
                      component={RouterLink}
                      onClick={onLinkClick}
                      to={item.url}
                      disableGutters
                      onMouseOver={() => onMouseOver(index)}
                    >
                      {IconComponent ? (
                        <ListItemIcon>
                          <IconComponent
                            generalStyles={{ color }}
                            // wrapperStyles={{ marginTop: 10 }}
                          />
                        </ListItemIcon>
                      ) : (
                        item.iconClassName && (
                          <ListItemIcon>
                            <i
                              style={{ color }}
                              className={classNames(
                                item.iconClassName,
                                classes.subMenuIcon,
                                {
                                  [classes.subMenuIconActive]:
                                    location.pathname === item.url
                                }
                              )}
                            />
                          </ListItemIcon>
                        )
                      )}

                      <ListItemText
                        className={classes.navigationSubMenuItem}
                        primary={item.linkText}
                        primaryTypographyProps={{
                          className: classNames(
                            classes.navigationSubMenuText,
                            dynamicClasses.navigationSubMenuText
                          )
                        }}
                        secondary={
                          <>
                            {item.description}
                            {item.printSystem && <b> ({t('System')}).</b>}
                          </>
                        }
                        secondaryTypographyProps={{
                          className: classes.navigationSubMenuDescriptionText
                        }}
                      />
                    </ListItem>
                  )
                })}
              </List>
            </div>
          </Scrollbars>
        }
      />
    </div>
  )
}

export default withRouter(Drawer)
