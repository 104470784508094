import React from 'react'
import { makeStyles } from '@material-ui/core'
import moment from 'moment/moment'

import LibraryGroupChips from 'components/LibraryGroupChips'

const useStyles = makeStyles(theme => ({
  root: {
    lineHeight: 'normal'
  }
}))

const GroupsCell = ({ data, column, colDef }) => {
  const classes = useStyles()

  const { group, licenseExpiration } = data
  const { actualWidth, sort } = column

  return (
    <LibraryGroupChips
      groups={group}
      descend={sort === 'desc'}
      maxWidth={actualWidth - 40}
      rootClasses={classes.root}
      isDevicesLibrary={colDef.isDevicesLibrary}
      maxDisplayedGroups={colDef.maxDisplayedGroups || 5}
      isLicenseExpired={
        licenseExpiration && moment(licenseExpiration).isBefore(moment())
      }
    />
  )
}

export default GroupsCell
