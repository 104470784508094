import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import update from 'immutability-helper'
import { useHistory } from 'react-router'
import { withStyles, Grid, Typography, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { useDrag } from 'react-dnd'
import { Share } from '@material-ui/icons'
import _get from 'lodash/get'
import { _isEmpty } from 'utils/lodash'

import DeviceMoreInfoCard from './DeviceMoreInfoCard'
import { DeviceMediaModal, DeviceVirtualPlayerModal } from 'components/Modal'
import DeviceCardAlerts from 'components/Pages/Admin/DeviceLibrary/DeviceCardAlerts'
import LegacyIndicator from 'components/Pages/Admin/DeviceLibrary/LegacyIndicator'

import { capitalize, getUrlPrefix } from 'utils/index'
import { dndConstants, permissionNames, routeByName } from 'constants/index'
import { getDeleteConfirmationMessage } from 'utils/generalUtils'
import { getDeviceTimeWithOffset } from 'utils/getDeviceTimeWithOffset'
import NoteIndicator from 'components/NoteIndicator'
import { DateTimeView } from 'components/TableLibrary'
import { viewTypes } from 'constants/libraryConstants'
import { getAbbreviatedState } from 'utils/provinces'
import { TextWithTooltip } from 'components/Typography'
import { isLegacyBrowserVersion } from 'utils/deviceUtils'
import { deviceStatus, VIRTUAL_DEVICE_ALIAS } from 'constants/deviceConstants'
import usePermissions from 'hooks/api/usePermissions'
import LibraryGridCard from 'components/Card/LibraryGridCard'
import { useUserRole } from 'hooks/tableLibrary'
import DeviceConnectivityIcon from 'components/Icons/DeviceConnectivityIcon'
import useConfirmation from 'hooks/useConfirmation'
import DeviceRebootModal from 'components/Modal/DeviceRebootModal'

const styles = ({ palette, type, typography, colors, spacing }) => ({
  faded: {
    opacity: 0.2
  },
  cursorGrab: {
    cursor: 'grab'
  },
  cardHeader: {
    height: palette[type].card.greyHeader.doubleLineHeight,
    padding: '10px 20px 5px',
    marginBottom: 0,
    backgroundColor: palette[type].deviceCard.header.background,
    borderRadius: '7px 7px 0 0'
  },
  backgroundOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 20px)',
    margin: '10px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    transition: 'opacity 1s ease-out'
  },
  detailRow: {
    minHeight: '32px',
    borderBottom: `1px solid ${palette[type].deviceCard.row.background}`
  },
  detailLabel: {
    ...typography.lightText[type]
  },
  detailValue: {
    ...typography.darkAccent[type]
  },
  statusActionWrap: {
    padding: '0px 4px',
    marginRight: 10
  },
  actionBtnIcon: {
    fontSize: '24px',
    color: '#74809a'
  },
  rebootDeviceColor: {
    color: '#d31712 !important'
  },
  cardTitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cardTitleIcon: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    maxWidth: 35,
    maxHeight: 35,
    marginRight: 15,
    marginBottom: 6
  },
  cardHeaderText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  cardTitle: {
    fontSize: 16,
    lineHeight: '1em'
  },
  cardSubTitle: {
    fontSize: '14px'
  },
  headerWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '54px'
  },
  sleepModeIcon: {
    color: 'rgba(186,104,200,1)'
  },
  text: {
    ...typography.darkAccent[type]
  },
  textSmall: {
    ...typography.subtitle[type],
    textAlign: 'end'
  },
  contentWrapAlign: {
    textAlign: 'end'
  },
  locationImage: {
    maxWidth: '100%',
    height: 'auto'
  },
  gridCardRowLineHeight: {
    lineHeight: '1em'
  },
  gridCardRowPadding: {
    padding: '5px 0'
  },
  hide: {
    visibility: 'hidden'
  },
  locationValueWrapper: {
    zIndex: 1
  },
  content: {
    flexGrow: '1',
    padding: '20px 20px 45px',
    position: 'relative',
    height: 269,
    transition: 'height 0.5s',

    '& :last-child': {
      borderBottom: 'none'
    }
  },
  'content-small': {
    padding: 20,
    height: 257
  },
  'content-smaller': {
    padding: 20,
    height: 227
  },
  'content-smallest': {
    padding: 20,
    height: 172
  },
  'content-auto': {
    padding: 20,
    height: 'auto'
  },
  'content-original-normal': {
    height: 214
  },
  'content-original-small': {
    height: 202
  },
  'content-original-smaller': {
    height: 172
  },
  'content-original-smallest': {
    height: 115
  },
  'content-original-auto': {
    height: 'auto'
  },
  customContent: {
    padding: `${spacing(1)}px 20px`
  },
  cardRoot: {
    '&:hover': {
      backgroundColor: palette[type].tableLibrary.body.row.stickyHover,
      '& $content': {
        height: 214
      },
      '& $content-small': {
        height: 202
      },
      '& $content-smaller': {
        height: 172
      },
      '& $content-smallest': {
        height: 115
      }
    }
  },

  cardFooter: {
    background: palette[type].deviceCard.footer.background
  },
  libraryCardWrapper: {
    flexGrow: 1,
    opacity: 1
  },
  cardAlertsWrapper: {
    position: 'relative'
  },
  shareIcon: {
    color: colors.hightlightedicons
  }
})

const dynamicStyles = makeStyles({
  cardRoot: ({ color, status }) => ({
    borderLeft: `5px solid ${
      status === 'Active' ? color : '#949494'
    } !important`
  }),
  deviceTypeIcon: ({ color }) => ({
    color
  })
})

const DeviceCard = ({
  t,
  classes,
  index,
  device,
  selected = [],
  setSelected,
  handleAlertClick,
  variant = 'normal',
  canDrag = false,
  view = viewTypes.GRID,
  showLocationImage = false,
  selectable,
  withActions = true,
  dropdown,
  withShowMore,
  infoIconsPosition = 'bottom',
  disableHoverEffect,
  withFooter,
  bottomContentComponent: BottomContentComponent,
  deleteDevice
}) => {
  const { showConfirmation } = useConfirmation()
  const role = useUserRole()
  const history = useHistory()
  const [rebootDevice, setRebootDevice] = useState(false)
  const [virtualPlayerDialog, setVirtualPlayerDialog] = useState(false)
  //fix for drag styles
  const [mediaDialog, setMediaDialog] = useState(false)
  const [titleHovered, setTitleHovered] = useState(false)
  const [error, setError] = useState(false)
  const [isPortraitImg, setIsPortraitImg] = useState(false)

  const { getPermissionByName } = usePermissions()

  const userHealthReadPermission = getPermissionByName(
    permissionNames.ORG_USER_DEVICE_HEALTH_READ,
    permissionNames.SYSTEM_USER_DEVICE_HEALTH_READ
  )

  const devicePermission = useMemo(
    () => ({
      update: getPermissionByName(
        permissionNames.CLIENT_DEVICE_UPDATE,
        permissionNames.SYSTEM_DEVICE_UPDATE,
        permissionNames.ENTERPRISE_DEVICE_UPDATE
      ),
      delete: getPermissionByName(permissionNames.SYSTEM_DEVICE_DESTROY)
    }),
    [getPermissionByName]
  )

  const deviceRebootPermission = useMemo(
    () => ({
      read: getPermissionByName(
        permissionNames.CLIENT_DEVICE_REBOOT_SHOW,
        permissionNames.SYSTEM_DEVICE_REBOOT_SHOW,
        permissionNames.ENTERPRISE_DEVICE_REBOOT_SHOW
      )
    }),
    [getPermissionByName]
  )

  const deviceAlertPermission = useMemo(
    () => ({
      read: getPermissionByName(permissionNames.CLIENT_DEVICE_ALERT_SHOW)
    }),
    [getPermissionByName]
  )

  const dragConfig = useMemo(
    () => ({
      type: dndConstants.deviceLibraryTypes.DEVICE_CARD,
      item: {
        type: dndConstants.deviceLibraryTypes.DEVICE_CARD,
        data: device
      },
      canDrag,
      collect: monitor => ({
        isDragging: monitor.isDragging()
      })
    }),
    [device, canDrag]
  )

  const [{ isDragging }, drag] = useDrag(dragConfig)

  const dynamicClasses = dynamicStyles({
    color: device.deviceType?.color,
    status: device.status
  })

  const isSelected = useMemo(() => !!selected.find(i => i.id === device.id), [
    device.id,
    selected
  ])
  // TODO refactor
  const handleClick = useCallback(
    (event, deviceId, deviceName) => {
      const index = selected.indexOf(selected.find(i => i.id === deviceId))

      if (index !== -1) {
        setSelected(
          update(selected, {
            $splice: [[index, 1]]
          })
        )
      } else {
        setSelected(
          update(selected, {
            $push: [{ ...device, id: deviceId, name: deviceName }]
          })
        )
      }
    },
    [setSelected, selected, device]
  )

  const renderDeviceCardAlerts = useMemo(() => {
    return (
      device.activeEmergencyAlert && (
        <DeviceCardAlerts
          alertId={device.activeEmergencyAlert}
          deviceId={device.id}
          handleAlertClick={handleAlertClick}
        />
      )
    )
  }, [device, handleAlertClick])

  const deviceNotes = useMemo(
    () => (role.system && device.totalNote ? device.totalNote : 0),
    [device.totalNote, role.system]
  )

  const isDeviceLibraryGridCard = useMemo(() => {
    return showLocationImage && view === viewTypes.GRID
  }, [showLocationImage, view])

  const isDeviceLibraryLocationCard = useMemo(() => {
    return showLocationImage && view === viewTypes.LOCATION
  }, [showLocationImage, view])

  const isLegacy = useMemo(
    () => isLegacyBrowserVersion(device?.browserVersion),
    [device]
  )

  const titleMaxWidth = useMemo(() => {
    const initialTitleWidth = 205
    const maxIconWidth = 28

    if (isLegacy && deviceNotes) {
      return initialTitleWidth - maxIconWidth * 2
    } else if (isLegacy || deviceNotes) {
      return initialTitleWidth - maxIconWidth
    } else {
      return initialTitleWidth
    }
  }, [isLegacy, deviceNotes])
  const defaultIcon = require('common/icons/device-icon-xhibit.png')

  const cardTitleComponent = useMemo(() => {
    const { name, alias, deviceType } = device
    return (
      <div
        className={classNames(classes.headerWrap, {
          [classes.cursorGrab]: canDrag
        })}
        onMouseEnter={() => setTitleHovered(true)}
        onMouseLeave={() => setTitleHovered(false)}
      >
        {deviceType?.logo && (
          <img
            className={classes.cardTitleIcon}
            alt={deviceType.name}
            src={deviceType.logo && !error ? deviceType.logo : defaultIcon}
            onError={() => {
              setError(true)
            }}
          />
        )}
        <div className={classes.cardHeaderText}>
          <div
            className={classNames(classes.cardTitleWrapper, {
              [classes.gridCardRowLineHeight]: isDeviceLibraryGridCard
            })}
          >
            <TextWithTooltip
              rootClassName={classes.cardTitle}
              weight="bold"
              color="title.primary"
              maxWidth={titleMaxWidth}
            >
              {alias}
            </TextWithTooltip>
            <LegacyIndicator isGrid isLegacy={isLegacy} />
            {deviceNotes > 0 && <NoteIndicator notes={deviceNotes} />}
          </div>

          <TextWithTooltip
            rootClassName={classes.cardSubTitle}
            color="light"
            maxWidth={205}
          >
            {name}
          </TextWithTooltip>
        </div>
      </div>
    )
  }, [
    device,
    classes,
    deviceNotes,
    canDrag,
    isDeviceLibraryGridCard,
    titleMaxWidth,
    isLegacy,
    error,
    defaultIcon
  ])

  const installedLocationImage = useMemo(() => {
    if (device.locationImage) {
      return device.locationImage.deviceLocationImage
    }
    return null
  }, [device])

  const displayLocationImage = useMemo(() => {
    return (
      installedLocationImage &&
      ((isDeviceLibraryGridCard && titleHovered) ||
        (isDeviceLibraryLocationCard && !titleHovered))
    )
  }, [
    isDeviceLibraryGridCard,
    titleHovered,
    installedLocationImage,
    isDeviceLibraryLocationCard
  ])

  const backgroundOverlayStyle = useMemo(
    () => ({
      backgroundImage: installedLocationImage
        ? `url("${installedLocationImage}")`
        : '',
      opacity: displayLocationImage ? 1 : 0
    }),
    [displayLocationImage, installedLocationImage]
  )

  const state = useMemo(() => {
    const abbreviatedState = getAbbreviatedState(device.country, device.state)
    return abbreviatedState || device.state
  }, [device])

  const handleDeleteClick = useCallback(() => {
    showConfirmation(getDeleteConfirmationMessage(device.name, t), () =>
      deleteDevice(device, index)
    )
  }, [device, deleteDevice, index, showConfirmation, t])

  const actions = useMemo(() => {
    return [
      {
        label: t('Edit'),
        to: getUrlPrefix(
          routeByName.device[`goToEdit${capitalize(view)}`](device.id)
        ),
        icon: 'fa-sharp fa-regular fa-pen-to-square',
        render: devicePermission.update
      },
      {
        label: t('Virtual Player'),
        clickAction: () => setVirtualPlayerDialog(true),
        iconComponent: <Share className={classes.shareIcon} />,
        render: _get(device, 'deviceType.alias') === VIRTUAL_DEVICE_ALIAS
      },
      {
        label: t('Media'),
        clickAction: () => setMediaDialog(true),
        icon: 'icon-files-landscape-video',
        render: role.org && deviceAlertPermission.read
      },
      {
        label: t('Health'),
        icon: 'fa-regular fa-stethoscope',
        to: getUrlPrefix(routeByName.device.goToHealth(device.id, view)),
        disabled:
          !device.lastCheckInUTC || deviceStatus.disabled === device.status,
        render:
          userHealthReadPermission &&
          _get(device, 'deviceType.alias') !== VIRTUAL_DEVICE_ALIAS
      },
      {
        label: t('Notes'),
        to: getUrlPrefix(`device-library/${view}/note/${device.id}`),
        icon: 'fa-light fa-memo-pad',
        render: role.system
      },
      {
        label: t('Reboot'),
        clickAction: () => setRebootDevice(true),
        icon: 'fa-regular fa-power-off',
        render: deviceRebootPermission.read,
        iconClassName: classes.rebootDeviceColor,
        disabled: deviceStatus.disabled === device.status
      },
      {
        label: t('Viewer Metrics'),
        clickAction: () => {
          history.push(
            getUrlPrefix(routeByName.device.goToViewerMetrics(device.id, view))
          )
        },
        icon: 'fa-regular fa-chart-line',
        render: _get(device, 'deviceType.alias') === VIRTUAL_DEVICE_ALIAS
      },
      {
        label: t('Delete'),
        clickAction: () => handleDeleteClick(),
        icon: 'fa-regular fa-trash-can',
        render: devicePermission.delete
      }
    ]
  }, [
    view,
    device,
    role.org,
    role.system,
    deviceAlertPermission.read,
    deviceRebootPermission.read,
    devicePermission.update,
    devicePermission.delete,
    userHealthReadPermission,
    history,
    handleDeleteClick,
    classes,
    t
  ])

  const infoIcons = useMemo(
    () => (
      <>
        <DeviceConnectivityIcon
          status={device.status}
          lastCheckInUTC={device.lastCheckInUTC}
          iconWrapperClass={classes.statusActionWrap}
          iconClass={classes.actionBtnIcon}
        />
      </>
    ),
    [device, classes]
  )

  const hasAccountRow = ['normal'].includes(variant) && !role.org

  return (
    <React.Fragment>
      <section ref={drag}>
        <LibraryGridCard
          dropdown={dropdown}
          selectable={selectable}
          withShowMore={infoIconsPosition === 'top' || withShowMore}
          titleComponent={cardTitleComponent}
          cardHeaderClassName={classes.cardHeader}
          menuDropdownComponent={
            <DeviceMoreInfoCard
              device={device}
              isPortraitImg={isPortraitImg}
              setIsPortraitImg={setIsPortraitImg}
            />
          }
          headerPopupPlacement="bottom"
          isSelected={isSelected}
          cardActions={actions}
          handleSelect={event => handleClick(event, device.id, device.name)}
          cardRootClassName={classNames(dynamicClasses.cardRoot, {
            [classes.cardRoot]: !disableHoverEffect,
            [classes.faded]: isDragging
          })}
          titleComponentWrapClassName={classes.libraryCardWrapper}
          disableHoverEffect={disableHoverEffect}
          cardFooterClassName={classes.cardFooter}
          {...(infoIconsPosition === 'top' && {
            iconButtonComponent: infoIcons
          })}
          {...(infoIconsPosition === 'bottom' && {
            restFooterComponent: infoIcons
          })}
          withFooter={withFooter}
        >
          <div className={classes.cardAlertsWrapper}>
            {renderDeviceCardAlerts}
          </div>
          <div
            className={classNames(
              classes.content,
              [`content-${variant}`],
              classes[`content-${variant}`],
              {
                [classes[`content-original-${variant}`]]: isSelected,
                [classes.customContent]: hasAccountRow
              }
            )}
          >
            <div
              className={classes.backgroundOverlay}
              style={backgroundOverlayStyle}
            />
            {hasAccountRow && (
              <Grid
                className={classNames(classes.detailRow, {
                  [classes.gridCardRowPadding]: isDeviceLibraryGridCard,
                  [classes.hide]: displayLocationImage
                })}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography className={classes.detailLabel}>
                    {t('Device Account')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={classNames(classes.detailValue, {
                      [classes.gridCardRowLineHeight]: isDeviceLibraryGridCard
                    })}
                  >
                    {device.client && device.client.name}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid
              className={classNames(classes.detailRow, {
                [classes.gridCardRowPadding]: isDeviceLibraryGridCard,
                [classes.hide]: displayLocationImage
              })}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography className={classes.detailLabel}>
                  {t('Location')} | {t('Time')}
                </Typography>
              </Grid>
              <Grid item className={classes.locationValueWrapper}>
                <TextWithTooltip
                  rootClassName={classNames(classes.contentWrapAlign, {
                    [classes.gridCardRowLineHeight]: isDeviceLibraryGridCard
                  })}
                  weight="bold"
                  color="title.primary"
                  maxWidth={130}
                >
                  {_get(device.deviceType, 'alias') === VIRTUAL_DEVICE_ALIAS ||
                  !_isEmpty(device.virtualDevice)
                    ? t('Virtual Cloud')
                    : `${device.city}, ${state}`}
                </TextWithTooltip>

                <Typography
                  component="span"
                  className={classNames(classes.text, classes.textSmall)}
                >
                  {getDeviceTimeWithOffset(device, t)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              className={classNames(classes.detailRow, {
                [classes.gridCardRowPadding]: isDeviceLibraryGridCard,
                [classes.hide]: displayLocationImage
              })}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography className={classes.detailLabel}>
                  {t('Device Last Reboot')}
                </Typography>
              </Grid>
              <Grid
                item
                className={classNames(classes.contentWrapAlign, {
                  [classes.gridCardRowLineHeight]: isDeviceLibraryGridCard
                })}
              >
                <DateTimeView
                  date={device.lastReboot}
                  textClass={classNames(classes.text, {
                    [classes.gridCardRowLineHeight]: isDeviceLibraryGridCard
                  })}
                />
              </Grid>
            </Grid>
            <Grid
              className={classNames(classes.detailRow, {
                [classes.gridCardRowPadding]: isDeviceLibraryGridCard,
                [classes.hide]: displayLocationImage
              })}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography className={classes.detailLabel}>
                  {t('Device Last Check-in')}
                </Typography>
              </Grid>
              <Grid
                item
                className={classNames(classes.contentWrapAlign, {
                  [classes.gridCardRowLineHeight]: isDeviceLibraryGridCard
                })}
              >
                <DateTimeView
                  date={device.lastCheckInUTC}
                  textClass={classNames(classes.text, {
                    [classes.gridCardRowLineHeight]: isDeviceLibraryGridCard
                  })}
                />
              </Grid>
            </Grid>
            <Grid
              className={classNames(classes.detailRow, {
                [classes.gridCardRowPadding]: isDeviceLibraryGridCard,
                [classes.hide]: displayLocationImage
              })}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography className={classes.detailLabel}>
                  {t('Application Version')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={classNames(classes.detailValue, {
                    [classes.gridCardRowLineHeight]: isDeviceLibraryGridCard
                  })}
                >
                  {device.firmware}
                </Typography>
              </Grid>
            </Grid>
          </div>
          {BottomContentComponent && BottomContentComponent}
        </LibraryGridCard>
      </section>

      {rebootDevice && (
        <DeviceRebootModal
          device={device}
          open={rebootDevice}
          handleClose={() => setRebootDevice(false)}
        />
      )}

      {virtualPlayerDialog && (
        <DeviceVirtualPlayerModal
          device={device}
          open={virtualPlayerDialog}
          handleClose={() => setVirtualPlayerDialog(false)}
        />
      )}

      {mediaDialog && (
        <DeviceMediaModal
          id={device.id}
          open={mediaDialog}
          handleClose={() => setMediaDialog(false)}
        />
      )}
    </React.Fragment>
  )
}

DeviceCard.propTypes = {
  classes: PropTypes.object,
  variant: PropTypes.oneOf(['normal', 'small', 'smaller', 'smallest', 'auto']),
  canDrag: PropTypes.bool,
  view: PropTypes.string,
  selectable: PropTypes.bool,
  withActions: PropTypes.bool,
  withShowMore: PropTypes.bool,
  dropdown: PropTypes.bool,
  infoIconsPosition: PropTypes.oneOf(['top', 'bottom']),
  disableHoverEffect: PropTypes.bool,
  withFooter: PropTypes.bool,
  bottomContentComponent: PropTypes.elementType
}

export default withTranslation('translations')(withStyles(styles)(DeviceCard))
