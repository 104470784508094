import { all, call, put } from 'redux-saga/effects'

import * as types from '../actions'

import { mediaService, playlistService, templateService } from '../services'
import { transformMeta } from 'utils/tableUtils'
import { approveContent } from 'services/approveService'
import apiTags from 'constants/apiTags'
import { templatesApi } from 'api/templatesApi'

function* approveTemplate({ id }) {
  try {
    yield call(approveContent, 'template', id)
    yield put({
      type: types.APPROVE_TEMPLATE_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.APPROVE_TEMPLATE_ERROR,
      payload: error
    })
  }
}

function* postTemplate(action) {
  try {
    const response = yield call(templateService.postTemplate, action.data)
    yield put({
      type: types.POST_TEMPLATE_SUCCESS,
      payload: response,
      meta: action.meta
    })
  } catch (error) {
    yield put({
      type: types.POST_TEMPLATE_ERROR,
      payload: error,
      meta: action.meta
    })
  }
}

function* getTemplate(action) {
  try {
    const response = yield call(templateService.getTemplate, action.data)
    yield put({
      type: types.GET_TEMPLATE_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_TEMPLATE_ERROR, payload: error })
  }
}

function* editTemplate(action) {
  try {
    const response = yield call(templateService.editTemplate, {
      id: action.meta.id,
      data: action.data
    })
    yield put({
      type: types.PUT_TEMPLATE_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.PUT_TEMPLATE_ERROR, payload: error })
  }
}

function* getItems({ params }) {
  try {
    const { data, meta } = yield call(templateService.getItems, params)
    const modifiedMeta = transformMeta(meta)
    yield put({
      type: types.GET_TEMPLATE_ITEMS_SUCCESS,
      payload: {
        response: data,
        params: params,
        modifiedMeta
      }
    })
  } catch (error) {
    yield put({ type: types.GET_TEMPLATE_ITEMS_ERROR, payload: error })
  }
}

function* getPreference() {
  try {
    const response = yield call(templateService.getPreference)
    yield put({
      type: types.GET_TEMPLATE_PREFERENCE_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_TEMPLATE_PREFERENCE_ERROR, payload: error })
  }
}

function* putPreference(action) {
  try {
    yield call(templateService.putPreference, action.payload)
    yield put({ type: types.GET_TEMPLATE_PREFERENCE })
  } catch (error) {
    yield put({ type: types.PUT_TEMPLATE_PREFERENCE_ERROR, payload: error })
  }
}

function* getGroupItems(action) {
  try {
    const response = yield call(
      templateService.getGroupItems,
      action.payload.id,
      action.payload.params
    )
    yield put({
      type: types.GET_TEMPLATE_GROUP_ITEMS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_TEMPLATE_GROUP_ITEMS_ERROR, payload: error })
  }
}

function* postGroupItem(action) {
  try {
    const response = yield call(templateService.postGroupItem, action.payload)
    yield put({
      type: types.POST_TEMPLATE_GROUP_ITEM_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.POST_TEMPLATE_GROUP_ITEM_ERROR, payload: error })
  }
}

function* deleteGroupItem(action) {
  try {
    const response = yield call(templateService.deleteGroupItem, action.payload)
    yield put({
      type: types.DELETE_TEMPLATE_GROUP_ITEM_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.DELETE_TEMPLATE_GROUP_ITEM_ERROR, payload: error })
  }
}

function* cloneTemplate({ data }) {
  try {
    yield call(templateService.cloneTemplate, data)
    yield put({
      type: types.CLONE_TEMPLATE_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.CLONE_TEMPLATE_ERROR, payload: error })
  }
}

function* deleteTemplateById({ id, data }) {
  try {
    yield call(templateService.deleteTemplateById, { id, data })
    yield put({
      type: types.DELETE_TEMPLATE_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_TEMPLATE_ERROR,
      payload: {
        ...(data || {}),
        ...error
      }
    })
  }
}

function* deleteSelectedTemplate({ ids, data }) {
  try {
    yield call(templateService.deleteSelectedTemplate, ids, data)
    yield put({
      type: types.DELETE_SELECTED_TEMPLATE_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_SELECTED_TEMPLATE_ERROR,
      payload: error
    })
  }
}

function* getTemplatePreview(action) {
  try {
    const response = yield call(templateService.getPreview, action.data.id)
    yield put({
      type: types.GET_TEMPLATE_PREVIEW_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_TEMPLATE_PREVIEW_ERROR, payload: error })
  }
}

function* generateTemplatePreview(action) {
  try {
    const response = yield call(templateService.generatePreview, action.data)
    yield put({
      type: types.GENERATE_TEMPLATE_PREVIEW_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GENERATE_TEMPLATE_PREVIEW_ERROR, payload: error })
  }
}

function* shareTemplate({ data }) {
  try {
    yield call(templateService.shareTemplate, data)
    yield put({
      type: types.SHARE_TEMPLATE_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.SHARE_TEMPLATE_ERROR, payload: error })
  }
}

function* copyTemplate({ data }) {
  try {
    yield call(templateService.copyTemplate, data)
    yield put({
      type: types.COPY_TEMPLATE_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.COPY_TEMPLATE_ERROR, payload: error })
  }
}

function* getTemplateBackgrounds({ payload: params }) {
  try {
    const response = yield call(templateService.getTemplateBackgrounds, params)
    const modifiedMeta = transformMeta(response.meta)
    yield put({
      type: types.GET_TEMPLATE_BACKGROUNDS_SUCCESS,
      payload: { ...response, meta: modifiedMeta }
    })
  } catch (error) {
    yield put({ type: types.GET_TEMPLATE_BACKGROUNDS_ERROR, payload: error })
  }
}

function* getTemplateBackground({ payload: id }) {
  try {
    const response = yield call(templateService.getTemplateBackground, id)
    yield put({
      type: types.GET_TEMPLATE_BACKGROUND_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_TEMPLATE_BACKGROUND_ERROR, payload: error })
  }
}

function* postTemplateBackground({ payload: data }) {
  try {
    const response = yield call(templateService.postTemplateBackground, data)
    yield put({
      type: types.POST_TEMPLATE_BACKGROUND_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.POST_TEMPLATE_BACKGROUND_ERROR, payload: error })
  }
}

function* putTemplateBackground({ payload }) {
  try {
    const response = yield call(templateService.putTemplateBackground, payload)
    yield put({
      type: types.PUT_TEMPLATE_BACKGROUND_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.PUT_TEMPLATE_BACKGROUND_ERROR, payload: error })
  }
}

function* getGroupTemplates({ params }) {
  try {
    const { data, meta } = yield call(templateService.getItems, params)
    const modifiedMeta = transformMeta(meta)
    yield put({
      type: types.GET_TEMPLATE_GROUP_TEMPLATES_SUCCESS,
      payload: {
        response: data,
        meta: modifiedMeta
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_TEMPLATE_GROUP_TEMPLATES_ERROR,
      payload: error
    })
  }
}

function* getItemPreview({ payload }) {
  try {
    const response = yield call(templateService.getItemPreview, payload)

    yield put({
      type: types.GET_TEMPLATE_ITEM_PREVIEW_SUCCESS,
      payload: {
        response,
        id: payload.id,
        type: payload.type,
        isZoomToFit: payload.isZoomToFit
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_TEMPLATE_ITEM_PREVIEW_ERROR,
      payload: {
        id: payload.id,
        type: payload.type,
        isZoomToFit: payload.isZoomToFit
      }
    })
  }
}

function* getTemplateZonePreview({ payload }) {
  try {
    const response = yield call(templateService.getTemplateZonePreview, payload)

    yield put({
      type: types.GET_TEMPLATE_ZONE_PREVIEW_SUCCESS,
      payload: {
        response,
        id: payload.id,
        type: payload.type,
        isZoomToFit: payload.isZoomToFit
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_TEMPLATE_ZONE_PREVIEW_ERROR,
      payload: {
        id: payload.id,
        type: payload.type,
        isZoomToFit: payload.isZoomToFit
      }
    })
  }
}

function* generateItemPreview({ payload }) {
  try {
    const response = yield call(
      templateService.generateItemPreview,
      payload.data
    )
    yield put({
      type: types.GENERATE_TEMPLATE_ITEM_PREVIEW_SUCCESS,
      payload: {
        response,
        id: payload.id,
        type: payload.type,
        isZoomToFit: payload.isZoomToFit
      }
    })
  } catch (error) {
    yield put({
      type: types.GENERATE_TEMPLATE_ITEM_PREVIEW_ERROR,
      payload: {
        id: payload.id,
        type: payload.type,
        isZoomToFit: payload.isZoomToFit
      }
    })
  }
}

function* getTemplateMedia({ payload }) {
  try {
    const response = yield call(mediaService.getMediaItemById, payload)

    yield put({
      type: types.GET_TEMPLATE_MEDIA_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_TEMPLATE_MEDIA_ERROR, payload: error })
  }
}

function* getTemplatePlaylist({ payload }) {
  try {
    const response = yield call(playlistService.getPlaylistById, payload)

    yield put({
      type: types.GET_TEMPLATE_PLAYLIST_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_TEMPLATE_PLAYLIST_ERROR, payload: error })
  }
}

function* getTemplateBackgroundImage({ payload }) {
  try {
    const response = yield call(mediaService.getMediaItemById, payload)

    yield put({
      type: types.GET_TEMPLATE_BACKGROUND_IMAGE_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_TEMPLATE_BACKGROUND_IMAGE_ERROR,
      payload: error
    })
  }
}

function* getTouchZoneTemplateItem({ payload }) {
  try {
    const { id, isMedia } = payload
    let response
    if (isMedia) {
      response = yield call(mediaService.getMediaItemById, id)
    } else {
      response = yield call(playlistService.getPlaylistById, id)
    }

    yield put({
      type: types.GET_TOUCH_ZONE_TEMPLATE_ITEM_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_TOUCH_ZONE_TEMPLATE_ITEM_ERROR,
      payload: error
    })
  }
}

function* getTemplateAssociations({ payload: { id, params } }) {
  try {
    const response = yield call(
      templateService.getTemplateAssociations,
      id,
      params
    )

    const modifiedMeta = transformMeta(response.meta)
    yield put({
      type: types.GET_TEMPLATE_ASSOCIATIONS_SUCCESS,
      payload: {
        response: response.data,
        meta: modifiedMeta
      }
    })
  } catch (error) {
    yield put({ type: types.GET_TEMPLATE_ASSOCIATIONS_ERROR, payload: error })
  }
}

function* bulkUpdateTemplateItem({ payload }) {
  const { mediaIds = [], playlistIds = [] } = payload
  try {
    const response = yield all([
      ...mediaIds.map(id => call(mediaService.getMediaItemById, id)),
      ...playlistIds.map(id => call(playlistService.getPlaylistById, id))
    ])

    yield put({
      type: types.BULK_UPDATE_TEMPLATE_ITEM_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.BULK_UPDATE_TEMPLATE_ITEM_ERROR, payload })
  }
}

function* getTemplateGalleryItems({ params }) {
  try {
    const { data, meta } = yield call(
      templateService.getTemplateGalleryItems,
      params
    )
    const modifiedMeta = transformMeta(meta)
    yield put({
      type: types.GET_TEMPLATE_GALLERY_ITEMS_SUCCESS,
      payload: data,
      modifiedMeta
    })
  } catch (error) {
    yield put({ type: types.GET_TEMPLATE_GALLERY_ITEMS_ERROR, payload: error })
  }
}

function* getTemplateGalleryById({ id }) {
  try {
    const response = yield call(templateService.getTemplateGalleryById, id)
    yield put({
      type: types.GET_TEMPLATE_GALLERY_BY_ID_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_TEMPLATE_GALLERY_BY_ID_ERROR,
      payload: error
    })
  }
}

function* getTemplateGalleryPreview({ data: { id } }) {
  try {
    const response = yield call(templateService.getTemplateGalleryPreview, id)
    yield put({
      type: types.GET_TEMPLATE_GALLERY_PREVIEW_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_TEMPLATE_GALLERY_PREVIEW_ERROR,
      payload: error
    })
  }
}

function* copyTemplateGallery({ data }) {
  try {
    const { id } = yield call(templateService.copyTemplateGallery, data)
    yield put({
      type: types.COPY_TEMPLATE_GALLERY_SUCCESS,
      payload: { status: 'success', templateId: id }
    })
  } catch (error) {
    yield put({ type: types.COPY_TEMPLATE_GALLERY_ERROR, payload: error })
  }
}

function* bulkTemplateGalleryCopy({ payload }) {
  try {
    const response = yield call(
      templateService.bulkTemplateGalleryCopy,
      payload
    )
    yield put({
      type: types.BULK_TEMPLATE_GALLERY_COPY_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.BULK_TEMPLATE_GALLERY_COPY_ERROR, payload: error })
  }
}

function* postTemplateBulk({ payload }) {
  try {
    yield call(templateService.postTemplateBulk, payload)
    yield put({
      type: types.POST_TEMPLATE_BULK_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.POST_TEMPLATE_BULK_ERROR, payload: error })
  }
}

function* invalidateTemplateTags() {
  yield put(templatesApi.util.invalidateTags([apiTags.template]))
}

function* invalidateTemplateBackgroundTags() {
  yield put(templatesApi.util.invalidateTags([apiTags.templatesBackground]))
}

const templateSaga = {
  approveTemplate,
  postTemplate,
  getTemplate,
  editTemplate,
  getItems,
  getPreference,
  putPreference,
  getGroupItems,
  postGroupItem,
  deleteGroupItem,
  cloneTemplate,
  deleteSelectedTemplate,
  deleteTemplateById,
  getTemplatePreview,
  generateTemplatePreview,
  shareTemplate,
  copyTemplate,
  getTemplateBackgrounds,
  getTemplateBackground,
  postTemplateBackground,
  putTemplateBackground,
  getGroupTemplates,
  getItemPreview,
  generateItemPreview,
  getTemplateMedia,
  getTemplatePlaylist,
  getTemplateBackgroundImage,
  getTouchZoneTemplateItem,
  getTemplateAssociations,
  bulkUpdateTemplateItem,
  getTemplateGalleryItems,
  getTemplateGalleryById,
  getTemplateGalleryPreview,
  copyTemplateGallery,
  bulkTemplateGalleryCopy,
  getTemplateZonePreview,
  postTemplateBulk,
  invalidateTemplateTags,
  invalidateTemplateBackgroundTags
}

export default templateSaga
