import React, { memo, useCallback, useState } from 'react'
import { Grid, withStyles } from '@material-ui/core'
import { _get } from 'utils/lodash'
import classNames from 'classnames'

import Spacing from 'components/Containers/Spacing'
import MaterialPopup from 'components/Popup/MaterialPopup'
import DeviceCard from 'components/Card/DeviceCard'
import { TextWithTooltip } from 'components/Typography'
import { getListContentWrapStyle } from './styles'
import DeviceCardScheduleContent from './DeviceCardScheduleContent'

const styles = theme => {
  const { spacing, palette, type } = theme

  return {
    popupContentWrap: {
      display: 'flex',
      alignItems: 'center',
      marginRight: spacing(1)
    },
    listContentWrap: {
      ...getListContentWrapStyle(theme)
    },
    deviceWrap: {
      fontSize: 18,
      color: '#74809A',
      marginRight: '5px',
      minWidth: '45px',
      width: '45px',
      height: '49px',
      position: 'absolute',
      zIndex: 9
    },
    deviceIcon: {
      color: '#0a83c8'
    },
    cardWrapper: {
      width: 320
    },
    cardTitleIcon: {
      height: '100%',
      objectFit: 'cover',
      maxWidth: '100%',
      marginRight: 8,
      backgroundColor: palette[type].card.greyHeader.background
    },
    cardHeaderText: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      height: '43px',
      padding: '5px',
      width: '100%',
      alignItems: 'center',
      textAlign: 'center'
    },
    cardTitleWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center'
    },
    cardTitle: {
      fontSize: 13,
      lineHeight: '1.667em'
    },
    cardSubTitle: {
      fontSize: 11,
      lineHeight: '1.667em'
    },
    container: {
      border: '1px solid #1E3966',
      overflow: 'hidden',
      position: 'relative',
      cursor: 'pointer'
    },
    activeContainer: {
      backgroundColor: palette.buttons.primary.theme4.background,
      border: '1px solid #00b3ff',
      '& p': {
        color: palette[type].default
      }
    },
    hidden: {
      opacity: '0'
    },
    listContent: {
      height: '45px',
      maxWidth: '100%'
    }
  }
}

const DeviceRow = ({
  item,
  name,
  checkboxValue,
  disabled,
  disabledReason,
  gridSize,
  maxTitleWidth = 100,
  onChange,
  isScheduled,
  hideSchedulePopup = false,
  containerProps,
  classes
}) => {
  const handleChange = useCallback(() => {
    onChange(item)
  }, [onChange, item])

  const [deviceInfo, setDeviceInfo] = useState(false)
  const [hoverTimeout, setHoverTimeout] = useState(null)

  const handleMouse = useCallback(
    hover => {
      if (hoverTimeout) {
        clearTimeout(hoverTimeout)
      }
      const timeout = setTimeout(() => {
        setDeviceInfo(hover)
      }, 1000)
      setHoverTimeout(timeout)
    },
    [hoverTimeout]
  )

  const handleDisplayPopup = useCallback(() => {
    setTimeout(() => {
      const popupContent = document.querySelector('.popup-content')
      const left = _get(popupContent, 'style.left', '0px')
      const leftValue = Number(left.replace('px', ''))
      const top = _get(popupContent, 'style.top', '0px')
      const topValue = Number(top.replace('px', ''))

      if (leftValue < 0 || leftValue > 40) {
        popupContent.style.left = '25px'
      }
      if (topValue < 0) {
        popupContent.style.top = '10px'
      }
    }, 5)
  }, [])

  const { alias } = item

  return (
    <MaterialPopup
      hasArrow={deviceInfo ? true : false}
      trigger={
        <Grid
          item
          xs={gridSize}
          {...containerProps}
          className={classNames(classes.listContentWrap, classes.listContent)}
          disabled={disabled}
          onMouseOver={() => handleMouse(true)}
          onMouseOut={() => handleMouse(false)}
        >
          <Spacing
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            variant={0}
            wrap="nowrap"
          >
            <Grid
              container
              item
              alignItems="center"
              wrap="nowrap"
              className={classNames(classes.container, {
                [classes.activeContainer]: checkboxValue
              })}
              onClick={handleChange}
            >
              <div className={classes.cardHeaderText}>
                <div className={classNames(classes.cardTitleWrapper)}>
                  <TextWithTooltip
                    rootClassName={classes.cardTitle}
                    weight="bold"
                    color="title.primary"
                    maxWidth={127}
                    title={alias}
                  >
                    {alias}
                  </TextWithTooltip>

                  <TextWithTooltip
                    rootClassName={classes.cardSubTitle}
                    color="light"
                    maxWidth={'100%'}
                  >
                    {name}
                  </TextWithTooltip>
                </div>
              </div>
            </Grid>
          </Spacing>
        </Grid>
      }
      onOpen={handleDisplayPopup}
    >
      {!hideSchedulePopup && deviceInfo ? (
        <div
          className={classes.cardWrapper}
          onMouseEnter={() => handleMouse(true)}
          onMouseLeave={() => handleMouse(false)}
        >
          <DeviceCard
            device={item}
            withFooter={false}
            infoIconsPosition="top"
            variant="auto"
            disableHoverEffect
            bottomContentComponent={
              <Spacing variant={0} paddingHor={2} paddingTop={2}>
                <DeviceCardScheduleContent device={item} />
              </Spacing>
            }
          />
        </div>
      ) : null}
    </MaterialPopup>
  )
}

export default withStyles(styles)(memo(DeviceRow))
