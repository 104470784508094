import React from 'react'
import { Grid } from '@material-ui/core'

import { DateTimeView } from '../../index'
import { columnTypes } from 'constants/tableConstants'

const DateTimeCell = ({ value, colDef }) => {
  const { align = 'flex-start', cellDataType, isUTC, timeFallback } = colDef

  return (
    <Grid container justifyContent={align}>
      <DateTimeView
        date={value}
        onlyDate={cellDataType === columnTypes.date}
        timeFallback={timeFallback}
        isUTC={isUTC}
      />
    </Grid>
  )
}

export default DateTimeCell
