import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'

import { FormControlReactSelect } from 'components/Form'
import { simulateEvent } from 'utils'
import { getVideowallDisplaysFromMatrix } from 'utils/featureUtils'
import { MAX_NO_OF_VIDEOWALL } from 'constants/featureSettingConstants'
import {
  DEFAULT_VIDEO_WALL,
  videowallDisplaysToMatrix
} from 'constants/featureConstants'
import { isBrightSignType } from 'utils/deviceUtils'

const useStyles = makeStyles(() => ({
  select: {
    width: 80,
    paddingLeft: 6,
    marginBottom: '0px !important'
  }
}))

const createOptions = (max = MAX_NO_OF_VIDEOWALL, deviceType) => {
  const options = isBrightSignType(deviceType)
    ? [1, 2, 3, 4, 6, 8, 9, 10, 12]
    : [2, 3, 4, 6, 8, 9, 10, 12]

  return options
    .filter(displays => displays <= max)
    .map(displays => ({
      value: displays,
      label: displays
    }))
}

const VideoWallMatrixInput = ({
  onChange,
  max = MAX_NO_OF_VIDEOWALL,
  settings,
  deviceType,
  ...props
}) => {
  const classes = useStyles()

  const options = useMemo(() => createOptions(max, deviceType), [
    max,
    deviceType
  ])

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const _settings = {
        ...settings,
        number_of_displays: value
      }
      if (
        !_settings?.video_wall_matrix ||
        value < getVideowallDisplaysFromMatrix(_settings.video_wall_matrix)
      ) {
        _settings.video_wall_matrix = videowallDisplaysToMatrix[value]
      }
      onChange(simulateEvent('settings', _settings))
    },
    [onChange, settings]
  )

  return (
    <FormControlReactSelect
      formControlContainerClass={classes.select}
      options={options}
      onChange={handleChange}
      isSort={false}
      isSearchable
      value={settings?.number_of_displays || DEFAULT_VIDEO_WALL}
      {...props}
    />
  )
}

export default VideoWallMatrixInput
