import React from 'react'
import { useTranslation } from 'react-i18next'

import { Spacing } from 'components/Containers'
import { Text } from 'components/Typography'

const ManageCustomParametersConflictContent = () => {
  const { t } = useTranslation()
  return (
    <Spacing>
      <Text>
        {t('It is not possible to delete a parameter that is used by devices')}
      </Text>
    </Spacing>
  )
}

export default ManageCustomParametersConflictContent
