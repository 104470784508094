import React, { useMemo } from 'react'
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  withStyles
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import Popup from 'reactjs-popup'
import { _get } from 'utils/lodash'

import {
  TableLibraryCell,
  TableLibraryHead,
  TableLibraryRow
} from 'components/TableLibrary'
import Scrollbars from 'components/Scrollbars'
import DeviceTypeIconCell from 'components/Pages/Admin/DeviceLibrary/DeviceTable/DeviceTypeIconCell'
import DeviceNameViewCell from 'components/Pages/Admin/DeviceLibrary/DeviceTable/DeviceNameViewCell'
import { useUserRole } from 'hooks/tableLibrary'
import MessageCard from 'components/Card/MessageCard'
import { conflictSources } from 'constants/api'
import { TextWithTooltip } from 'components/Typography'

const styles = ({ type, typography, colors, palette }) => ({
  scrollContent: {
    marginRight: 20
  },
  table: {
    border: '1px solid ' + colors.border[type],
    marginBottom: 16
  },
  tableHeaderCell: {
    paddingTop: '8px !important',
    fontWeight: 700,

    '& span': {
      padding: '0px !important',
      cursor: 'unset !important'
    }
  },
  name: {
    ...typography.darkAccent[type]
  },
  aliasWrapContent: {
    minWidth: '190px'
  },
  overflowIcon: {
    transform: 'rotate(90deg)'
  },
  overflowIconWrap: {
    padding: 0,
    width: 22,
    height: 22
  },
  overflowIconContent: {
    fontSize: 20,
    width: 'inherit',
    height: 'inherit',
    color: palette[type].header.rightAction.iconColor
  },
  popupContent: {
    padding: '5px 10px'
  }
})

const initialColumns = [
  { id: 'deviceTypeLogo', label: 'Logo', align: 'left' },
  { id: 'deviceType', label: 'Type', align: 'left' },
  { id: 'deviceName', label: 'Device Name', align: 'left' },
  { id: 'schedule', label: 'Schedule', align: 'left' }
]

const columnWidth = {
  deviceTypeLogo: 70,
  deviceType: 120,
  deviceName: 220,
  schedule: 200
}

const DeviceSchedulesConflictContent = ({
  conflictSource,
  error,
  classes,
  theme: { palette, type }
}) => {
  const { t } = useTranslation('translations')
  const role = useUserRole()

  const data = useMemo(() => error?.data || [], [error])
  const { device, schedules } = data

  const displaySchedules = schedules.slice(0, 2)
  const popupSchedules = schedules.slice(2)

  return (
    <Scrollbars autoHeight autoHeightMax="calc(100vh - 190px)">
      <div className={classes.scrollContent}>
        {![
          conflictSources.addEditDevice,
          conflictSources.bulkTagsAssign,
          conflictSources.deviceToGroup,
          conflictSources.deviceLocation
        ].includes(conflictSource) && (
          <MessageCard
            icon="fa-regular fa-circle-info"
            message={`${t(
              'There is a conflict between schedules. To save the changes, edit the indicated schedules'
            )}:`}
            marginBottom
          />
        )}
        <Table className={classes.table}>
          <TableLibraryHead
            noType
            editRows={false}
            disableSort
            rowCount={data.length}
            columns={initialColumns}
            columnWidth={columnWidth}
            headerCellClassName={classes.tableHeaderCell}
          />
          <TableBody>
            <TableLibraryRow hover tabIndex={-1}>
              <DeviceTypeIconCell item={device} isPopupDisabled />
              <TableLibraryCell align="left">
                {_get(device.deviceType, 'name', t('N/A'))}
              </TableLibraryCell>
              <TableLibraryCell
                className={classNames(classes.name, classes.aliasWrapContent)}
              >
                <DeviceNameViewCell
                  role={role}
                  classes={classes}
                  row={device}
                />
              </TableLibraryCell>
              <TableLibraryCell>
                {displaySchedules.map(schedule => (
                  <TextWithTooltip
                    key={schedule.id}
                    color="title.secondary"
                    maxWidth={135}
                  >{`${schedule.title}`}</TextWithTooltip>
                ))}

                {!!popupSchedules.length && (
                  <Popup
                    on="hover"
                    position="top center"
                    contentStyle={{
                      background:
                        palette[type].tableLibrary.body.row.dropdown.background,
                      border: 'none',
                      borderRadius: 6,
                      animation: 'fade-in',
                      padding: 0,
                      width: 155
                    }}
                    arrowStyle={{
                      background:
                        palette[type].tableLibrary.body.row.dropdown.background,
                      border: 'none'
                    }}
                    trigger={
                      <IconButton
                        classes={{
                          root: classes.overflowIconWrap,
                          label: classes.overflowIconContent
                        }}
                      >
                        <i
                          className={[
                            'fa-regular fa-ellipsis-vertical',
                            classes.overflowIcon
                          ].join(' ')}
                        />
                      </IconButton>
                    }
                  >
                    <Grid container className={classes.popupContent}>
                      {popupSchedules.map(schedule => (
                        <TextWithTooltip
                          key={schedule.id}
                          color="title.secondary"
                          maxWidth={135}
                        >{`${schedule.title}`}</TextWithTooltip>
                      ))}
                    </Grid>
                  </Popup>
                )}
              </TableLibraryCell>
            </TableLibraryRow>
          </TableBody>
        </Table>
      </div>
    </Scrollbars>
  )
}

export default withStyles(styles, { withTheme: true })(
  DeviceSchedulesConflictContent
)
