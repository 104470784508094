import {
  ENTERPRISE_TOKEN,
  instanceTypes,
  ORG_TOKEN,
  SYSTEM_TOKEN,
  vuePreviews
} from '../constants/api'
import { VUE_PREVIEW } from '../constants/localStorage'

export const getBaseUrl = () => {
  if (!process.env.REACT_APP_BASE_URL) {
    return ''
  }
  return process.env.REACT_APP_BASE_URL.endsWith('/')
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL + '/'
}

export const createSuccessInvalidator = tags => (response, error) =>
  !error && tags

export const createMergeListEndpoint = ({ builder, name, tags, url }) => ({
  [`${name}Merge`]: builder.query({
    query: (params = {}) => ({
      url,
      params
    }),
    serializeQueryArgs: ({ endpointName }) => {
      return endpointName
    },
    merge: (currentCache, newItems) => {
      if (
        newItems.meta.currentPage > 1 &&
        newItems.meta.currentPage === currentCache.meta.currentPage
      ) {
        //do nothing
        //you need to fetch everything from page 1 from the UI in case of tag invalidation
      } else if (newItems.meta.currentPage > 1) {
        currentCache.meta = newItems.meta
        currentCache.data.push(...newItems.data)
      } else {
        currentCache.meta = newItems.meta
        currentCache.data = newItems.data
      }
    },
    forceRefetch({ currentArg, previousArg }) {
      return currentArg !== previousArg
    }
  })
})

export const getCRUDEndpoints = ({
  builder,
  tags,
  url,
  name,
  pluralName,
  config = {}
}) => ({
  //GET LIST
  [`get${pluralName ? pluralName : `${name}s`}`]: builder.query({
    ...(tags && { providesTags: tags }),
    query: (params = {}) => ({
      url,
      params
    })
  }),
  //GET MERGED LIST
  ...(config.withMerge &&
    createMergeListEndpoint({
      builder,
      name: `get${pluralName ? pluralName : `${name}s`}`,
      tags,
      url
    })),
  //GET ITEM
  [`get${name}`]: builder.query({
    ...(tags && { providesTags: tags }),
    query: id => ({
      url: `${url}/${id}`
    })
  }),
  //POST
  [`post${name}`]: builder.mutation({
    ...(tags && { invalidatesTags: createSuccessInvalidator(tags) }),
    query: data => ({
      method: 'POST',
      url,
      data
    })
  }),
  //PUT
  [`put${name}`]: builder.mutation({
    ...(tags && { invalidatesTags: createSuccessInvalidator(tags) }),
    query: ({ id, ...data }) => ({
      method: 'PUT',
      url: `${url}/${id}`,
      data
    })
  }),
  //DELETE
  [`delete${name}`]: builder.mutation({
    ...(tags && { invalidatesTags: createSuccessInvalidator(tags) }),
    query: ({ id, ...data }) => ({
      method: 'DELETE',
      url: `${url}/${id}`,
      data
    })
  })
})

export const isStorageHasToken = () =>
  !!localStorage.getItem(ORG_TOKEN) ||
  !!localStorage.getItem(SYSTEM_TOKEN) ||
  !!localStorage.getItem(ENTERPRISE_TOKEN)

export const isVueV3Preview = () =>
  [instanceTypes.dev, instanceTypes.local].includes(
    process.env.REACT_APP_INSTANCE
  ) && localStorage.getItem(VUE_PREVIEW) === vuePreviews.v3
