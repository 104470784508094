import { useCallback, useState } from 'react'
import { getAddress1 } from 'utils/generalUtils'

const defaultFilterOptionProps = [
  'street',
  'city',
  'state',
  'country',
  'zipcode',
  'latitude',
  'longitude'
]

const useSelectLocations = (
  options = { filterOptionProps: defaultFilterOptionProps }
) => {
  const { filterOptionProps } = options
  const [selectedOption, setSelectedOption] = useState({})
  const [missedAddressData, setMissedAddressData] = useState([])

  const filterOption = useCallback(
    ({ data: { data } }) => {
      return filterOptionProps.every(prop => !!data[prop])
    },
    [filterOptionProps]
  )

  const setAddressFields = useCallback(
    ({ data = {}, setFieldValue = f => f, zipAlias }) => {
      const { city, state, zipcode, country } = data

      const selected = {
        address1: getAddress1(data),
        city: city || '',
        state: state || '',
        [zipAlias || 'zipCode']: zipcode || '',
        country: country || ''
      }

      setSelectedOption(selected)
      Object.entries(selected).forEach(([key, value]) =>
        setFieldValue(key, value)
      )
    },
    []
  )

  const clearAddressFields = useCallback(
    ({ setFieldValue = f => f, zipAlias }) => {
      setSelectedOption({})

      setFieldValue('address1', '')
      setFieldValue('city', '')
      setFieldValue('state', '')
      setFieldValue(zipAlias || 'zipCode', '')
      setFieldValue('country', '')
    },
    []
  )

  const setMissedAddressFields = useCallback(
    ({ data = {}, requiredAddressFields = [] }) => {
      const missedData = []

      Object.entries(data).forEach(([key, value]) => {
        if (!value && requiredAddressFields.includes(key)) {
          missedData.push(key)
        }
      })

      setMissedAddressData(missedData)
    },
    []
  )

  return {
    filterOption,
    setAddressFields,
    clearAddressFields,
    selectedOption,
    missedAddressData,
    setMissedAddressFields
  }
}

export default useSelectLocations
