import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Link, Route, Redirect, useLocation } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import { GridOn, List, NetworkWifi, Assessment } from '@material-ui/icons'
import classNames from 'classnames'

import { entityConstants, routeByName } from 'constants/index'
import DeviceOverview from './DeviceOverview'
import DevicePreview from './DevicePreview'
import DeviceHealth from './DeviceHealth'
import DeviceDiagram from './DeviceDiagram'
import AlarmSettings from './AlarmSettings'
import ShareNoc from './ShareNoc'
import useFilter from 'hooks/useFilter'
import DeviceSearchForm from '../DeviceLibrary/DeviceSearch'
import { CheckboxSwitcher } from 'components/Checkboxes'
import PageContainer from 'components/PageContainer'
import { getUrlPrefix } from 'utils'
import { CircleIconButton } from 'components/Buttons'
import DevicePublicFilter from './DevicePublicFilter'
import { deviceNocView } from 'constants/deviceNoc'
import Tooltip from 'components/Tooltip'
import useDeviceNocAlarmSocket from 'hooks/socket/useDeviceNocAlarmSocket'
import useSnackbar from 'hooks/useSnackbar'
import usePreferenceNew from 'hooks/tableLibrary/usePreferenceNew'
import useUserRole from 'hooks/tableLibrary/useUserRole'
import DeviceUptime from './DeviceUptime'
import { filterContentWidth } from 'constants/stylesConstants'

const uptimeColumns = [
  {
    id: 'status',
    label: 'Status',
    align: 'center',
    display: true
  },
  {
    id: 'lastCheckInUTC',
    label: 'Offline From',
    align: 'center',
    display: true
  },
  {
    id: 'networkConnectivity',
    label: 'Offline To',
    align: 'center',
    display: true
  },
  {
    id: 'duration',
    label: 'Offline Duration',
    align: 'center',
    display: true
  }
]

const styles = ({ palette, type }) => {
  return {
    container: {
      '& .infoIconWrap': {
        display: 'none'
      }
    },
    circleButton: {
      color: '#afb7c7',
      '&:hover, &.active': {
        color: '#1c5dca'
      },
      '& i': {
        fontSize: 16
      }
    },
    absoluteFooter: {
      display: 'flex',
      width: '0',
      position: 'absolute',
      bottom: '0',
      left: '0',
      paddingLeft: 46
    },
    relativeFooter: {
      display: 'flex',
      position: 'relative',
      background: palette[type].tableLibrary.footer.background,
      paddingLeft: 46
    },
    popupRootFilter: {
      zIndex: 4
    },
    subHeaderWrap: {
      maxWidth: '25%',
      flexBasis: '25%'
    }
  }
}

const staticColumns = [{ id: 'alias', label: 'Name', align: 'left' }]

const initialColumns = [
  {
    id: 'lastCheckInUTC',
    label: 'Last Check-in',
    align: 'center',
    display: true
  },
  {
    id: 'status',
    label: 'Status',
    align: 'center',
    display: true
  },
  {
    id: 'lanIP',
    label: 'IP / LAN',
    align: 'center',
    display: true
  },
  {
    id: 'networkConnectivity',
    label: 'Network Connectivity',
    align: 'center',
    display: true
  },
  {
    id: 'synchronousConnection',
    label: 'Synchronous Connection',
    align: 'center',
    display: true
  },
  {
    id: 'createdBy',
    label: 'Added By',
    align: 'center',
    display: true
  }
]

const sortOptions = {
  sort: 'alias',
  order: 'asc'
}

const DeviceNOC = ({
  t,
  classes,
  searchParams,
  isPublic = false,
  parentClasses = {},
  routes = {},
  redirectLinks = {},
  fetcher = f => null,
  getItemReducer
}) => {
  const location = useLocation()
  const role = useUserRole()
  const [autoScroll, setAutoScroll] = useState(false)
  const { pathname } = location
  const { showSnackbar } = useSnackbar()
  const view = useMemo(() => pathname.split('/').pop(), [pathname])
  const token = useMemo(
    () =>
      isPublic ? pathname.split('public/').pop().split('/device-noc')[0] : '',
    [pathname, isPublic]
  )

  const [filterParams, updateFilters, resetFilters] = useFilter(
    entityConstants.DeviceNocLibrary
  )

  const [isFiltersInitialized, setIsFiltersInitialized] = useState(false)
  const [filterPopupData, setFilterPopupData] = useState({
    columns: 1,
    width: filterContentWidth
  })

  const {
    isFetchAllowed,
    preferenceParams,
    preferenceActions,
    columns
  } = usePreferenceNew({
    entity: entityConstants.DeviceNocLibrary,
    initialColumns,
    initialSortOptions: sortOptions,
    serverSide: !!isPublic,
    publicToken: token
  })

  const socketListener = useCallback(
    ({ data }) => {
      showSnackbar(data.message, 'warning')
    },
    [showSnackbar]
  )

  useDeviceNocAlarmSocket(socketListener)

  const getTitleByView = useCallback(view => {
    switch (view) {
      case deviceNocView.OVERVIEW:
        return 'Device Monitoring Overview'
      case deviceNocView.PREVIEW:
        return 'Device Playback Previews'
      case deviceNocView.HEALTH:
        return 'Device Health Overview'
      case deviceNocView.DIAGRAM:
        return 'Device Network Map'
      case deviceNocView.UPTIME:
        return 'Device Uptime'
      default:
        return 'Device NOC'
    }
  }, [])

  const handleResetSearchForm = useCallback(() => {
    if (isPublic) {
      preferenceActions.changeFilter()
    } else {
      resetFilters()
    }
  }, [resetFilters, preferenceActions, isPublic])

  const handleSubmitSearchForm = useCallback(
    formValues => {
      if (isPublic) {
        preferenceActions.changeFilter(formValues)
      } else {
        updateFilters(formValues)
      }
    },
    [updateFilters, preferenceActions, isPublic]
  )

  const handleAutoScrollChange = useCallback(
    value => {
      setAutoScroll(value)
      if (isPublic) {
        preferenceActions.changeAutoScroll(value)
      }
    },
    [preferenceActions, isPublic]
  )

  useEffect(() => {
    if (isFetchAllowed) {
      if (isPublic) {
        updateFilters({
          ...filterParams,
          name: preferenceParams?.filters?.name || null,
          status: preferenceParams?.filters?.status || '',
          location: preferenceParams?.filters?.location || '',
          deviceType: preferenceParams?.filters?.deviceType || '',
          sort: preferenceParams?.filters?.sort || ''
        })
      }
      setIsFiltersInitialized(true)
    }
    // eslint-disable-next-line
  }, [preferenceParams.filters, isFetchAllowed])

  useEffect(() => {
    if (autoScroll && !isPublic) {
      setAutoScroll(false)
    }
    // eslint-disable-next-line
  }, [location.pathname])

  useEffect(() => {
    if (autoScroll !== preferenceParams.autoScroll && isPublic) {
      setAutoScroll(preferenceParams.autoScroll)
    }
    // eslint-disable-next-line
  }, [preferenceParams, autoScroll])

  return (
    <PageContainer
      pageContainerClassName={classes.container}
      pageTitle={t(getTitleByView(view))}
      pageHeaderClassName={parentClasses.pageHeader}
      pageHeaderTitleClassName={parentClasses.pageHeaderTitle}
      header={!isPublic}
      SubHeaderMenuComponent={
        isPublic ? (
          <DevicePublicFilter
            initialValues={filterParams}
            onSubmit={handleSubmitSearchForm}
            onReset={handleResetSearchForm}
            searchParams={searchParams}
            view={view}
          />
        ) : (
          <DeviceSearchForm
            initialValues={filterParams}
            onSubmit={handleSubmitSearchForm}
            onReset={handleResetSearchForm}
            searchParams={searchParams}
            role={role}
            uptime={view === deviceNocView.UPTIME}
            filterPopupData={filterPopupData}
            setFilterPopupData={setFilterPopupData}
          />
        )
      }
      circleIconClassName={parentClasses.circleIconButton}
      filterPopupRootClassName={classes.popupRootFilter}
      subHeaderPopupStyle={{
        ...(!isPublic && { width: filterPopupData.width }),
        zIndex: 102
      }}
      hasMaterialPopup
      SubHeaderLeftActionComponent={
        [
          deviceNocView.OVERVIEW,
          deviceNocView.DIAGRAM,
          deviceNocView.UPTIME
        ].includes(view) && (
          <div className={parentClasses.subHeaderRoot}>
            <CheckboxSwitcher
              value={autoScroll}
              handleChange={handleAutoScrollChange}
              label={t('Auto Scroll')}
            />
          </div>
        )
      }
      SubHeaderRightActionComponent={
        <>
          <Tooltip
            headerText={t('Device Uptime')}
            title={t('Historical view of device active status information')}
            withHeader
            arrowWithHeaderColor
            arrow
          >
            <CircleIconButton
              className={classNames(
                'hvr-grow',
                classes.circleButton,
                parentClasses.circleIconButton,
                {
                  active: view === 'uptime'
                }
              )}
              component={Link}
              to={
                redirectLinks?.uptime ||
                getUrlPrefix(routeByName.deviceNOC.uptime)
              }
            >
              <i className="fa-solid fa-wave-pulse" />
            </CircleIconButton>
          </Tooltip>
          <Tooltip
            headerText={t('Device Network Map')}
            title={t(
              'Display a visual map of network nodes of Device-Server communication'
            )}
            withHeader
            arrowWithHeaderColor
            arrow
          >
            <CircleIconButton
              className={classNames(
                'hvr-grow',
                classes.circleButton,
                parentClasses.circleIconButton,
                {
                  active: view === 'diagram'
                }
              )}
              component={Link}
              to={
                redirectLinks?.diagram ||
                getUrlPrefix(routeByName.deviceNOC.diagram)
              }
            >
              <Assessment />
            </CircleIconButton>
          </Tooltip>
          <Tooltip
            headerText={t('Device Health Overview')}
            title={t('A grid-view of device health data w/ status monitoring')}
            withHeader
            arrowWithHeaderColor
            arrow
          >
            <CircleIconButton
              className={classNames(
                'hvr-grow',
                classes.circleButton,
                parentClasses.circleIconButton,
                {
                  active: view === 'health'
                }
              )}
              component={Link}
              to={
                redirectLinks?.health ||
                getUrlPrefix(routeByName.deviceNOC.health)
              }
            >
              <NetworkWifi />
            </CircleIconButton>
          </Tooltip>
          <Tooltip
            headerText={t('Device Playback Previews')}
            title={t('A grid view of device playback previews')}
            withHeader
            arrowWithHeaderColor
            arrow
          >
            <CircleIconButton
              className={classNames(
                'hvr-grow',
                classes.circleButton,
                parentClasses.circleIconButton,
                {
                  active: view === 'preview'
                }
              )}
              component={Link}
              to={
                redirectLinks?.preview ||
                getUrlPrefix(routeByName.deviceNOC.preview)
              }
            >
              <GridOn />
            </CircleIconButton>
          </Tooltip>
          <Tooltip
            headerText={t('Device Monitoring Overview')}
            title={t(
              'A Tabular view of device parameters, activity & network status'
            )}
            withHeader
            arrowWithHeaderColor
            arrow
          >
            <CircleIconButton
              className={classNames(
                'hvr-grow',
                classes.circleButton,
                parentClasses.circleIconButton,
                {
                  active: view === 'overview'
                }
              )}
              component={Link}
              to={
                redirectLinks?.overview ||
                getUrlPrefix(routeByName.deviceNOC.overview)
              }
            >
              <List />
            </CircleIconButton>
          </Tooltip>
        </>
      }
      subHeaderRightActionComponentClassName={classNames(
        parentClasses.subHeaderRightAction,
        classes.subHeaderWrap
      )}
    >
      <Route
        exact
        path={routes?.root || getUrlPrefix(routeByName.deviceNOC.root)}
        render={() => (
          <Redirect
            to={
              redirectLinks?.overview ||
              getUrlPrefix(routeByName.deviceNOC.overview)
            }
          />
        )}
      />
      <Route
        path={routes?.overview || getUrlPrefix(routeByName.deviceNOC.overview)}
        render={props => (
          <DeviceOverview
            {...props}
            searchParams={searchParams}
            filterParams={filterParams}
            fetcher={fetcher(view)}
            itemReducer={getItemReducer && getItemReducer(view)}
            isPublic={isPublic}
            parentClasses={parentClasses}
            autoScroll={autoScroll}
            isFetchAllowed={isFiltersInitialized}
            preferenceParams={preferenceParams}
            preferenceActions={preferenceActions}
            columns={columns}
            staticColumns={staticColumns}
          />
        )}
      />
      <Route
        path={routes?.uptime || getUrlPrefix(routeByName.deviceNOC.uptime)}
        render={props => (
          <DeviceUptime
            {...props}
            searchParams={searchParams}
            filterParams={filterParams}
            fetcher={fetcher(view)}
            itemReducer={getItemReducer && getItemReducer(view)}
            isPublic={isPublic}
            parentClasses={parentClasses}
            autoScroll={autoScroll}
            isFetchAllowed={isFiltersInitialized}
            preferenceParams={preferenceParams}
            preferenceActions={preferenceActions}
            columns={uptimeColumns}
            staticColumns={staticColumns}
          />
        )}
      />
      <Route
        path={routes?.preview || getUrlPrefix(routeByName.deviceNOC.preview)}
        render={props => (
          <DevicePreview
            {...props}
            searchParams={searchParams}
            filterParams={filterParams}
            fetcher={fetcher(view)}
            itemReducer={getItemReducer && getItemReducer(view)}
            isPublic={isPublic}
            parentClasses={parentClasses}
            isFetchAllowed={isFiltersInitialized}
            preferenceParams={preferenceParams}
          />
        )}
      />
      <Route
        path={routes?.health || getUrlPrefix(routeByName.deviceNOC.health)}
        render={props => (
          <DeviceHealth
            {...props}
            searchParams={searchParams}
            filterParams={filterParams}
            fetcher={fetcher(view)}
            itemReducer={getItemReducer && getItemReducer(view)}
            isPublic={isPublic}
            parentClasses={parentClasses}
            isFetchAllowed={isFiltersInitialized}
            preferenceParams={preferenceParams}
          />
        )}
      />
      <Route
        path={routes?.diagram || getUrlPrefix(routeByName.deviceNOC.diagram)}
        render={props => (
          <DeviceDiagram
            {...props}
            searchParams={searchParams}
            filterParams={filterParams}
            autoScroll={autoScroll}
            fetcher={fetcher(view)}
            itemReducer={getItemReducer && getItemReducer(view)}
            isPublic={isPublic}
            parentClasses={parentClasses}
            isFetchAllowed={isFiltersInitialized}
            preferenceParams={preferenceParams}
          />
        )}
      />
      {!isPublic && (
        <div className={classes.relativeFooter}>
          <AlarmSettings />
          <ShareNoc />
        </div>
      )}
    </PageContainer>
  )
}

DeviceNOC.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string
  }).isRequired
}

export default withTranslation('translations')(withStyles(styles)(DeviceNOC))
