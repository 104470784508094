import React, { useMemo } from 'react'
import { Table, TableBody, withStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { TableLibraryHead } from 'components/TableLibrary'
import ScheduleSimpleTableRow from 'components/CommonRows/ScheduleSimpleTableRow'
import MessageCard from 'components/Card/MessageCard'

const styles = ({ type, colors }) => ({
  table: {
    border: '1px solid ' + colors.border[type],
    marginBottom: 16
  },
  tableHeaderCell: {
    paddingTop: '8px !important',
    fontWeight: 700,

    '& span': {
      padding: '0px !important',
      cursor: 'unset !important'
    }
  }
})

const initialColumns = [
  { id: 'title', label: 'Title' },
  { id: 'workingDays', label: 'Days', align: 'center' },
  { id: 'time', label: 'Time', align: 'center' }
]

const columnWidth = {
  title: 200,
  workingDays: 300,
  time: 150
}

const AutoAddDeviceToScheduleConflictContent = ({
  error,
  classes,
  entities,
  infoMessage
}) => {
  const { t } = useTranslation()
  const translations = {
    info: infoMessage
      ? infoMessage
      : t('There is an active schedule(s) assigned to this entity.', {
          entity: entities.length ? entities.join('/') : 'entity'
        }),
    activeSchedulesInfo: t(
      'The active schedule(s) will be published to this device.'
    ),
    inactiveSchedulesInfo: t(
      'The active schedule(s) will be removed from this device.'
    )
  }

  const {
    deviceWillBeAddedToSchedules = [],
    deviceWillBeRemovedFromSchedules = []
  } = useMemo(() => error.data || {}, [error])

  return (
    <>
      <MessageCard
        icon="fa-regular fa-circle-info"
        message={`${translations.info} ${
          deviceWillBeAddedToSchedules
            ? translations.activeSchedulesInfo
            : translations.inactiveSchedulesInfo
        }`}
        marginBottom
      />
      {!!deviceWillBeAddedToSchedules && (
        <Table className={classes.table}>
          <TableLibraryHead
            noType={true}
            editRows={false}
            disableSort
            columns={initialColumns}
            columnWidth={columnWidth}
            headerCellClassName={classes.tableHeaderCell}
          />
          <TableBody>
            {deviceWillBeAddedToSchedules.map(schedule => (
              <ScheduleSimpleTableRow data={schedule} />
            ))}
          </TableBody>
        </Table>
      )}
      {!!deviceWillBeRemovedFromSchedules && (
        <>
          {!!deviceWillBeAddedToSchedules && (
            <MessageCard
              icon="fa-regular fa-circle-info"
              message={translations.inactiveSchedulesInfo}
              marginBottom
            />
          )}
          <Table className={classes.table}>
            <TableLibraryHead
              noType={true}
              editRows={false}
              disableSort
              columns={initialColumns}
              columnWidth={columnWidth}
              headerCellClassName={classes.tableHeaderCell}
            />
            <TableBody>
              {deviceWillBeRemovedFromSchedules.map(schedule => (
                <ScheduleSimpleTableRow data={schedule} />
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </>
  )
}

export default withStyles(styles)(AutoAddDeviceToScheduleConflictContent)
