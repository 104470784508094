import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import { _isEmpty } from 'utils/lodash'

import DefaultModal from 'components/Modal/DefaultModal'
import MacAddressConflictContent from './MacAddressConflictContent'
import DeviceHasSchedulesConflictContent from './DeviceHasSchedulesConflictContent'
import FeatureDisabledContent from './FeatureDisabledContent'
import ScheduleConflictContent from './ScheduleConflicContent'
import MultipleScheduleAssociationContent from './MultipleScheduleAssociationContent'
import {
  conflictSources,
  conflictSourceToEntity,
  conflictTypes,
  exceptionsToConflictTypes
} from 'constants/api'
import AssociationHasSchedulesConflictContent from './AssociationHasSchedulesConflictContent'
import ModelAlreadyUsedContent from './ModelAlreadyUsedContent'
import CommonModelAlreadyUsedContent from './CommonModelAlreadyUsedContent'
import AutoAddDeviceToScheduleConflictContent from './AutoAddDeviceToScheduleConflictContent'
import { useUserRole } from 'hooks/tableLibrary'
import MultipleCollectionScheduleAssociationContent from './MultipleCollectionScheduleAssociationContent'
import DeviceHasAutoAssignedScheduleConflictContent from './DeviceHasAutoAssignedScheduleConflictContent'
import EntityHasAssignedScheduleConflictContent from './ScheduleHasAssignedScheduleConflictContent'
import DeleteEmailTemplateConflictContent from './DeleteEmailTemplateConflictContent'
import DeviceSchedulesConflictContent from './DeviceSchedulesConflictContent'
import ManageCustomParametersConflictContent from './ManageCustomParametersConflictContent'
import { notAllowedExceptionParser } from 'utils/deviceUtils'

const styles = () => ({
  removeRightPadding: {
    paddingRight: 0
  },
  dialogContainerMd: {
    maxWidth: '860px !important'
  },
  multiCollectionContainer: {
    maxWidth: '880px'
  }
})

const ConflictsModal = ({
  onConfirm,
  onClose,
  errors,
  classes,
  contentProps = {},
  conflictSource = conflictSources.general,
  getConflictType,
  isTimelineTable = false,
  clearConflictOnSubmit = true
}) => {
  const role = useUserRole()
  const { t } = useTranslation('translations')

  const [conflict, setConflict] = useState()

  const error = useMemo(() => errors.find(data => !_isEmpty(data)), [errors])

  const conflictType = useMemo(() => {
    if (!conflict) {
      return
    }

    const { exception, data } = conflict

    if (!exception) {
      return
    }

    return (
      (getConflictType && getConflictType(exception, data)) ||
      exceptionsToConflictTypes[exception]
    )
  }, [conflict, getConflictType])

  const handleConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm({ confirm: true, conflictType })
    }
    clearConflictOnSubmit && setConflict(null)
  }, [onConfirm, conflictType, clearConflictOnSubmit])

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose({ confirm: false, conflictType })
    }
    setConflict(null)
  }, [onClose, conflictType])

  useEffect(() => {
    if (error && error?.code === 409) {
      setConflict(error)
    }
  }, [error])

  const {
    Content,
    modalProps = {},
    contentProps: innerContentProps = {}
  } = useMemo(() => {
    switch (conflictType) {
      case conflictTypes.deviceMacAddress:
        return {
          Content: MacAddressConflictContent,
          modalProps: {
            modalTitle: t('MAC Address Conflicts'),
            onClickSave: handleClose,
            buttonPrimaryText: t('Ok'),
            maxWidth: 'md',
            contentClass: classes.removeRightPadding,
            hasCancelBtn: false
          }
        }
      case conflictTypes.deviceMacAddressForReuse:
        return {
          Content: MacAddressConflictContent,
          modalProps: {
            modalTitle: t('MAC Address Conflicts'),
            onClickSave: handleConfirm,
            buttonPrimaryText: t('Reuse'),
            maxWidth: 'md',
            contentClass: classes.removeRightPadding
          }
        }
      case conflictTypes.deviceHasSchedule:
        return {
          Content: DeviceHasSchedulesConflictContent,
          modalProps: {
            modalTitle: t('Schedules Association Conflict'),
            onClickSave: handleConfirm,
            buttonPrimaryText: t('Remove Schedules'),
            contentClass: classes.removeRightPadding
          }
        }
      case conflictTypes.associationHasSchedule:
        return {
          Content: AssociationHasSchedulesConflictContent,
          modalProps: {
            modalTitle: t('Schedules Association Conflict'),
            onClickSave: handleConfirm,
            buttonPrimaryText: t('Remove Schedules'),
            contentClass: classes.removeRightPadding
          }
        }
      case conflictTypes.featureDisabled:
      case conflictTypes.scheduleDeviceFeatureNotAllowedException:
        return {
          Content: FeatureDisabledContent,
          modalProps: {
            modalTitle: t('Feature(s) not enabled on Device(s)'),
            onClickSave: handleClose,
            buttonPrimaryText: t('OK'),
            maxWidth: 'sm',
            hasCancelBtn: false
          },
          contentProps: {
            ...(conflictTypes.scheduleDeviceFeatureNotAllowedException ===
              conflictType && { dataParser: notAllowedExceptionParser })
          }
        }
      case conflictTypes.schedulesConflict:
        return {
          Content: ScheduleConflictContent,
          modalProps: {
            modalTitle: t('Conflicting Schedule(s) in Device(s)'),
            onClickSave: handleConfirm,
            buttonPrimaryText: isTimelineTable ? t('OK') : t('Override'),
            ...(isTimelineTable && { hasCancelBtn: false }),
            maxWidth: 'lg'
          },
          contentProps: {
            hasConflictNote: !isTimelineTable,
            isTimelineTable
          }
        }
      case conflictTypes.deviceConflictedScheduleAssociationException:
        return {
          Content: DeviceSchedulesConflictContent,
          modalProps: {
            modalTitle: t('Schedules Association Conflict'),
            onClickSave: handleClose,
            buttonPrimaryText: t('Ok'),
            contentClass: classes.removeRightPadding,
            hasCancelBtn: false
          }
        }
      case conflictTypes.multipleScheduleAssociation:
        return {
          Content: MultipleScheduleAssociationContent,
          modalProps: {
            modalTitle: t('Scheduling Conflict'),
            onClickSave: handleConfirm,
            buttonPrimaryText: t('Override'),
            maxWidth: 'md',
            containerClassName: classes.dialogContainerMd,
            ...([
              conflictSources.addEditDevice,
              conflictSources.bulkTagsAssign,
              conflictSources.deviceToGroup,
              conflictSources.deviceLocation,
              conflictSources.scheduleTimeline
            ].includes(conflictSource) && {
              hasCancelBtn: false,
              buttonPrimaryText: t('OK')
            })
          }
        }
      case conflictTypes.multipleCollectionScheduleAssociation: {
        return {
          Content: MultipleCollectionScheduleAssociationContent,
          modalProps: {
            modalTitle: t('Devices With Existing Schedule'),
            onClickSave: handleClose,
            maxWidth: 'md',
            containerClassName: classes.multiCollectionContainer,
            hasCancelBtn: false,
            buttonPrimaryText: t('OK')
          }
        }
      }
      case conflictTypes.modelAlreadyUsed:
        //TODO refactor for single modal content
        if (
          [
            conflictSources.tag,
            conflictSources.designGalleryCategories
          ].includes(conflictSource)
        ) {
          return {
            Content: CommonModelAlreadyUsedContent,
            modalProps: {
              modalTitle: t('Item already used in:'),
              buttonPrimaryText: t('Delete'),
              maxWidth: 'sm',
              contentClass: classes.removeRightPadding,
              hasCancelBtn: false
            }
          }
        }

        if ([conflictSources.manageCustomParameters].includes(conflictSource)) {
          return {
            Content: ManageCustomParametersConflictContent,
            modalProps: {
              modalTitle: t('Parameter already used'),
              buttonPrimaryText: t('Ok'),
              maxWidth: 'sm',
              contentClass: classes.removeRightPadding,
              hasCancelBtn: false
            }
          }
        }

        return {
          Content: ModelAlreadyUsedContent,
          modalProps: {
            modalTitle: t('Item already used in:'),
            onClickSave: handleClose,
            buttonPrimaryText: t('Ok'),
            maxWidth: 'md',
            contentClass: classes.removeRightPadding,
            hasCancelBtn: false
          }
        }
      case conflictTypes.modelCollectionAlreadyUsed:
        return {
          Content: CommonModelAlreadyUsedContent,
          modalProps: {
            modalTitle: t('Items already used in:'),
            buttonPrimaryText: t('Delete'),
            maxWidth: 'sm',
            contentClass: classes.removeRightPadding,
            hasCancelBtn: false
          }
        }
      case conflictTypes.confirmAutoAddDeviceToScheduleException:
        return {
          Content: AutoAddDeviceToScheduleConflictContent,
          modalProps: {
            modalTitle: t('Confirmation of schedule publication'),
            onClickSave: handleConfirm,
            buttonPrimaryText: t('Yes'),
            buttonSecondaryText: t('No'),
            maxWidth: 'sm',
            containerClassName: classes.dialogContainerMd
          },
          contentProps: {
            ...(conflictSources.deviceToSchedule === conflictSource && {
              infoMessage: t(
                'There is an active schedule(s) assigned to this Group(s)/Tag(s).'
              )
            }),
            entities: [
              ...([
                conflictSources.addEditDevice,
                conflictSources.deviceToGroup
              ].includes(conflictSource)
                ? [t('Group')]
                : []),
              ...([
                conflictSources.addEditDevice,
                conflictSources.bulkTagsAssign
              ].includes(conflictSource) && !role.system
                ? [t('Tag')]
                : []),
              ...(conflictSource === conflictSources.deviceLocation
                ? [t('Location')]
                : [])
            ]
          }
        }
      case conflictTypes.deviceHasAutoAssignedScheduleException:
        return {
          Content: DeviceHasAutoAssignedScheduleConflictContent,
          modalProps: {
            modalTitle: t('Device has auto assigned schedule'),
            hasCancelBtn: false,
            onClickSave: handleClose,
            buttonPrimaryText: t('Ok')
          }
        }
      case conflictTypes.modelHasScheduleAssociationException:
        return {
          Content: EntityHasAssignedScheduleConflictContent,
          modalProps: {
            modalTitle: t('Entity has assigned schedule', {
              entity: t(conflictSourceToEntity[conflictSource] || 'Item')
            }),
            hasCancelBtn: false,
            onClickSave: handleClose,
            buttonPrimaryText: t('Ok'),
            maxWidth: 'md'
          }
        }
      case conflictTypes.removeEmailTemplateRelatedToEntitiesException:
        return {
          Content: DeleteEmailTemplateConflictContent,
          modalProps: {
            modalTitle: t('Email Template assigned to notification'),
            hasCancelBtn: false,
            buttonPrimaryText: t('Delete')
          }
        }
      default:
        return { Content: () => <div /> }
    }
  }, [
    isTimelineTable,
    conflictSource,
    handleClose,
    handleConfirm,
    conflictType,
    classes,
    role,
    t
  ])

  return (
    <DefaultModal
      open={!!conflictType}
      onCloseModal={handleClose}
      onClickSave={handleConfirm}
      buttonPrimaryIcon="fa-regular fa-circle-check"
      buttonSecondaryIcon="fa-regular fa-circle-xmark"
      {...modalProps}
    >
      <Content
        error={conflict}
        conflictSource={conflictSource}
        {...{
          ...innerContentProps,
          ...contentProps
        }}
      />
    </DefaultModal>
  )
}

ConflictsModal.propTypes = {
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  errors: PropTypes.array.isRequired,
  contentProps: PropTypes.object,
  conflictSource: PropTypes.oneOf(Object.values(conflictSources))
}

export default withStyles(styles)(memo(ConflictsModal))
