import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDrag } from 'react-dnd'
import classNames from 'classnames'
import { Tooltip, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import ImagePreview from './ImagePreview'
import FontPreview from './FontPreview'
import FontCombinationPreview from './FontCombinationPreview'
import { previewGridTypes } from 'constants/canvasConstants'
import DesignPreview from './DesignPreview'
import { getPreviewUrl } from 'components/Pages/DesignGallery/utils'
import SvgPreview from './SvgPreview'
import { designGalleryService } from 'services'
import useSnackbar from 'hooks/useSnackbar'
import useConfirmation from 'hooks/useConfirmation'
import { getThumbnail } from 'utils/mediaUtils'
import { featureNames } from 'constants/featureConstants'

const styles = () => ({
  root: {
    width: '100%',
    height: 'auto',
    cursor: 'pointer',
    borderRadius: '4px',
    position: 'relative',
    opacity: 0.6,
    overflow: 'hidden',
    '&:hover': {
      opacity: 0.99,
      '&::after': {
        visibility: 'visible'
      }
    }
  },
  border: {
    padding: 3
  },
  selected: {
    opacity: 0.99
  },
  borderReset: {
    borderColor: 'transparent',
    '&::after': {
      visibility: 'hidden',
      animation: 'none'
    }
  },
  actionRoot: {
    position: 'absolute',
    top: 4,
    right: 7,
    zIndex: 1
  },
  activeFavoriteLandscape: {
    left: 112,
    top: 24,
    color: '#4d99f0',
    position: 'relative',
    zIndex: 1
  },
  activeFavoritePortrait: {
    left: 70,
    top: 24,
    color: '#4d99f0',
    position: 'relative',
    zIndex: 1
  },
  inActiveFavoriteLandscape: {
    left: 112,
    top: 24,
    color: '#8c8d8e',
    position: 'relative',
    zIndex: 1
  },
  inActiveFavoritePortrait: {
    left: 70,
    top: 24,
    color: '#8c8d8e',
    position: 'relative',
    zIndex: 1
  },
  defaultComponentWrapper: {
    height: '100%'
  }
})

const PreviewGridsItem = ({
  grid,
  onClick,
  withBorder,
  isPortrait,
  withBlur,
  isLoading,
  selectedId,
  rootClassName,
  imageRootClassName,
  imageClassName,
  classes,
  imageHeight,
  renderActionComponent: ActionCompnent,
  showImagePlaceholder,
  isShapeSvg,
  imageErrorClass,
  placeHolderClass,
  t,
  showAddToFavorite = false,
  withPreviewComponent = true
}) => {
  const [errorImage, setErrorImage] = useState(false)
  const [isFavorite, setIsFavorite] = useState(grid?.extra?.isFavorite)
  const [imgLoading, setImgLoading] = useState(false)
  const { showAs, selected, id } = grid

  const { showSnackbar } = useSnackbar()
  const { showConfirmation } = useConfirmation()

  const [, dragRef] = useDrag({
    type: grid.type || 'preview grid item',
    item: grid,
    end(item, monitor) {
      if (item.isConfirmAction && !errorImage) {
        const canDrop = monitor.didDrop()
        const isCanvasDropResult = monitor.getTargetIds().length

        if (!canDrop && isCanvasDropResult) {
          item.isConfirmAction = false
          onClick(grid)
        }
      }
    }
  })

  const handleErrorImage = useCallback(() => {
    setErrorImage(true)
  }, [])

  const toggleFavorite = () => {
    if (!isFavorite) {
      designGalleryService.addDesignToFavorite(grid.id)
      setIsFavorite(true)
      showSnackbar('Design added to Favorites', 'success')
    } else {
      showConfirmation(
        t(
          'Are you sure you want to remove',
          { name: grid.name },
          'from Favorites?'
        ),
        () => {
          designGalleryService.removeDesignFromFavorite(grid.id)
          setIsFavorite(false)
          showSnackbar('Design removed from Favorites', 'success')
        }
      )
    }
  }

  const getPreviewComponent = showAs => {
    if (imgLoading) {
      return (
        <ImagePreview
          imageHeight={imageHeight}
          isLoading={imgLoading}
          isPortrait={isPortrait}
          imageRootClassName={imageRootClassName}
          imageClassName={imageClassName}
          error={true}
        />
      )
    }
    switch (showAs) {
      case previewGridTypes.svg:
        return (
          <SvgPreview
            src={getPreviewUrl(grid.src)}
            isLoading={withBlur && isLoading}
            isShapeSvg={isShapeSvg}
          />
        )
      case previewGridTypes.bg:
      case previewGridTypes.component:
      case previewGridTypes.image:
        return (
          <ImagePreview
            tooltip={grid.name}
            imageHeight={imageHeight}
            src={getPreviewUrl(grid.src)}
            isLoading={withBlur && isLoading}
            isPortrait={isPortrait}
            imageRootClassName={imageRootClassName}
            imageClassName={imageClassName}
            onError={handleErrorImage}
            error={errorImage}
          />
        )
      case previewGridTypes.font:
        return <FontPreview {...grid} />
      case previewGridTypes.fontCombination:
        return <FontCombinationPreview {...grid} />
      case previewGridTypes.template:
      case previewGridTypes.myDesign:
        return (
          <DesignPreview
            type={showAs}
            src={getPreviewUrl(grid.src)}
            name={grid.name || grid?.extra?.title}
            isLoading={withBlur && isLoading}
            imageRootClassName={imageRootClassName}
            showImagePlaceholder={showImagePlaceholder}
            imageClassName={imageClassName}
            isPortrait={isPortrait}
            imageHeight={imageHeight}
            imageErrorClass={imageErrorClass}
            placeHolderClass={placeHolderClass}
          />
        )
      default:
        return grid?.defaultComponent ? (
          grid?.defaultComponent
        ) : (
          <div>Example div</div>
        )
    }
  }

  const handleClick = useCallback(() => onClick(grid), [grid, onClick])

  const isSelected = useMemo(() => selected || (id && id === selectedId), [
    id,
    selected,
    selectedId
  ])

  return (
    <div className={classNames(classes.root, rootClassName)}>
      {showAddToFavorite && (
        <Tooltip
          title={isFavorite ? 'Remove From Favorite' : 'Add To Favorite'}
          arrow
        >
          <i
            className={classNames('fa fa-star', {
              [classes.activeFavoriteLandscape]: !isPortrait && isFavorite,
              [classes.inActiveFavoriteLandscape]: !isPortrait && !isFavorite,
              [classes.activeFavoritePortrait]: isPortrait && isFavorite,
              [classes.inActiveFavoritePortrait]: isPortrait && !isFavorite
            })}
            onClick={toggleFavorite}
          />
        </Tooltip>
      )}
      <div
        {...(!errorImage && { onClick: handleClick, ref: dragRef })}
        className={classNames(
          {
            [classes.border]: withBorder,
            [classes.borderReset]: !isSelected,
            [classes.selected]: isSelected,
            [classes.defaultComponentWrapper]: grid?.defaultComponent
          },
          'gradient-border'
        )}
      >
        {ActionCompnent && (
          <div className={classes.actionRoot}>
            <ActionCompnent
              item={grid}
              loading={imgLoading}
              setLoading={setImgLoading}
            />
          </div>
        )}
        {withPreviewComponent ? (
          getPreviewComponent(showAs)
        ) : (
          <div>
            {grid?.defaultComponent ? (
              grid?.defaultComponent
            ) : (
              <DesignPreview
                {...(grid.feature?.name === featureNames.Wayfinding
                  ? {
                      type: featureNames.Wayfinding
                    }
                  : {
                      type: previewGridTypes.myDesign
                    })}
                src={getThumbnail(grid)}
                thumbnailIcon={grid.feature?.icon}
                thumbnailColor={grid.feature?.color}
                name={grid?.title}
                isLoading={withBlur && isLoading}
                imageRootClassName={imageRootClassName}
                showImagePlaceholder={showImagePlaceholder}
                imageClassName={imageClassName}
                isPortrait={isPortrait}
                imageHeight={imageHeight}
                imageErrorClass={imageErrorClass}
                placeHolderClass={placeHolderClass}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

PreviewGridsItem.propTypes = {
  grid: PropTypes.object,
  onClick: PropTypes.func,
  cols: PropTypes.oneOf([1, 2, 3]),
  withBorder: PropTypes.bool,
  rootClassName: PropTypes.string,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isShapeSvg: PropTypes.bool,
  imageErrorClass: PropTypes.string
}

export default withTranslation('translations')(
  withStyles(styles)(PreviewGridsItem)
)
