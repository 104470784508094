import React from 'react'
import { Link, Grid } from '@material-ui/core'

import { TextWithTooltip } from 'components/Typography'
import { getUrlPrefix } from 'utils'
import { routeByName } from 'constants/index'
import { filterStatuses } from 'constants/libraryConstants'

const ClientCell = ({ data: { client } }) => {
  return (
    <Link
      href={getUrlPrefix(
        `${routeByName.clients.root}?id=${client.id}&exact=true&status=${filterStatuses.any}`
      )}
      target="_blank"
      underline="always"
    >
      <Grid
      // style={{
      //   maxWidth: defaultColumnWidth.clientName,
      //   width: 'fit-content'
      // }}
      >
        <TextWithTooltip maxWidth="100%" color="highlight" id="clientNameLink">
          {`${client.name} [${client.id}]`}
        </TextWithTooltip>
      </Grid>
    </Link>
  )
}

export default ClientCell
