import { useSnackbar as _useSnackbar } from 'notistack'
import React, { useCallback, useMemo } from 'react'
import { Button, makeStyles } from '@material-ui/core'

import { DEFAULT_NOTIFICATION_DURATION } from 'constants/api'

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'none'
  }
}))

export default function useSnackbar() {
  const { enqueueSnackbar, closeSnackbar } = _useSnackbar()
  const classes = useStyles()

  const handleCloseSnackbar = useCallback(
    (key, cb) => {
      if (cb && typeof cb === 'function') cb()
      closeSnackbar(key)
    },
    [closeSnackbar]
  )

  const showSnackbar = useCallback(
    (
      message,
      variant = 'default',
      buttonText,
      callback,
      persist = false,
      options = {},
      customAction
    ) => {
      enqueueSnackbar(message, {
        variant,
        action:
          buttonText || customAction
            ? key =>
                customAction ? (
                  customAction({ onClose: () => closeSnackbar(key) })
                ) : (
                  <Button
                    className={classes.button}
                    color="secondary"
                    size="small"
                    onClick={() => handleCloseSnackbar(key, callback)}
                  >
                    {buttonText}
                  </Button>
                )
            : undefined,
        preventDuplicate: true,
        autoHideDuration: DEFAULT_NOTIFICATION_DURATION,
        persist: buttonText && persist,
        ...options
      })
    },
    [enqueueSnackbar, handleCloseSnackbar, classes, closeSnackbar]
  )

  return useMemo(
    () => ({
      showSnackbar,
      closeSnackbar
    }),
    [showSnackbar, closeSnackbar]
  )
}
