import React, {
  Fragment,
  useState,
  useEffect,
  Suspense,
  useCallback,
  useMemo
} from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { SnackbarProvider } from 'notistack'
import { DndProvider } from 'react-dnd'
import { _get } from 'utils/lodash'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { LinkedInCallback } from 'react-linkedin-login-oauth2'
import update from 'immutability-helper'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { StylesProvider, createGenerateClassName } from '@material-ui/styles'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import { withStyles, CssBaseline } from '@material-ui/core'
import { GoogleOAuthProvider } from '@react-oauth/google'

import i18n from './i18n'
import defaultTheme, { AUTO, DARK, LIGHT } from './theme'
import Header from 'components/Header'
import Footer from 'components/Footer'
import {
  SignIn,
  SignUp,
  EmailVerified,
  ForgotPassword,
  SystemSignIn,
  AccessDenied,
  SsoLogin
} from 'components/Account'
import MicrosoftLoginProcessing from 'components/Account/SocialLogin/MicrosoftLoginProcessing'
import WhiteLabelProvider from 'components/WhiteLabelProvider'
import ContextMenu from 'components/ContextMenu'
import {
  UserDashboard,
  AccountSettings,
  MediaLibrary,
  PlaylistLibrary,
  ReportsLibrary,
  ScheduleLibrary,
  ScheduleTimeline,
  TemplateLibrary,
  FontLibrary,
  CustomReport,
  DesignGallery,
  RolesAndPermissions,
  ApplicationHealth,
  EulaTerms,
  HTMLContentLibrary,
  WorkplacePostersLibrary,
  TemplateBackgroundLibrary,
  MenuMakerLibrary,
  MenuMakerDesign,
  RoomsLibrary,
  ThirdPartyReports,
  UserProfile,
  DownloadReport,
  ProofOfPlayReports,
  ReportsApiUsage,
  DesignGalleryLibrary
} from 'components/Pages'
import {
  AdminDashboard,
  UsersLibrary,
  DeviceLibrary,
  DeviceNOC,
  AppVersionsLibrary,
  ClientLicensesLibrary,
  LicensesLibrary,
  ClientsLibrary,
  PackagesLibrary,
  HelpPagesLibrary,
  ContentAppHelpPagesLibrary,
  OEMClientsLibrary,
  MediaContentSource,
  TagsLibrary,
  ClientUsersLibrary,
  ResellerClientsLibrary,
  ResellerClientUsersLibrary,
  DeviceModelsLibrary,
  SuperAdminSettings,
  EmailNotificationsLibrary,
  UserEulaTerms,
  VideoTutorialsComponent,
  FeatureManagement,
  ResellerUsersLibrary
} from 'components/Pages/Admin'
import {
  UnauthorizedRoute,
  SystemRoute,
  PermissionRoute
} from 'components/Routes'
import { getGoogleFonts } from 'actions/fontsActions'
import { getUserDetailsAction, putUserDetailsAction } from 'actions/userActions'
import routeByName from 'constants/routes'
import { HTML_CONTENT } from 'constants/library'
import featureConstants, {
  EULA_APP_FEATURE,
  MENU_MAKER_APP_FEATURE,
  EMAIL_NOTIFICATION_APP_FEATURE,
  DEVICE_NOC_FEATURE,
  featureNames,
  SPACES_BOOKING_FEATURE
} from 'constants/featureConstants'
import {
  getRedirectUrlForUnauthorized,
  getUrlPrefix
} from 'utils/permissionUrls'
import ResellerRoute from './components/Routes/ResellerRoute'
import { isExpired } from 'utils/date'
import './styles/index.scss'
import InactivityTimer from 'components/InactivityTimer'
import useRefreshToken from 'hooks/useRefreshToken'
import useWindowTitle from 'hooks/useWindowTitle'
import { instanceTypes, redirectRoutes, tfaTypes } from 'constants/api'
import useUserPermissionGroupsByType from 'hooks/api/useUserPermissionGroupsByType'
import { permissionTypes } from 'constants/permissionGroups'
import { permissionGroups } from './constants'
import { checkPermissions } from 'utils/permissionsUtils'
import NoPermissionsPage from 'components/Pages/NoPermissionsPage'
import isEmpty from 'lodash/isEmpty'
import SocketNotification from 'components/SocketNotification'
import CircularLoader from 'components/Loaders/CircularLoader'
import socket from 'services/socket'
import ErrorBoundary from './components/ErrorBoundary'
import useAutoLogout from './hooks/api/useAutoLogout'
import usePasswordReminder from './hooks/api/usePasswordReminder'
import useWindowMessageListeners from 'hooks/api/useWindowMessageListeners'
import MediaPreviewSettingsProvider from 'components/Media/MediaPreviewSettingsContext'
import EulaModal from 'components/EulaModal/EulaModal'
import useLegacyThemesState from 'hooks/api/useLegacyThemesState'
import { isFeatureAvailable } from 'utils/api/featureAvailability'
import ContentApprove from 'components/Pages/ContentApprove'
import ServiceNotifications from './components/ServiceNotifications'
import { FavoriteFeaturesSplashModal } from 'components/Modal'
import ActivateDevice from './components/Pages/ActivateDevice'
import {
  AssignCreatedEntityContextProvider,
  ThemeContextProvider,
  ContextMenuContextProvider
} from 'contexts'
import useAssignCreatedEntity from 'hooks/api/useAssignCreatedEntity'
import useRefiner from 'hooks/api/useRefiner'
import { zIndexes } from 'constants/stylesConstants'
import PublicPages from 'components/PublicPages'
import TopBanner from 'components/Banner/TopBanner'
import useTopBanner from 'hooks/api/useTopBanner'
import useUserRole from 'hooks/tableLibrary/useUserRole'
import usePostHog from './hooks/api/usePostHog'
import useFeaturesAvailability from 'hooks/api/useFeaturesAvailability'
import useSpaceBookingPermissions from 'hooks/useSpaceBookingPermissions'
import useScript from './hooks/useScript'
import InstagramLoginProcessing from 'components/Account/SocialLogin/InstagramLoginProcessing'
import ItsaCheckmateRedirect from './components/Pages/MenuMakerLibrary/Integrations/Provider/ItsaCheckmateRedirect'
import ApiErrorHandler from 'components/ApiErrorHandler'
import {
  TimeoutErrorPage,
  ServerErrorPage,
  MaintenanceErrorPage,
  ExpiredPassworldessLoginErrorPage
} from 'components/Pages/ErrorPages'
import useDeviceReboot from 'hooks/useDeviceReboot'
import { isStorageHasToken } from 'utils/apiUtils'
import ManuallySignIn from 'components/Account/ManuallySignIn'
import InteractiveWayfindListView from './components/Pages/InteractiveWayfindEditor/index'
import InteractiveWayfindEditor from './components/Pages/InteractiveWayfindEditor/InteractiveWayfindEditor'
import Documentation from './components/Pages/Documentation'
import PasswordlessLogin from './components/Account/PasswordlessLogin'
import GoogleAuthenticatorSetupModal from './components/Modal/GoogleAuthenticatorSetupModal'
import RecoveryCodesModal from './components/Modal/RecoveryCodesModal'

const styles = () => ({
  mainContainer: {
    maxWidth: 1600,
    margin: '16px auto 0',
    paddingTop: 80
  },
  confirmation: {
    '&::before': {
      content: '""',
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: zIndexes.confirmation
    }
  },
  hasTopBanner: {
    paddingTop: 115
  }
})

const {
  Feeds,
  RSSFeed,
  MediaRSS,
  YouTube,
  Radio,
  CustomWidget,
  News,
  Twitch,
  VideoTutorials
} = featureConstants

const isProduction = process.env.REACT_APP_INSTANCE === instanceTypes.prod

const appWrapperMapStateToProps = ({ user, login, appReducer }) => ({
  login: login,
  details: user.details,
  minContainerHeight: appReducer.groupModalHeight,
  isConfirmationRequired: appReducer.isConfirmationRequired
})

const appWrapperMapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUserDetailsAction
    },
    dispatch
  )

const AppWrapper = connect(
  appWrapperMapStateToProps,
  appWrapperMapDispatchToProps
)(
  withStyles(styles, { withTheme: true })(
    ({
      classes,
      details = {},
      login = {},
      getUserDetailsAction,
      minContainerHeight,
      theme,
      setTheme,
      setCurrentTheme,
      isConfirmationRequired,
      ...props
    }) => {
      const readGroups = useUserPermissionGroupsByType(permissionTypes.read)
      const createGroups = useUserPermissionGroupsByType(permissionTypes.create)
      const topBanner = useTopBanner()
      const [
        applicationHealthAvailable,
        showDeviceNoc,
        showMenuMaker,
        videoTutorialsAvailable,
        showWayfinding
      ] = useFeaturesAvailability(
        featureNames.ApplicationHealth,
        featureNames.DeviceNoc,
        featureNames.MenuMaker,
        featureNames.VideoTutorials,
        featureNames.Wayfinding
      )

      const {
        hasPermission: hasSpaceBookingPermission
      } = useSpaceBookingPermissions()

      const role = useUserRole()

      const reportRead = checkPermissions(readGroups, [
        permissionGroups.ORG_REPORT,
        permissionGroups.SYSTEM_REPORT
      ])

      const reportWrite = checkPermissions(createGroups, [
        permissionGroups.ORG_REPORT,
        permissionGroups.SYSTEM_REPORT
      ])

      useAutoLogout()
      usePasswordReminder()
      useLegacyThemesState()
      useAssignCreatedEntity()
      useRefiner()
      usePostHog()
      useScript('https://weatherwidget.io/js/widget.min.js')
      useWindowMessageListeners()

      useEffect(() => {
        const isLoggedOut = isExpired()

        if (!details.response) getUserDetailsAction()

        if (!isLoggedOut) {
          socket.connect()
        }
        // eslint-disable-next-line
      }, [])

      useEffect(() => {
        if (details.response && details.response.themeMode && theme) {
          setCurrentTheme(details.response.themeMode)
          localStorage.setItem('theme', details.response.themeMode)
        }

        const redirectExceptions = [routeByName.activateDevice]

        if (details.response) {
          const urlRoleLevel = props.location.pathname.split('/')?.[1] || ''
          if (isEmpty(details.response.permissions)) {
            props.history.replace('/no-permissions')
          } else if (
            _get(details, 'response.role.level').toLowerCase() !==
              urlRoleLevel.toLowerCase() &&
            !redirectExceptions.includes(props.location.pathname)
          ) {
            props.history.replace(getRedirectUrlForUnauthorized())
          }
        }
        // eslint-disable-next-line
      }, [details.response])

      return !details.response ? (
        <></> //prevent redirects if permissions are not loaded
      ) : (
        <EulaModal>
          <Fragment>
            <InactivityTimer />
            <TopBanner
            // deactivateMessage={userDetails?.client?.deactivateMessage}
            // deactivateNotificationType={
            //   userDetails?.client?.deactivateNotificationType
            // }
            />
            <ContextMenu />
            <Header
              currentTheme={props.currentTheme}
              dark={props.dark}
              handleThemeChange={props.handleThemeChange}
              hasTopBanner={topBanner.visible}
            />
            <MediaPreviewSettingsProvider>
              <div
                className={classNames(classes.mainContainer, 'main-container', {
                  DarkTheme: props.dark,
                  [classes.confirmation]: isConfirmationRequired,
                  [classes.hasTopBanner]: topBanner.visible
                })}
              >
                <ErrorBoundary>
                  <Suspense fallback={<CircularLoader />}>
                    <div style={{ minHeight: minContainerHeight }}>
                      <Route
                        path="/org/dashboard"
                        component={
                          readGroups.includes(permissionGroups.DASHBOARD)
                            ? UserDashboard
                            : () => <UserDashboard noPermission />
                        }
                      />
                      <PermissionRoute
                        path="/org/account-settings"
                        component={AccountSettings}
                        allowed={readGroups.includes(permissionGroups.SETTING)}
                        lazyLoad={true}
                      />
                      <PermissionRoute
                        path={getUrlPrefix(routeByName.schedule.root)}
                        component={ScheduleLibrary}
                        allowed={readGroups.includes(permissionGroups.SCHEDULE)}
                      />
                      <PermissionRoute
                        path={getUrlPrefix(routeByName.schedule.timeline)}
                        component={ScheduleTimeline}
                        allowed={readGroups.includes(permissionGroups.SCHEDULE)}
                      />
                      <PermissionRoute
                        path={`/org/${routeByName.users.root}`}
                        component={UsersLibrary}
                        allowed={readGroups.includes(permissionGroups.USER)}
                      />
                      <Route
                        path="/no-permissions"
                        component={NoPermissionsPage}
                      />
                      <PermissionRoute
                        path={`/org/${routeByName.device.root}`}
                        component={DeviceLibrary}
                        allowed={readGroups.includes(permissionGroups.DEVICE)}
                      />
                      <PermissionRoute
                        path={`/org/${routeByName.tag.root}`}
                        component={TagsLibrary}
                        allowed={readGroups.includes(permissionGroups.TAG)}
                        lazyLoad={true}
                      />

                      {videoTutorialsAvailable && (
                        <PermissionRoute
                          path={`/${role.role}/${routeByName.tutorialVideos.root}`}
                          component={VideoTutorialsComponent}
                          allowed={videoTutorialsAvailable}
                        />
                      )}
                      <ResellerRoute
                        path={routeByName.reseller.client}
                        component={ResellerClientsLibrary}
                        allowed={readGroups.includes(
                          permissionGroups.RESELLER_CLIENT
                        )}
                      />
                      <ResellerRoute
                        path={routeByName.reseller.clientUser}
                        component={ResellerClientUsersLibrary}
                        allowed={readGroups.includes(
                          permissionGroups.RESELLER_CLIENT_USER
                        )}
                      />
                      <ResellerRoute
                        path={`/reseller/${routeByName.users.root}`}
                        component={ResellerUsersLibrary}
                        // allowed={readGroups.includes(
                        //   permissionGroups.RESELLER_USER
                        // )}
                      />
                      <PermissionRoute
                        path="/enterprise/dashboard"
                        component={AdminDashboard}
                        allowed={readGroups.includes(
                          permissionGroups.DASHBOARD
                        )}
                      />
                      <PermissionRoute
                        path="/enterprise/tags-library"
                        component={TagsLibrary}
                        allowed={readGroups.includes(permissionGroups.TAG)}
                      />
                      <PermissionRoute
                        path="/enterprise/device-library"
                        component={DeviceLibrary}
                        allowed={readGroups.includes(permissionGroups.DEVICE)}
                      />
                      <PermissionRoute
                        path={`/enterprise/${routeByName.users.root}`}
                        component={UsersLibrary}
                        allowed={readGroups.includes(permissionGroups.USER)}
                      />
                      <PermissionRoute
                        path="/enterprise/account-settings"
                        component={AccountSettings}
                        allowed={readGroups.includes(permissionGroups.SETTING)}
                        lazyLoad={true}
                      />
                      <PermissionRoute
                        path={`/enterprise/${routeByName.clients.root}`}
                        component={ClientsLibrary}
                        allowed={readGroups.includes(permissionGroups.CLIENT)}
                      />
                      <SystemRoute
                        path="/system/settings"
                        component={SuperAdminSettings}
                        allowed={readGroups.includes(permissionGroups.SETTING)}
                      />
                      <SystemRoute
                        path={routeByName.clientUsers.eulaTerms}
                        component={UserEulaTerms}
                        allowed={readGroups.includes(permissionGroups.EULA)}
                      />
                      <SystemRoute
                        path={routeByName.clientUsers.root}
                        component={ClientUsersLibrary}
                        allowed={readGroups.includes(
                          permissionGroups.CLIENT_USER
                        )}
                      />
                      <SystemRoute
                        path="/system/dashboard"
                        component={AdminDashboard}
                        allowed={readGroups.includes(
                          permissionGroups.DASHBOARD
                        )}
                      />
                      <SystemRoute
                        path={`/system/${routeByName.users.root}`}
                        component={UsersLibrary}
                        allowed={readGroups.includes(permissionGroups.USER)}
                      />
                      <SystemRoute
                        path={`/system/${routeByName.clients.root}`}
                        component={ClientsLibrary}
                        allowed={readGroups.includes(permissionGroups.CLIENT)}
                      />
                      <SystemRoute
                        path={`/system/${routeByName.clientLicenses.root}`}
                        component={ClientLicensesLibrary}
                        allowed={readGroups.includes(
                          permissionGroups.CLIENT_LICENSE
                        )}
                      />
                      <SystemRoute
                        path={routeByName.licenses.root}
                        component={LicensesLibrary}
                        allowed={readGroups.includes(
                          permissionGroups.DEVICE_REFRESH_LICENSE
                        )}
                      />
                      <SystemRoute
                        path="/system/packages-library"
                        component={PackagesLibrary}
                        allowed={checkPermissions(readGroups, [
                          permissionGroups.CLIENT_PACKAGE,
                          permissionGroups.BANDWIDTH_PACKAGE,
                          permissionGroups.DEVICE_PACKAGE
                        ])}
                      />
                      <SystemRoute
                        path={`/system/${routeByName.features.root}`}
                        component={FeatureManagement}
                        allowed={readGroups.includes(permissionGroups.FEATURE)}
                      />
                      <SystemRoute
                        path="/system/help-pages-library"
                        component={HelpPagesLibrary}
                        allowed={readGroups.includes(
                          permissionGroups.HELP_PAGE
                        )}
                      />
                      <SystemRoute
                        path={`/system/${routeByName.contentAppHelp.root}`}
                        component={ContentAppHelpPagesLibrary}
                        allowed={readGroups.includes(
                          permissionGroups.CONTENT_APP_HELP_PAGE
                        )}
                      />
                      <SystemRoute
                        path={`/system/${routeByName.deviceModels.root}`}
                        component={DeviceModelsLibrary}
                        allowed={readGroups.includes(
                          //TODO change to device model
                          permissionGroups.DEVICE_TYPE
                        )}
                      />
                      <SystemRoute
                        path={`/system/${routeByName.appVersions.root}`}
                        component={AppVersionsLibrary}
                        allowed={readGroups.includes(
                          permissionGroups.APP_VERSION
                        )}
                      />
                      <SystemRoute
                        path="/system/oem-clients-library"
                        component={OEMClientsLibrary}
                      />
                      <SystemRoute
                        path={routeByName[RSSFeed].root}
                        propsComponent={{ feature: RSSFeed }}
                        component={MediaContentSource}
                        allowed={readGroups.includes(
                          permissionGroups.MEDIA_CONTENT_SOURCE
                        )}
                      />
                      <SystemRoute
                        path={routeByName[Feeds].root}
                        propsComponent={{ feature: Feeds }}
                        component={MediaContentSource}
                        allowed={readGroups.includes(
                          permissionGroups.MEDIA_CONTENT_SOURCE
                        )}
                      />
                      <SystemRoute
                        path={routeByName[MediaRSS].root}
                        propsComponent={{ feature: MediaRSS }}
                        component={MediaContentSource}
                        allowed={readGroups.includes(
                          permissionGroups.MEDIA_CONTENT_SOURCE
                        )}
                      />

                      <SystemRoute
                        path={`${routeByName[VideoTutorials].root}`}
                        propsComponent={{ feature: VideoTutorials }}
                        component={MediaContentSource}
                      />
                      <SystemRoute
                        path={routeByName[YouTube].root}
                        propsComponent={{ feature: YouTube }}
                        component={MediaContentSource}
                        allowed={readGroups.includes(
                          permissionGroups.MEDIA_CONTENT_SOURCE
                        )}
                      />
                      <SystemRoute
                        path={routeByName[Radio].root}
                        propsComponent={{ feature: Radio }}
                        component={MediaContentSource}
                        allowed={readGroups.includes(
                          permissionGroups.MEDIA_CONTENT_SOURCE
                        )}
                      />
                      <SystemRoute
                        path={routeByName[CustomWidget].root}
                        propsComponent={{ feature: CustomWidget }}
                        component={MediaContentSource}
                        allowed={readGroups.includes(
                          permissionGroups.MEDIA_CONTENT_SOURCE
                        )}
                      />
                      <SystemRoute
                        path={routeByName[News].root}
                        propsComponent={{ feature: News }}
                        component={MediaContentSource}
                        allowed={readGroups.includes(
                          permissionGroups.MEDIA_CONTENT_SOURCE
                        )}
                      />
                      <SystemRoute
                        path={routeByName[Twitch].root}
                        propsComponent={{ feature: Twitch }}
                        component={MediaContentSource}
                        allowed={readGroups.includes(
                          permissionGroups.MEDIA_CONTENT_SOURCE
                        )}
                      />
                      <SystemRoute
                        path={routeByName.rolesPermissions.index}
                        component={RolesAndPermissions}
                        allowed={readGroups.includes(permissionGroups.ROLES)}
                      />
                      <SystemRoute
                        path="/system/eula-terms"
                        component={EulaTerms}
                        allowed={
                          isFeatureAvailable(EULA_APP_FEATURE) &&
                          readGroups.includes(permissionGroups.EULA)
                        }
                      />
                      <SystemRoute
                        path={getUrlPrefix(routeByName.htmlContent.root)}
                        propsComponent={{ variant: HTML_CONTENT }}
                        component={HTMLContentLibrary}
                        allowed={readGroups.includes(
                          permissionGroups.HTML_CONTENT
                        )}
                      />
                      <SystemRoute
                        path={getUrlPrefix(routeByName.workplacePosters.root)}
                        component={WorkplacePostersLibrary}
                        allowed={readGroups.includes(permissionGroups.POSTER)}
                      />
                      <SystemRoute
                        path={`/system/${routeByName.device.root}/:view?`}
                        component={DeviceLibrary}
                        allowed={readGroups.includes(permissionGroups.DEVICE)}
                      />
                      <SystemRoute
                        path={`/:role/${routeByName.templateBackground.root}`}
                        component={TemplateBackgroundLibrary}
                        allowed={readGroups.includes(
                          permissionGroups.TEMPLATE_BACKGROUND_PATTERN
                        )}
                      />
                      {isFeatureAvailable(DEVICE_NOC_FEATURE) &&
                        showDeviceNoc && (
                          <PermissionRoute
                            path={`/org/${routeByName.deviceNOC.root}`}
                            component={DeviceNOC}
                            allowed={readGroups.includes(
                              permissionGroups.DEVICE
                            )}
                          />
                        )}
                      <PermissionRoute
                        path={getUrlPrefix('application-health')}
                        component={ApplicationHealth}
                        allowed={
                          applicationHealthAvailable &&
                          readGroups.includes(permissionGroups.APP_HEALTH)
                        }
                        lazyLoad={true}
                      />
                      <Route
                        exact
                        path={['/', '/org', '/enterprise', '/system']}
                        render={props => (
                          <Redirect to={getUrlPrefix('dashboard')} {...props} />
                        )}
                      />
                      <PermissionRoute
                        path={getUrlPrefix(routeByName.template.root)}
                        component={TemplateLibrary}
                        allowed={readGroups.includes(permissionGroups.TEMPLATE)}
                        lazyLoad={true}
                      />
                      <PermissionRoute
                        path={getUrlPrefix(routeByName.playlist.root)}
                        component={PlaylistLibrary}
                        allowed={readGroups.includes(permissionGroups.PLAYLIST)}
                        lazyLoad={true}
                      />
                      <PermissionRoute
                        exact
                        path={[
                          getUrlPrefix(routeByName.designGallery.addDesign),
                          getUrlPrefix(routeByName.designGallery.edit)
                        ]}
                        component={DesignGallery}
                        allowed={createGroups.includes(
                          permissionGroups.DESIGN_GALLERY
                        )}
                        lazyLoad={true}
                      />
                      <PermissionRoute
                        path={getUrlPrefix(routeByName.designGallery.root)}
                        component={DesignGalleryLibrary}
                        allowed={createGroups.includes(
                          permissionGroups.DESIGN_GALLERY
                        )}
                        lazyLoad={true}
                      />
                      {isFeatureAvailable(MENU_MAKER_APP_FEATURE) && (
                        <PermissionRoute
                          exact
                          path={[
                            getUrlPrefix(routeByName.menuMaker.addDesign),
                            getUrlPrefix(routeByName.menuMaker.editDesign),
                            getUrlPrefix(routeByName.menuMaker.editPreset),
                            getUrlPrefix(routeByName.menuMaker.viewDesign)
                          ]}
                          component={MenuMakerDesign}
                          allowed={
                            (!role.org || showMenuMaker) &&
                            createGroups.includes(permissionGroups.MENU_MAKER)
                          }
                          lazyLoad={true}
                        />
                      )}
                      {isFeatureAvailable(MENU_MAKER_APP_FEATURE) && (
                        <PermissionRoute
                          path={getUrlPrefix(routeByName.menuMaker.root)}
                          component={MenuMakerLibrary}
                          allowed={
                            readGroups.includes(permissionGroups.MENU_MAKER) &&
                            showMenuMaker
                          }
                          lazyLoad={true}
                        />
                      )}
                      {isFeatureAvailable(SPACES_BOOKING_FEATURE) && (
                        <PermissionRoute
                          path={getUrlPrefix(routeByName.room.root)}
                          component={RoomsLibrary}
                          allowed={hasSpaceBookingPermission}
                        />
                      )}

                      <PermissionRoute
                        path="/account-settings"
                        component={AccountSettings}
                        allowed={readGroups.includes(permissionGroups.SETTING)}
                        lazyLoad={true}
                      />
                      <Route
                        path={[
                          getUrlPrefix(routeByName.user.userProfile),
                          `/reseller/${routeByName.user.userProfile}`
                        ]}
                        component={UserProfile}
                      />
                      <PermissionRoute
                        path={getUrlPrefix(routeByName.media.root)}
                        component={MediaLibrary}
                        allowed={readGroups.includes(permissionGroups.MEDIA)}
                        lazyLoad={true}
                      />
                      <PermissionRoute
                        path={getUrlPrefix(routeByName.fontLibrary.root)}
                        component={FontLibrary}
                        allowed={readGroups.includes(
                          permissionGroups.MEDIA_FONT
                        )}
                        lazyLoad={true}
                      />
                      <PermissionRoute
                        path={getUrlPrefix(routeByName.report.root)}
                        component={ReportsLibrary}
                        allowed={reportRead}
                        lazyLoad={true}
                      />
                      <SystemRoute
                        exact
                        path={getUrlPrefix(routeByName.reportApiUsage.root)}
                        component={ReportsApiUsage}
                      />
                      <PermissionRoute
                        path="/custom-report/:id"
                        component={CustomReport}
                        allowed={reportRead && reportWrite}
                        lazyLoad={true}
                      />
                      {checkPermissions(readGroups, [
                        permissionGroups.ORG_REPORT,
                        permissionGroups.SYSTEM_REPORT
                      ]) && (
                        <>
                          {checkPermissions(createGroups, [
                            permissionGroups.ORG_REPORT,
                            permissionGroups.SYSTEM_REPORT
                          ]) && (
                            <>
                              <Route
                                path={getUrlPrefix(routeByName.report.download)}
                                component={DownloadReport}
                              />
                            </>
                          )}
                        </>
                      )}
                      {checkPermissions(readGroups, [
                        permissionGroups.SYSTEM_REPORT
                      ]) && (
                        <SystemRoute
                          exact
                          path={getUrlPrefix(routeByName.thirdPartyReport.root)}
                          component={ThirdPartyReports}
                        />
                      )}
                      <Route
                        path={getUrlPrefix(routeByName.proofOfPlayReports.root)}
                        component={ProofOfPlayReports}
                      />
                      <PermissionRoute
                        path="/system/tags-library"
                        component={TagsLibrary}
                        allowed={readGroups.includes(permissionGroups.TAG)}
                        lazyLoad={true}
                      />
                      {isFeatureAvailable(EMAIL_NOTIFICATION_APP_FEATURE) && (
                        <PermissionRoute
                          path={getUrlPrefix(routeByName.notifications.root)}
                          component={EmailNotificationsLibrary}
                          allowed={readGroups.includes(
                            permissionGroups.DYNAMIC_EMAIL_NOTIFICATION
                          )}
                          lazyLoad={true}
                        />
                      )}
                      <Route
                        path={routeByName.contentApprove.root}
                        component={ContentApprove}
                      />
                      <Route
                        path={routeByName.activateDevice}
                        component={ActivateDevice}
                      />
                      <PermissionRoute
                        path={getUrlPrefix(routeByName.interactiveWayfind.root)}
                        component={InteractiveWayfindListView}
                        allowed={showWayfinding}
                      />
                      <PermissionRoute
                        path={getUrlPrefix(routeByName.interactiveWayfind.add)}
                        component={InteractiveWayfindEditor}
                        allowed={showWayfinding}
                      />
                      <PermissionRoute
                        path={getUrlPrefix(routeByName.interactiveWayfind.edit)}
                        component={InteractiveWayfindEditor}
                        allowed={showWayfinding}
                      />
                      <SystemRoute
                        path={getUrlPrefix(routeByName.documentation)}
                        component={Documentation}
                      />
                    </div>
                  </Suspense>
                </ErrorBoundary>
                {details?.response?.isNeedToSetupGoogle2FA && (
                  <GoogleAuthenticatorSetupModal />
                )}
                <RecoveryCodesModal />
                <FavoriteFeaturesSplashModal />
                <Footer dark={props.dark} />
              </div>
            </MediaPreviewSettingsProvider>
          </Fragment>
        </EulaModal>
      )
    }
  )
)

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        !isStorageHasToken() ? (
          rest.location.pathname === '/system' ? (
            <Redirect
              to={{
                pathname: '/system/sign-in',
                state: { from: props.location }
              }}
            />
          ) : (
            <Redirect
              to={{ pathname: '/sign-in', state: { from: props.location } }}
            />
          )
        ) : (
          <Component
            {...props}
            dark={rest.dark}
            currentTheme={rest.currentTheme}
            setCurrentTheme={rest.setCurrentTheme}
            handleThemeChange={rest.handleThemeChange}
            setTheme={rest.setTheme}
          />
        )
      }
    />
  )
}

const generateClassName = createGenerateClassName({
  seed: 'xhibit'
})

const App = ({ getGoogleFonts, putUserDetailsAction }) => {
  useRefreshToken(isStorageHasToken())
  useWindowTitle()
  useDeviceReboot()
  const [currentTheme, setCurrentTheme] = useState(defaultTheme?.type)
  const [theme, setTheme] = useState(defaultTheme)
  let currentHour = new Date().getHours()

  const changeAutoTheme = useCallback(() => {
    let th = LIGHT
    if (currentHour >= 7 && currentHour < 19) {
      th = LIGHT
    } else {
      th = DARK
    }
    setTheme(
      update(theme, {
        type: { $set: th }
      })
    )
  }, [currentHour, setTheme, theme])

  useEffect(() => {
    if (currentTheme === AUTO) {
      changeAutoTheme()
    } else {
      setTheme(
        update(theme, {
          type: { $set: currentTheme }
        })
      )
    }
    // eslint-disable-next-line
  }, [currentTheme])

  useEffect(() => {
    if (currentTheme === AUTO) {
      changeAutoTheme()
    }
    // eslint-disable-next-line
  }, [currentHour])

  useEffect(() => {
    document.body.style.background = theme.palette[theme.type].body.background
    getGoogleFonts()
  }, [getGoogleFonts, theme])
  const MatTheme = useMemo(() => createTheme(theme), [theme])

  const handleThemeChange = useCallback(
    (newTheme, onlyLocal = false) => {
      localStorage.setItem('theme', newTheme)

      !onlyLocal &&
        putUserDetailsAction(
          {
            themeMode: newTheme
          },
          { hideNotification: true }
        )

      setCurrentTheme(newTheme)
    },
    [putUserDetailsAction]
  )

  return (
    <StylesProvider generateClassName={generateClassName}>
      <DndProvider backend={HTML5Backend}>
        <I18nextProvider i18n={i18n}>
          <MuiThemeProvider theme={MatTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider
                classes={{
                  root: 'Snackbar',
                  variantWarning: 'snackbarWarning',
                  ...(isStorageHasToken() && {
                    containerRoot: 'snackbarContainer'
                  })
                }}
                anchorOrigin={{
                  horizontal: 'center',
                  vertical: 'top'
                }}
                maxSnack={5}
                disableWindowBlurListener
              >
                <GoogleOAuthProvider
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                >
                  <AssignCreatedEntityContextProvider>
                    <ContextMenuContextProvider>
                      <ThemeContextProvider
                        contextValue={{
                          currentTheme,
                          handleThemeChange
                        }}
                      >
                        <Fragment>
                          <CssBaseline />
                          <WhiteLabelProvider />
                          <SocketNotification />
                          <ServiceNotifications />
                          <ApiErrorHandler />
                          <Suspense fallback={<div />}>
                            <Switch>
                              <Route
                                path="/login/sso-login"
                                component={SsoLogin}
                              />
                              <Route
                                path={routeByName.contentApprove.root}
                                component={ContentApprove}
                              />
                              <Route
                                path={routeByName.activateDevice}
                                component={ActivateDevice}
                              />
                              <Route
                                path={redirectRoutes.instagram}
                                component={InstagramLoginProcessing}
                              />
                              <Route
                                path={redirectRoutes.itsaCheckmate}
                                component={ItsaCheckmateRedirect}
                              />
                              <UnauthorizedRoute
                                path={routeByName.timeoutError}
                                component={TimeoutErrorPage}
                              />
                              <UnauthorizedRoute
                                path={routeByName.serverError}
                                component={ServerErrorPage}
                              />
                              <UnauthorizedRoute
                                path={routeByName.expiredPasswordlessLoginError}
                                component={ExpiredPassworldessLoginErrorPage}
                              />
                              <UnauthorizedRoute
                                path={routeByName.maintenanceError}
                                component={MaintenanceErrorPage}
                              />
                              <UnauthorizedRoute
                                path="/sign-in"
                                component={SignIn}
                              />
                              <UnauthorizedRoute
                                path="/sign-in-manually"
                                component={ManuallySignIn}
                              />
                              {isProduction && (
                                <UnauthorizedRoute
                                  path="/sign-up"
                                  component={SignUp}
                                />
                              )}
                              <UnauthorizedRoute
                                path="/system/sign-in"
                                component={SystemSignIn}
                              />
                              <UnauthorizedRoute
                                path={['/email-verified', '/user/email-verify']}
                                component={EmailVerified}
                              />
                              <UnauthorizedRoute
                                path="/forgot-password"
                                component={ForgotPassword}
                              />
                              <UnauthorizedRoute
                                path={[
                                  '/password-reset/:token/:email',
                                  '/system/password-reset/:token/:email'
                                ]}
                                component={ForgotPassword}
                              />
                              <UnauthorizedRoute
                                path="/system/forgot-password"
                                component={ForgotPassword}
                              />
                              <UnauthorizedRoute
                                path="/password-expired"
                                component={ForgotPassword}
                              />
                              <UnauthorizedRoute
                                path="/password-reset"
                                component={ForgotPassword}
                              />
                              <UnauthorizedRoute
                                path="/login/microsoft"
                                component={MicrosoftLoginProcessing}
                              />
                              <UnauthorizedRoute
                                path="/login/linkedin"
                                component={LinkedInCallback}
                              />
                              <UnauthorizedRoute
                                path="/access-denied"
                                component={AccessDenied}
                              />
                              <UnauthorizedRoute
                                path={routeByName.loginPasswordless}
                                component={PasswordlessLogin}
                              />
                              <UnauthorizedRoute
                                path={routeByName.loginGoogleTfa}
                                component={() => (
                                  <PasswordlessLogin
                                    tfaType={tfaTypes.googleTfa}
                                  />
                                )}
                              />
                              <Route path="/login" render={() => null} />
                              <Route
                                path={routeByName.public.root}
                                render={() => (
                                  <PublicPages
                                    currentTheme={currentTheme}
                                    handleThemeChange={handleThemeChange}
                                  />
                                )}
                              />

                              <PrivateRoute
                                path="/"
                                component={AppWrapper}
                                dark={theme.type === 'dark'}
                                currentTheme={currentTheme}
                                setCurrentTheme={setCurrentTheme}
                                handleThemeChange={handleThemeChange}
                                setTheme={setTheme}
                              />
                            </Switch>
                          </Suspense>
                        </Fragment>
                      </ThemeContextProvider>
                    </ContextMenuContextProvider>
                  </AssignCreatedEntityContextProvider>
                </GoogleOAuthProvider>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </I18nextProvider>
      </DndProvider>
    </StylesProvider>
  )
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getGoogleFonts,
      putUserDetailsAction
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(App)
