import api from './api'

import { errorHandler, queryParamsHelper } from '../utils'
import { getApiParamsByOriginalUser } from 'utils/localStorage'
import { isVueV3Preview } from 'utils/apiUtils'
import { mediaQueryParamsHelper } from '../utils/queryParamsHelpers'

const getMediaLibraryItems = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/media',
      params: mediaQueryParamsHelper(params)
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getMediaGridViewEmbeddedItems = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/media/gridViewEmbedded',
      params: mediaQueryParamsHelper(params)
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getModalMediaItems = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/media',
      params: queryParamsHelper(params, ['group', 'tag'])
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getLatestMediaItem = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/media',
      params: {
        sort: 'createdAt',
        order: 'desc',
        limit: 1,
        ...params
      }
    })

    return response.data
  } catch (e) {}
}

const deleteMediaLibraryItem = async request => {
  try {
    const { mediaId, data } = request

    const response = await api({
      method: 'DELETE',
      url: `/media/${mediaId}`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getMediaGroupItems = async request => {
  try {
    const { groupId } = request

    const response = await api({
      method: 'GET',
      url: `/media/group/${groupId}`
    })

    return response.data
  } catch (error) {
    throw new Error(error)
  }
}

const setMediaGroupItems = async request => {
  try {
    const { groupId, mediaId } = request

    const response = await api({
      method: 'POST',
      url: `/media/group/${groupId}`,
      data: {
        mediaId
      }
    })

    return response.data
  } catch (error) {
    throw new Error(error)
  }
}

const deleteMediaGroupItems = async request => {
  try {
    const { groupId, mediaId } = request

    const response = await api({
      method: 'DELETE',
      url: `/media/group/${groupId}/${mediaId}`
    })

    return response.data
  } catch (error) {
    throw new Error(error)
  }
}

const getMediaLibraryPref = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: '/preference',
      params: {
        entity: 'MediaLibrary'
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putMediaLibraryPref = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: '/preference/MediaLibrary',
      data: {
        recordsPerPage: 10,
        gridColumn: data
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const addMedia = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/media`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getGroupItems = async (id, params) => {
  try {
    const response = await api({
      method: 'GET',
      url: `/media/group/${id}`,
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getMediaPreview = async (id, isZoomToFit = 0) => {
  try {
    const response = await api({
      method: 'GET',
      url: `/media/preview/${id}`,
      params: {
        ...(isZoomToFit === 1 && { isZoomToFit }),
        ...(isVueV3Preview() && { vue3: 1 })
      },
      responseType: 'text'
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postGroupItem = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/media/group/${data.groupId}`,
      data: {
        mediaId: data.mediaId
      }
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const generateMediaPreview = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/media/preview`,
      params: {
        ...(isVueV3Preview() && { vue3: 1 })
      },
      data,
      responseType: 'text'
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteGroupItem = async data => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/media/group/${data.groupId}/${data.mediaId}`
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getMediaItemById = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/media/${id}`
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putMediaItemById = async ({ id, data, method }) => {
  try {
    const response = await api({
      method: method ? method : 'PUT',
      url: `/media/${id}`,
      data
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const cloneMediaAndUpdate = async ({ id, data }) => {
  try {
    if (data.hasOwnProperty('_method')) {
      delete data._method
    }

    const response = await api({
      method: 'POST',
      url: `/media/cloneAndUpdate`,
      data: {
        ...data,
        mediaId: data.mediaId ? data.mediaId : id
      }
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getFeatureMediaItems = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: `/feature/media`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putOrganizeFeatureMediaItems = async ({ id, payload }) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `feature/${id}/organize`,
      data: payload
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const addMediaToPlaylists = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/media/playlist`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

async function getMediaCapAlert(params) {
  try {
    const response = await api({
      method: 'GET',
      url: '/media',
      params: {
        featureId: 42,
        ...params
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

async function getMediaIpawsAlert(params) {
  try {
    const response = await api({
      method: 'GET',
      url: '/media',
      params: {
        featureId: 90,
        ...params
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postFavoriteMedia = async data => {
  try {
    await api({
      method: 'POST',
      url: `/media/favorite`,
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteSelectedMedia = async ({ ids, data }) => {
  try {
    await api({
      method: 'DELETE',
      url: `/media/bulk`,
      params: {
        ids: ids.join(',')
      },
      data
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const shareMedia = async data => {
  const { id, ...rest } = data
  try {
    await api({
      method: 'POST',
      url: `/media/${id}/share`,
      data: rest
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const publishDesign = async data => {
  const { id, ...rest } = data
  try {
    await api({
      method: 'PUT',
      url: `/media/${id}/publishDesignGalleryMedia`,
      data: rest
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const copyMedia = async (data, impersonated) => {
  const { id, ...rest } = data
  try {
    await api({
      method: 'POST',
      url: `/media/${id}/copy`,
      data: rest,
      ...(impersonated && getApiParamsByOriginalUser(`/media/${id}/copy`))
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const copyDesignToSystem = async designGalleryId => {
  try {
    await api({
      method: 'POST',
      ...getApiParamsByOriginalUser(
        `/designGallery/design/${designGalleryId}/copy`
      )
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const addMediaToPlaylist = async data => {
  const { id, ...rest } = data
  try {
    await api({
      method: 'POST',
      url: `/media/${id}/playlist`,
      data: rest
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const postMediaBulk = async ({ mediaIds, data }) => {
  try {
    await api({
      method: 'POST',
      url: `/media/bulk?ids=${mediaIds}`,
      data
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const cloneMedia = async data => {
  try {
    await api({
      method: 'POST',
      url: '/media/clone',
      data
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const getMediaAssociations = async (id, params) => {
  try {
    return await api({
      method: 'GET',
      url: `/media/${id}/association`,
      params
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const getSftpFoldersList = async data => {
  try {
    const response = await api({
      method: 'GET',
      url: '/sftp/listContents',
      params: data
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

const mediaService = {
  getMediaLibraryPref,
  getMediaLibraryItems,
  getMediaGroupItems,
  putMediaLibraryPref,
  deleteMediaGroupItems,
  deleteMediaLibraryItem,
  setMediaGroupItems,
  addMedia,
  getMediaPreview,
  generateMediaPreview,
  getGroupItems,
  postGroupItem,
  deleteGroupItem,
  getMediaItemById,
  putMediaItemById,
  cloneMediaAndUpdate,
  getFeatureMediaItems,
  putOrganizeFeatureMediaItems,
  addMediaToPlaylists,
  getMediaCapAlert,
  getMediaIpawsAlert,
  postFavoriteMedia,
  deleteSelectedMedia,
  shareMedia,
  publishDesign,
  copyMedia,
  copyDesignToSystem,
  addMediaToPlaylist,
  postMediaBulk,
  cloneMedia,
  getModalMediaItems,
  getLatestMediaItem,
  getMediaAssociations,
  getSftpFoldersList,
  getMediaGridViewEmbeddedItems
}

export default mediaService
