import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { _isEmpty } from 'utils/lodash'

import MapplicAdmin from 'components/MapplicAdmin'
import { CircularLoader } from 'components/Loaders'
import { createNewMapJson } from 'utils/mapplicUtils'
import useDetermineMediaFeatureId from 'hooks/useDetermineMediaFeatureId'
import DefaultModal from 'components/Modal/DefaultModal'
import { FormControlInput } from 'components/Form'
import useMutationResultHandler from 'hooks/useMutationResultHandler'
import { BlueButton, WhiteButton } from 'components/Buttons'
import { getUrlPrefix } from 'utils'
import { routeByName } from 'constants/index'
import {
  useCloneMediaAndUpdateMutation,
  useGetMediaLibraryItemQuery,
  usePostMediaMutation,
  usePutMediaMutation
} from 'api/mediaApi'
import { featureNames } from 'constants/featureConstants'
import useUrlSearchParams from 'hooks/useUrlSearchParams'
import { CLONE_MEDIA_TITLE } from 'constants/localStorage'

const useStyles = makeStyles(theme => ({
  root: {
    height: '74vh'
  },
  modalHeader: {
    height: 65
  }
}))

const InteractiveWayfindEditor = ({
  match: {
    params: { id }
  }
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const featureId = useDetermineMediaFeatureId(featureNames.Wayfinding)
  const searchParams = useUrlSearchParams()
  const history = useHistory()
  const location = useLocation()
  const { data } = location.state || {}

  const {
    data: item = {},
    isFetching,
    refetch
  } = useGetMediaLibraryItemQuery(id, { skip: !id })

  const defaultMapJson = useMemo(
    () => (!!id ? item.attributes?.mapJson : createNewMapJson(data || {})),
    [id, item.attributes, data]
  )
  const [mapJson, setMapJson] = useState(defaultMapJson)

  const [open, setOpen] = useState(false)
  const [postMap, post] = usePostMediaMutation()
  const [putMap, put] = usePutMediaMutation()
  const [postClone, clone] = useCloneMediaAndUpdateMutation()
  const {
    isValid,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setErrors
  } = useFormik({
    initialValues: {
      title: searchParams?.isClone
        ? localStorage.getItem(CLONE_MEDIA_TITLE) || ''
        : item.title || ''
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t('This field is required'))
    }),
    onSubmit: values => {
      let requestData = {
        title: values.title,
        featureId: featureId,
        attributes: { mapJson }
      }

      if (data || !!item?.sharedBy) {
        postMap(requestData)
      } else {
        if (searchParams?.isClone) {
          postClone({ mediaId: item.id, ...requestData })
        } else {
          putMap({ id: item.id, ...requestData })
        }
      }
    }
  })

  useEffect(() => {
    const error = !_isEmpty(post.error)
      ? post.error
      : !_isEmpty(put.error)
      ? put.error
      : !_isEmpty(clone.error)
      ? clone.error
      : null
    if (!_isEmpty(error)) {
      const formErrors = {}
      error.errorFields.forEach(field => {
        formErrors[field.name] = field.value[0]
      })

      setErrors(formErrors)
    }
  }, [post, setErrors, put, clone])

  useEffect(() => {
    if (item.title && !values.title) {
      setFieldValue(
        'title',
        searchParams?.isClone
          ? localStorage.getItem(CLONE_MEDIA_TITLE)
          : item.title
      )
    }
    // eslint-disable-next-line
  }, [item.title])

  useEffect(() => {
    if (!data && !id) {
      history.push(getUrlPrefix(routeByName.interactiveWayfind.root))
    }
    // eslint-disable-next-line
  }, [])

  const onOpen = mapData => {
    setMapJson(mapData)
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const actionItems = (
    <>
      <WhiteButton
        onClick={onClose}
        variant="danger"
        iconClassName="fa-regular fa-circle-arrow-left"
      >
        {t('Cancel')}
      </WhiteButton>

      <BlueButton
        opaque={!isValid}
        disabled={false}
        onClick={handleSubmit}
        iconClassName="fa-regular fa-circle-check"
      >
        {data ? t('Save') : t('Update')}
      </BlueButton>
    </>
  )

  const handleSuccess = () => {
    setOpen(false)
    history.push(getUrlPrefix(routeByName.interactiveWayfind.root))
  }

  useMutationResultHandler({
    result: post,
    keyWord: 'WayFinding',
    actionText: 'added',
    onSuccess: handleSuccess
  })

  useMutationResultHandler({
    result: put,
    keyWord: 'WayFinding',
    actionText: 'updated',
    onSuccess: () => {
      handleSuccess()
      refetch()
    }
  })

  useMutationResultHandler({
    result: clone,
    keyWord: 'WayFinding',
    actionText: 'cloned',
    onSuccess: () => {
      handleSuccess()
      refetch()
    }
  })

  const renderMapplicAdmin = useMemo(
    () => <MapplicAdmin mapJson={defaultMapJson} onSave={onOpen} />,
    [defaultMapJson]
  )

  return (
    <>
      <div className={classes.root}>
        {isFetching && <CircularLoader />}
        {renderMapplicAdmin}
      </div>
      <DefaultModal
        actions={actionItems}
        open={open}
        modalTitle={t('WayFinding Map')}
        onCloseModal={onClose}
        headerClassName={classes.modalHeader}
      >
        <FormControlInput
          fullWidth
          label={t('Name / Title')}
          value={values.title}
          error={errors.title}
          touched={touched.title}
          name="title"
          handleChange={handleChange}
          onBlur={handleBlur}
        />
      </DefaultModal>
    </>
  )
}

export default InteractiveWayfindEditor
