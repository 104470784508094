import React from 'react'
import * as Yup from 'yup'
import { _isEmpty, _get } from 'utils/lodash'
import moment from 'moment'
import {
  isValidPhoneNumber,
  validatePhoneNumberLength
} from 'libphonenumber-js'
import draftToHtml from 'draftjs-to-html'
import { convertToRaw } from 'draft-js'

import { Trans } from 'react-i18next'
import {
  groupRequiredField,
  passwordFormat,
  phoneMaxLength,
  phoneMinLength,
  phoneValidFormat,
  requiredField
} from 'constants/validationMessages'
import { htmlParagraphRegExp, passwordRegExp } from './regExp'
import { hasHTTPS } from 'utils/urlUtils'
import store from 'store/configureStore'
import { USER_GROUP_STORE } from 'constants/permissionGroups'
import { ENTERPRISE_ROLE, ORG_ROLE } from './api'

const requiredImageValidateSchema = Yup.mixed()
  .required('A file is required')
  .test('fileFormat', 'jpeg, jpg, and png only', value => {
    if (value) {
      return value.type
        ? ['image/jpeg', 'image/jpg', 'image/png'].includes(value.type)
        : value.path
        ? ['.jpeg', '.jpg', '.png'].some(ext => value.path.endsWith(ext))
        : false
    }
  })
  .nullable()

const requiredFileValidateSchema = Yup.array()
  .min(1, 'Upload at least 1 file')
  .required('Upload at least 1 file')
  .nullable()

const imageValidateSchema = Yup.mixed().test(
  'fileFormat',
  'jpeg, jpg, png only',
  value => {
    return (
      _isEmpty(value) ||
      typeof value === 'string' ||
      ['image/jpeg', 'image/jpg', 'image/png'].includes(value.type)
    )
  }
)
// .nullable()

const requiredIconValidateSchema = Yup.mixed()
  .required('An icon is required')
  .test('iconTest', 'Please select an icon from the list.', value => value)
  .nullable()

const iconValidateSchema = Yup.mixed()
  .required('An icon is required')
  .test(
    'iconTest',
    'Please select an icon from the list.',
    value => value && value.valid
  )
  .nullable()

store.subscribe(() => {
  const state = store.getState()
  const permission = _get(
    state,
    `user.details.response.permissions.${USER_GROUP_STORE}.create`,
    false
  )
  const roleLevel = _get(state, `user.details.response.role.level`, false)
  Yup.addMethod(Yup.array, 'groupRequired', function groupRequired(message) {
    return !permission && [ENTERPRISE_ROLE, ORG_ROLE].includes(roleLevel)
      ? Yup.array().min(1, message)
      : Yup.array()
  })
})

export const activeDateValidationSchema = (expireName = 'expireDate') =>
  Yup.string()
    .test(
      'isBeforeExpire',
      'The activation date must be before the expiration date.',
      function (value) {
        const expireDate = this.parent[expireName]
        return !value || !expireDate || moment(value).isBefore(expireDate)
      }
    )
    .nullable()

export const expireDateValidationSchema = (activeName = 'activeDate') =>
  Yup.string()
    .test(
      'isAfterActive',
      'The expiration date must be after the activation date.',
      function (value) {
        const activeDate = this.parent[activeName]
        return !value || !activeDate || moment(value).isAfter(activeDate)
      }
    )
    .nullable()

const mediaValidityShape = {
  activeDate: activeDateValidationSchema('expireDate'),
  expireDate: expireDateValidationSchema('activeDate')
}

const mediaInfoCommonValidationSchema = {
  title: Yup.string().restrictedCharacters().required(requiredField),
  group: Yup.lazy(() => Yup.array().groupRequired(groupRequiredField)),
  ...mediaValidityShape
}

const mediaInfoValidateSchema = Yup.object().shape(
  mediaInfoCommonValidationSchema
)

const getCustomParametersValidateSchema = (additionalShapeSchemas = {}) =>
  Yup.array().of(
    Yup.object().shape({
      parameter_id: Yup.mixed().required(requiredField),
      ...additionalShapeSchemas
    })
  )

const mediaInfoNoRequiredTitleValidateSchema = Yup.object().shape({
  title: Yup.string().restrictedCharacters(),
  group: Yup.lazy(() => Yup.array().groupRequired(groupRequiredField)),
  ...mediaValidityShape
})

const designGalleryInfoValidateSchema = ({ updateMetadata, t = f => f }) =>
  Yup.object().shape({
    ...(updateMetadata
      ? {
          metadataTitle: Yup.string()
            .restrictedCharacters()
            .required(t(requiredField))
        }
      : {
          title: Yup.string().restrictedCharacters().required(t(requiredField))
        }),
    group: Yup.lazy(() => Yup.array().groupRequired(t(groupRequiredField))),
    ...mediaValidityShape
  })

const playlistInfoValidateSchema = (t = f => f) =>
  Yup.object().shape({
    title: Yup.string().restrictedCharacters().required(t(requiredField)),
    group: Yup.lazy(() => Yup.array().groupRequired(t(groupRequiredField))),
    activateOn: activeDateValidationSchema('expireOn'),
    expireOn: expireDateValidationSchema('activateOn')
  })

const smartPlaylistValidateSchema = {
  title: Yup.string().restrictedCharacters().required(requiredField),
  group: Yup.lazy(() => Yup.array().groupRequired(groupRequiredField)),
  activateOn: activeDateValidationSchema('expireOn'),
  expireOn: expireDateValidationSchema('activateOn')
}

const templateInfoValidateSchema = t =>
  Yup.object().shape({
    title: Yup.string().restrictedCharacters().required(t(requiredField)),
    group: Yup.lazy(() => Yup.array().groupRequired(t(groupRequiredField))),
    activateOn: activeDateValidationSchema('expireOn'),
    expireOn: expireDateValidationSchema('activateOn')
  })

const scheduleValidateSchema = t => ({
  title: Yup.string().restrictedCharacters().required(t(requiredField)),
  group: Yup.lazy(() => Yup.array().groupRequired(t(groupRequiredField)))
})

const passwordValidateSchema = Yup.string()
  .required(requiredField)
  .matches(passwordRegExp, passwordFormat)

const notRequiredPasswordValidateSchema = Yup.string().matches(
  passwordRegExp,
  passwordFormat
)

const passwordConfirmValidateSchema = Yup.string().when('password', {
  is: value => !passwordRegExp.test(value),
  then: Yup.string(),
  otherwise: Yup.string()
    .required(<Trans i18nKey={requiredField} />)
    .oneOf([Yup.ref('password')], <Trans i18nKey={`Passwords don't match`} />)
})

const httpsUrlValidateSchema = Yup.string()
  .required(requiredField)
  .test('httpUrl', 'Only https based links are allowed', value => {
    if (value) {
      return hasHTTPS(value)
    }
    return false
  })

const validationDate = (format, message = 'Date is invalid') => {
  return Yup.string().test('validationDate', message, value => {
    return moment(value, format).isValid()
  })
}

const phoneValidationSchema = Yup.string()
  .test('phoneMinTest', phoneMinLength, value =>
    value ? validatePhoneNumberLength(value) !== 'TOO_SHORT' : true
  )
  .test('phoneMaxTest', phoneMaxLength, value =>
    value ? validatePhoneNumberLength(value) !== 'TOO_LONG' : true
  )
  .test('phoneFormatTest', phoneValidFormat, value =>
    value ? isValidPhoneNumber(value) : true
  )

const requiredWysiwygStateSchema = Yup.object().test(
  'isStateEmpty',
  requiredField,
  value => {
    try {
      return value
        ? !!draftToHtml(convertToRaw(value.getCurrentContent()))
            .replace(htmlParagraphRegExp, '')
            .replaceAll('&nbsp;', '')
            .trim()
        : false
    } catch (e) {
      return true
    }
  }
)

const DEFAULT_MAX_STRING_LENGTH = 128

export {
  notRequiredPasswordValidateSchema,
  requiredImageValidateSchema,
  requiredFileValidateSchema,
  imageValidateSchema,
  requiredIconValidateSchema,
  iconValidateSchema,
  mediaInfoValidateSchema,
  mediaInfoNoRequiredTitleValidateSchema,
  passwordValidateSchema,
  passwordConfirmValidateSchema,
  httpsUrlValidateSchema,
  mediaValidityShape,
  mediaInfoCommonValidationSchema,
  validationDate,
  phoneValidationSchema,
  requiredWysiwygStateSchema,
  playlistInfoValidateSchema,
  smartPlaylistValidateSchema,
  templateInfoValidateSchema,
  scheduleValidateSchema,
  designGalleryInfoValidateSchema,
  DEFAULT_MAX_STRING_LENGTH,
  getCustomParametersValidateSchema
}
