import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { getToken, getUserType } from '../../utils'
import { getRedirectUrlForUnauthorized } from 'utils/permissionUrls'
import getUserLevel from 'utils/getUserLevel'
import { ORG_USER, RESELLER_ROLE } from 'constants/api'

const ORGRoute = ({ component: Component, allowed = true, ...rest }) => {
  const logged = !!getToken()

  const level = getUserLevel()

  const type = getUserType()
  const org = type === ORG_USER

  return (
    <Route
      {...rest}
      render={props =>
        !logged ? (
          <Redirect to="/sign-in" />
        ) : org && level !== RESELLER_ROLE && allowed ? (
          <Component {...props} />
        ) : level === RESELLER_ROLE ? (
          <Redirect to={`/${level}/clients-library`} />
        ) : (
          <Redirect to={getRedirectUrlForUnauthorized()} />
        )
      }
    />
  )
}

ORGRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.object
  ]),
  allowed: PropTypes.bool
}

export default ORGRoute
