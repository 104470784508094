import { takeLatest } from 'redux-saga/effects'

import * as types from 'actions'
import templateSaga from 'sagas/templateSaga'

const actions = [
  types.POST_TEMPLATE_BACKGROUND_SUCCESS,
  types.PUT_TEMPLATE_BACKGROUND_SUCCESS
]

const templateBackgroundInvalidateCache = () =>
  actions.map(action =>
    takeLatest(action, templateSaga.invalidateTemplateBackgroundTags)
  )

export default templateBackgroundInvalidateCache
