import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'

import Tooltip from 'components/Tooltip'
import { DateTimeView } from 'components/TableLibrary'

const useStyles = makeStyles(({ palette, fontSize, colors }) => ({
  ageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tooltipText: {
    color: '#fff !important'
  },
  icon: {
    width: 'auto',
    fontSize: fontSize.primary,
    margin: '5px'
  },
  'color-success': {
    color: colors.green
  },
  'color-danger': {
    color: palette.buttons.secondary.theme2.color
  },
  'color-needsAttentionStatus': {
    color: colors.needsAttentionStatus
  },
  ageContent: {
    width: 'max-content',
    textTransform: 'capitalize'
  },
  tooltipContent: {
    display: 'flex',
    width: 'max-content',
    alignItems: 'baseline'
  }
}))

const AgeCellContent = ({
  date,
  color,
  headerText,
  title,
  content,
  iconClass
}) => {
  const classes = useStyles()

  return (
    <Tooltip
      arrow
      withHeader
      headerText={headerText}
      maxWidth="500px"
      placement="top"
      title={
        <div className={classes.tooltipContent}>
          {title}

          <DateTimeView
            date={date}
            direction="row"
            textClass={classes.tooltipText}
            textSmallClass={classes.tooltipText}
          />
        </div>
      }
    >
      <div className={classes.ageContainer}>
        <i
          className={classNames(
            classes.icon,
            `fa-sharp fa-solid ${iconClass}`,
            classes[`color-${color}`]
          )}
        />
        <div className={classes.ageContent}>{content}</div>
      </div>
    </Tooltip>
  )
}

export default AgeCellContent
