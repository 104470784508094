import api from './api'
import { errorHandler, queryParamsHelper } from '../utils'
import { deviceQueryParamsHelper } from 'utils/queryParamsHelpers'

export const getDeviceNocGeneralItems = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/device/noc/general',
      params: deviceQueryParamsHelper(params)
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const getDeviceNocHealthItems = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/device/noc/health',
      params: deviceQueryParamsHelper(params)
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const getDeviceNocNetworkItems = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/device/noc/network',
      params: deviceQueryParamsHelper(params)
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const getDeviceAlarmItems = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/device/alarm',
      params: queryParamsHelper(params)
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const getDeviceAlarmById = async id => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/device/alarm/${id}`
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const postDeviceAlarm = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: '/device/alarm',
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const putDeviceAlarm = async (id, data) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/device/alarm/${id}`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const deleteDeviceAlarm = async id => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/device/alarm/${id}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const getDeviceAlarmColumnList = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: '/device/alarm/getColumnList'
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}
