import React, { useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Text } from 'components/Typography'
import Scrollbars from 'components/Scrollbars'
import PulseDot from '../PulseDot/PulseDot'
import MaterialPopup from 'components/Popup/MaterialPopup'
import Spacing from 'components/Containers/Spacing'
import useRegionalSettings from 'hooks/useRegionalSettings'
import { DATE_VIEW_FORMAT } from 'constants/dateTimeFormats'

const useStyles = makeStyles(({ palette, type, spacing }) => ({
  statusWrapper: {
    height: '30px',
    width: '12px',
    padding: '1px',
    borderRadius: '3.75px'
  },
  statusIndicator: {
    paddingBottom: '1px',
    height: '100%',
    width: '85%',
    borderRadius: '100px'
  },
  active: {
    backgroundColor: '#008000'
  },
  inactive: {
    backgroundColor: '#ff0000'
  },
  halfActive: {
    backgroundColor: '#FFBF00'
  },
  popupContent: {
    borderRadius: 0,
    minWidth: 275,
    background: palette[type].dialog.background,
    boxShadow: palette[type].dropdown.shadow2
  },
  popupArrow: {
    borderRightColor: palette[type].dialog.header.background
  },
  popupHeader: {
    background: palette[type].dialog.header.background
  },
  pulseDotWrapper: {
    width: 20,
    marginRight: spacing(2)
  },
  periodWrapper: {
    paddingLeft: spacing(2)
  }
}))

const statuses = {
  active: 'Active',
  inactive: 'Inactive',
  halfActive: 'HalfActive'
}

const UptimeLineCell = ({ data }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { from, onlineDuration, offlineDuration, offlinePeriods } = data

  const { convertedTimeFormat } = useRegionalSettings()

  const status = useMemo(() => {
    if (offlineDuration === 0) {
      return statuses.active
    } else if (onlineDuration === 0) {
      return statuses.inactive
    } else if (offlineDuration && onlineDuration) {
      return statuses.halfActive
    }
  }, [offlineDuration, onlineDuration])

  const popupTitle = useMemo(() => {
    const formatedDate = moment(from).format(DATE_VIEW_FORMAT)

    switch (status) {
      case statuses.halfActive:
        return `${formatedDate}: ${t('n Inactive Period', {
          count: offlinePeriods.length
        })}`
      case statuses.active:
        return `${formatedDate}: ${t('No Inactive Periods')}`
      case statuses.inactive:
        return `${formatedDate}: ${t('Device Not Active')}`
      default:
        return formatedDate
    }
  }, [status, from, offlinePeriods, t])

  const offlineDurationText = useMemo(() => {
    const hours = Math.floor(offlineDuration / (60 * 60))
    const minutes = Math.ceil((offlineDuration % (60 * 60)) / 60)
    return `${hours ? `${hours}${t('hour', { count: hours })}` : ''}${
      hours && minutes ? ', ' : ''
    }${minutes ? `${minutes}${t('min', { count: minutes })}` : ''}`
  }, [offlineDuration, t])

  const statusDescriptionText = useMemo(() => {
    switch (status) {
      case statuses.inactive:
        return 'Device was not active on this day'
      case statuses.active:
        return 'Device was active the entire day'
      default:
        return 'No state available'
    }
  }, [status])

  return (
    <MaterialPopup
      on="hover"
      contentClassName={classes.popupContent}
      arrowClassName={classes.popupArrow}
      trigger={
        <div className={classes.statusWrapper}>
          <div
            className={classNames(classes.statusIndicator, {
              [classes.active]: status === statuses.active,
              [classes.inactive]: status === statuses.inactive,
              [classes.halfActive]: status === statuses.halfActive
            })}
          />
        </div>
      }
    >
      <Spacing
        variant={0}
        paddingHor={2}
        paddingVert={2}
        rootClassName={classes.popupHeader}
      >
        <Text color="title.primary" weight="bold">
          {popupTitle}
        </Text>
      </Spacing>
      <Spacing paddingRight={2}>
        {status === statuses.halfActive && !!offlinePeriods.length && (
          <>
            <Scrollbars autoHeight autoHeightMax={275}>
              {offlinePeriods.map((period, index) => (
                <Spacing
                  variant={0}
                  paddingVert={1}
                  borderBottom={1}
                  key={index}
                  direction="row"
                  wrap="nowrap"
                  rootClassName={classes.periodWrapper}
                >
                  <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent="flex-start"
                    className={classes.pulseDotWrapper}
                  >
                    <PulseDot color="red" />
                  </Grid>
                  <Spacing item variant={0}>
                    <Text color="title.primary">{`${t('Inactive Period')} ${
                      index + 1
                    }`}</Text>
                    <Text color="title.primary">{`${t('Inactivity')}: ${moment(
                      period.from
                    ).format(convertedTimeFormat('h:mmA'))} ${t('to')} ${moment(
                      period.to
                    ).format(convertedTimeFormat('h:mmA'))}`}</Text>
                  </Spacing>
                </Spacing>
              ))}
            </Scrollbars>
            <Spacing
              variant={0}
              paddingVert={2}
              direction="row"
              justifyContent="center"
            >
              <Text color="title.primary" weight="bold">{`${t(
                'Total Offline'
              )}: `}</Text>
              &nbsp;
              <Text color="title.primary">{offlineDurationText}</Text>
            </Spacing>
          </>
        )}
        {status !== statuses.halfActive && (
          <Spacing variant={0} paddingVert={2} paddingLeft={2}>
            <Text color="title.primary">{t(statusDescriptionText)}</Text>
          </Spacing>
        )}
      </Spacing>
    </MaterialPopup>
  )
}

export default UptimeLineCell
