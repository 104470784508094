import React, { useState, useCallback, useMemo, memo } from 'react'
import PropTypes from 'prop-types'
import { compose } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import {
  withStyles,
  Dialog,
  IconButton,
  Grid,
  DialogTitle,
  DialogContent
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import { TabToggleButtonGroup, TabToggleButton } from 'components/Buttons'
import EmergencyAlert from './EmergencyAlert'
import CapAlert from './CapAlert'
import IPAWSAlert from './IPAWSAlert'
import Text from 'components/Typography/Text'
import Spacing from 'components/Containers/Spacing'
import RectLoader from 'components/Loaders/RectLoader'

import { isEmpty, isEqual, isNumber } from 'utils/generalUtils'
import { useGetDeviceQuery } from 'api/deviceApi'
import usePermissions from 'hooks/api/usePermissions'
import useFeaturesId from 'hooks/api/useFeaturesId'
import { permissionNames } from 'constants/index'
import { featureNames } from 'constants/featureConstants'

const styles = ({ palette, type }) => ({
  backdrop: {
    background: palette[type].dialog.overlay
  },
  dialogPaper: {
    width: 600,
    background: palette[type].dialog.background,
    boxShadow: palette[type].dialog.shadow
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: palette[type].dialog.header.background,
    borderBottom: `solid 1px ${palette[type].dialog.border}`,
    padding: '10px 20px 5px 20px',
    height: palette[type].dialog.header.height
  },
  content: {
    background: palette[type].dialog.background,
    padding: '10px 20px 0px',
    overflow: 'visible'
  },
  title: {
    color: palette[type].dialog.title
  },
  closeButton: {
    width: '46px',
    height: '46px',
    margin: '0px',
    padding: '0px',
    color: palette[type].dialog.closeButton
  },
  closeIcon: {
    color: palette[type].sideModal.header.titleColor
  },
  infoContainer: {
    marginBottom: 10
  },
  toggleButtonsContainer: {}
})

function DeviceMediaModal({
  t,
  id,
  open = false,
  handleClose = f => f,
  classes
}) {
  const [currentTab, setCurrentTab] = useState(0)
  const { getPermissionByName } = usePermissions()
  const [emergencyId, capId, ipawsId] = useFeaturesId(
    featureNames.AlertSystem,
    featureNames.CapAlert,
    featureNames.IPAWS
  )

  const { data: device, isFetching, isUninitialized } = useGetDeviceQuery(id, {
    skip: !id
  })

  const [isEmergencyEnabled, isCapEnabled, isIpawsEnabled] = useMemo(() => {
    const { feature } = device || {}
    return [
      feature?.some(({ id }) => id === emergencyId),
      feature?.some(({ id }) => id === capId),
      feature?.some(({ id }) => id === ipawsId)
    ]
  }, [device, emergencyId, capId, ipawsId])

  const deviceAlertPermission = useMemo(
    () => ({
      update: getPermissionByName(permissionNames.CLIENT_DEVICE_ALERT_MANAGE)
    }),
    [getPermissionByName]
  )

  const tabs = useMemo(() => {
    return [
      {
        title: t('Emergency Alert'),
        enabled: isEmergencyEnabled,
        component: EmergencyAlert
      },
      {
        title: t('CAP Alert'),
        enabled: isCapEnabled,
        component: CapAlert
      },
      {
        title: t('IPAWS Alert'),
        enabled: isIpawsEnabled,
        component: IPAWSAlert
      }
    ]
  }, [t, isCapEnabled, isEmergencyEnabled, isIpawsEnabled])

  const enabledTabs = useMemo(() => {
    return tabs
      .filter(({ enabled }) => enabled)
      .map((tab, index) => ({ id: index, ...tab }))
  }, [tabs])

  const handleTabChange = useCallback(
    (_, newTab) => {
      if (isNumber(newTab)) {
        setCurrentTab(newTab)
      }
    },
    [setCurrentTab]
  )

  const renderTabButtons = useMemo(() => {
    return enabledTabs.map(({ title, id }) => {
      return (
        <TabToggleButton value={id} key={id}>
          {title}
        </TabToggleButton>
      )
    })
  }, [enabledTabs])

  const renderTab = useMemo(() => {
    if (isEmpty(enabledTabs)) return null
    const { component: Component } = enabledTabs.find(({ id }) => {
      return isEqual(id, currentTab)
    })
    return (
      <Component
        id={id}
        handleClose={handleClose}
        disabled={!deviceAlertPermission.update}
      />
    )
  }, [currentTab, enabledTabs, id, handleClose, deviceAlertPermission])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.dialogPaper
      }}
      BackdropProps={{
        className: classes.backdrop
      }}
    >
      <DialogTitle className={classes.header} disableTypography>
        <Text
          variant="big"
          weight="bold"
          rootClassName={classes.title}
          component="h2"
        >
          {t('Device media associations')}
        </Text>

        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <Grid
          container
          justifyContent="center"
          className={classes.toggleButtonsContainer}
        >
          {(isFetching || isUninitialized) &&
            Array(9)
              .fill('')
              .map((val, index) => (
                <RectLoader key={index} height={45} padding={8} />
              ))}
          {!(isFetching || isUninitialized) &&
            !isCapEnabled &&
            !isEmergencyEnabled &&
            !isIpawsEnabled && (
              <Spacing alignItems="center" paddingVert={2}>
                <Text>{t('Alerts are disabled for this device.')}</Text>
              </Spacing>
            )}
          <TabToggleButtonGroup
            value={currentTab}
            exclusive
            onChange={handleTabChange}
          >
            {renderTabButtons}
          </TabToggleButtonGroup>
        </Grid>
      </DialogContent>
      {renderTab}
    </Dialog>
  )
}

DeviceMediaModal.propTypes = {
  id: PropTypes.number,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  isEmergencyEnabled: PropTypes.bool,
  isCapEnabled: PropTypes.bool
}

export default memo(
  compose(withTranslation('translations'), withStyles(styles))(DeviceMediaModal)
)
