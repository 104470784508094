import { _has, _isEmpty } from 'utils/lodash'

import {
  defaultMaxHeadDropdownColumnItems,
  headDropdownColumnItemHeight,
  headDropdownOffset
} from 'constants/tableConstants'
import update from 'immutability-helper'
import arrayMove from 'array-move'

const transformerSelectedItems = (initAccum = {}, ids) =>
  ids.reduce(
    (accum, id) => ({
      ...accum,
      [id]: true
    }),
    { ...initAccum }
  )

const unselectItems = (list, ids) => {
  const modifiedList = { ...list }
  ids.forEach(id => {
    delete modifiedList[id]
  })
  return modifiedList
}

const checkData = (data, fallback = 'No data') => {
  return !_isEmpty(data) || data ? data : fallback
}

const transformMeta = meta => ({
  ...(meta || {}),
  isLoading: false,
  perPage: Number.parseInt(meta?.perPage || 0)
})

const maxHeadDropdownColumnItems = Math.max(
  5,
  Math.min(
    defaultMaxHeadDropdownColumnItems,
    (window.innerHeight - headDropdownOffset) / headDropdownColumnItemHeight
  )
)

const getChangedSortParams = (prevSort, nextSort, prevOrder) => {
  return {
    sort: nextSort,
    order:
      nextSort !== prevSort ? prevOrder : prevOrder === 'asc' ? 'desc' : 'asc'
  }
}

const reorderColumns = (source, destination, columns, role, idFilter) => {
  try {
    let visibleColumns = columns.filter(column => column.display !== false)

    if (role) {
      visibleColumns = visibleColumns.filter(
        ({ forRoles }) => !forRoles || forRoles.some(r => role[r])
      )
    }

    if (idFilter) {
      visibleColumns = visibleColumns.filter(column => idFilter(column.id))
    }

    const [, sourceColumn] = source.droppableId.split('-')
    const [, destinationColumn, index] = destination.droppableId.split('-')

    const from = source.index
    const target = parseInt(index) + destination.index
    const to =
      sourceColumn !== destinationColumn && from < target ? target - 1 : target

    const sourceIndex = columns.findIndex(
      ({ id }) => visibleColumns[from].id === id
    )
    const destinationIndex = columns.findIndex(
      ({ id }) => visibleColumns[to].id === id
    )

    return update(columns, {
      $set: arrayMove(columns, sourceIndex, destinationIndex)
    })
  } catch (e) {
    return columns
  }
}

const getNotSelectedColumns = api => {
  let notSelected = [...api.getAllGridColumns()]

  notSelected = notSelected.reduce((a, b) => {
    if (!b.visible) {
      if (!!b?.originalParent?.groupId) {
        const groupIndex = a.findIndex(
          ({ colId }) => colId === b?.originalParent?.groupId
        )
        if (groupIndex > -1 && _has(a[groupIndex], 'children')) {
          a[groupIndex].children.push({
            colId: b.colId,
            label: api.getDisplayNameForColumn(b, 'columnToolPanel'),
            disabled: b.colDef?.lockVisible || false,
            groupId: b?.originalParent?.groupId
          })
        } else {
          a.push({
            colId: b.originalParent.groupId,
            label: api.getDisplayNameForColumnGroup(
              api.getColumnGroup(b.originalParent.groupId),
              'columnToolPanel'
            ),
            isGroup: true,
            children: [
              {
                colId: b.colId,
                label: api.getDisplayNameForColumn(b, 'columnToolPanel'),
                disabled: b.colDef?.lockVisible || false,
                groupId: b?.originalParent?.groupId
              }
            ]
          })
        }
      } else {
        a.push({
          colId: b.colId,
          label: api.getDisplayNameForColumn(b, 'columnToolPanel'),
          disabled: b.colDef?.lockVisible || false
        })
      }
    }
    return a
  }, [])

  notSelected = notSelected
    .sort((a, b) => (a.label > b.label ? 1 : -1))
    .map(col =>
      col.isGroup
        ? {
            ...col,
            children: (col.children || []).sort((a, b) =>
              a.label > b.label ? 1 : -1
            )
          }
        : col
    )

  return notSelected
}

const getSelectedColumns = api => {
  let selectedCols = [...api.getAllDisplayedColumns()]

  return selectedCols
    .filter(({ colDef }) => !colDef?.suppressColumnsToolPanel)
    .map(column => ({
      colId: column.colId,
      label: api.getDisplayNameForColumn(column, 'columnToolPanel'),
      disabled: column.colDef?.lockVisible || false
    }))
}

const searchColumnsByLabel = (columns, searchText) => {
  return columns
    .filter(({ label, children }) => {
      const isDisplayed = (label || '')
        .trim()
        .toLowerCase()
        .includes((searchText || '').trim().toLowerCase())

      if (!!children?.length && !isDisplayed) {
        return children.find(({ label: _label }) =>
          (_label || '')
            .trim()
            .toLowerCase()
            .includes((searchText || '').trim().toLowerCase())
        )
      }

      return isDisplayed
    })
    .map(({ children, ...rest }) => ({
      ...rest,
      ...(!!children?.length
        ? {
            children: children.filter(({ label }) =>
              (label || '')
                .trim()
                .toLowerCase()
                .includes((searchText || '').trim().toLowerCase())
            )
          }
        : {})
    }))
}

export {
  unselectItems,
  transformerSelectedItems,
  checkData,
  transformMeta,
  maxHeadDropdownColumnItems,
  getChangedSortParams,
  reorderColumns,
  searchColumnsByLabel,
  getNotSelectedColumns,
  getSelectedColumns
}
