import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { _isEmpty, _differenceWith } from 'utils/lodash'

import {
  clearPreferenceByEntity,
  getPreferenceByEntity,
  getPreferencePublicNoc,
  putPreferenceByEntity,
  putPreferencePublicNoc
} from 'actions/preferenceActions'
import useUserRole from 'hooks/tableLibrary/useUserRole'
import { preferenceStatus } from 'constants/libraryConstants'
import { permissionNames } from 'constants/index'
import usePermissions from 'hooks/api/usePermissions'

const defaultSortOptions = {
  sort: 'id',
  order: 'asc'
}

export default function usePreferenceNew({
  entity,
  initialPerPage = 10,
  initialSortOptions = defaultSortOptions,
  initialColumns,
  serverSide = true,
  publicToken = ''
}) {
  const dispatch = useDispatch()

  const preferenceSelector = useCallback(
    ({ preference: { [entity]: preference } }) => {
      if (!entity) return {}

      return preference
    },
    [entity]
  )

  const { getPermissionByName } = usePermissions()

  const preferencePermissions = useMemo(
    () => ({
      read: getPermissionByName(
        permissionNames.CLIENT_PREFERENCE_SHOW,
        permissionNames.ENTERPRISE_PREFERENCE_SHOW
      ),
      create: getPermissionByName(
        permissionNames.CLIENT_PREFERENCE_STORE,
        permissionNames.ENTERPRISE_PREFERENCE_STORE
      )
    }),
    [getPermissionByName]
  )

  const { response, status } = useSelector(preferenceSelector)

  const [isFetchAllowed, setFetchAllowed] = useState(false)
  const [preferenceParams, setPreferenceParams] = useState({})
  const [columns, setColumns] = useState([])
  const role = useUserRole()

  const filterColumnsByRole = useCallback(
    columns => {
      return columns
        .filter(({ forRoles }) => !forRoles || forRoles.some(r => role[r]))
        .filter(
          ({ forLevels }) =>
            !forLevels || forLevels.some(level => role.level === level)
        )
    },
    [role]
  )

  const filteredInitialColumns = useMemo(
    () => filterColumnsByRole(initialColumns),
    [filterColumnsByRole, initialColumns]
  )

  const handlePreferenceUpdate = useCallback(
    params => {
      const data = {
        columns,
        ...preferenceParams,
        ...params
      }

      if (
        serverSide &&
        entity &&
        (role.system || preferencePermissions.create)
      ) {
        dispatch(
          putPreferenceByEntity(entity, {
            recordsPerPage: data.limit,
            gridColumn: data.columns,
            defaultSortColumn: data.sort,
            defaultSortOrder: data.order
          })
        )
      } else if (publicToken) {
        const filters = data?.filters
          ? Object.fromEntries(
              Object.entries(data.filters).filter(([_, v]) => !_isEmpty(v))
            )
          : {}
        dispatch(
          putPreferencePublicNoc({
            token: publicToken,
            recordsPerPage: data.limit,
            gridColumn: data.columns,
            defaultSortColumn: data.sort,
            defaultSortOrder: data.order,
            autoScroll: data.autoScroll,
            filters
          })
        )
      }
    },
    [
      columns,
      preferenceParams,
      entity,
      dispatch,
      serverSide,
      role,
      publicToken,
      preferencePermissions
    ]
  )

  const handleFilterChange = useCallback(
    filters => {
      handlePreferenceUpdate({ filters })
      setPreferenceParams(prevState => ({
        ...prevState,
        filters
      }))
    },
    [handlePreferenceUpdate]
  )

  const handleAutoScrollChange = useCallback(
    autoScroll => {
      handlePreferenceUpdate({ autoScroll })
      setPreferenceParams(prevState => ({
        ...prevState,
        autoScroll
      }))
    },
    [handlePreferenceUpdate]
  )

  const handlePerPageChange = useCallback(
    limit => {
      handlePreferenceUpdate({ limit })
      setPreferenceParams(prevState => ({
        ...prevState,
        limit
      }))
    },
    [handlePreferenceUpdate]
  )

  const handleSortChange = useCallback(
    (sort, order) => {
      handlePreferenceUpdate({ sort, order })
      setPreferenceParams(prevState => ({
        ...prevState,
        sort,
        order
      }))
    },
    [handlePreferenceUpdate]
  )

  const filterNotAllowedColumns = useCallback(
    columns => {
      return columns.filter(({ id }) =>
        filteredInitialColumns.some(initialColumn => initialColumn.id === id)
      )
    },
    [filteredInitialColumns]
  )

  const toggleDisplayColumn = useCallback(
    (id, display) => {
      const modifiedColumns = columns.map(column =>
        column.id === id
          ? {
              ...column,
              display: display == null ? column.display === false : display
            }
          : column
      )
      handlePreferenceUpdate({ columns: modifiedColumns })
      setColumns(modifiedColumns)
    },
    [handlePreferenceUpdate, columns]
  )

  const handleResetColumns = useCallback(() => {
    handlePreferenceUpdate({ columns: filteredInitialColumns })
    setColumns(filteredInitialColumns)
  }, [handlePreferenceUpdate, filteredInitialColumns])

  const handleColumnsChange = useCallback(
    columns => {
      setColumns(columns)
      handlePreferenceUpdate({ columns })
    },
    [handlePreferenceUpdate]
  )

  const handleColumnMove = useCallback(
    (columnId, toIndex, delay = 0) => {
      const columnToMove = columns.find(({ id }) => id === columnId)

      if (columnToMove) {
        const result = columns.filter(({ id }) => id !== columnId)
        result.splice(toIndex, 0, columnToMove)

        //to prevent animation lag
        setTimeout(() => {
          setColumns(result)
          handlePreferenceUpdate({
            columns: result
          })
        }, delay)
      }
    },
    [handlePreferenceUpdate, columns]
  )

  useEffect(
    () => {
      if (role.id || publicToken) {
        if (
          serverSide &&
          entity &&
          (role.system || preferencePermissions.read)
        ) {
          dispatch(getPreferenceByEntity(entity))
        } else if (publicToken) {
          dispatch(getPreferencePublicNoc(publicToken))
        } else {
          setPreferenceParams({
            limit: initialPerPage,
            sort: initialSortOptions.sort,
            order: initialSortOptions.order
          })

          setColumns(filteredInitialColumns)
          setFetchAllowed(true)
        }
      }
      return () => {
        dispatch(clearPreferenceByEntity(entity))
      }
    },
    // eslint-disable-next-line
    [role.id, publicToken]
  )

  useEffect(
    () => {
      if (!_isEmpty(response)) {
        const {
          defaultSortColumn,
          defaultSortOrder,
          recordsPerPage,
          gridColumn,
          filters,
          autoScroll
        } = response

        const filteredGridColumn = gridColumn
          .map(({ align, ...keepAttrs }) => keepAttrs)
          .map(column => {
            const initialLabel = initialColumns.filter(
              ({ id, forRoles }) =>
                id === column.id && (!forRoles || forRoles.some(r => role[r]))
            )[0]?.label

            if (initialLabel && column.label !== initialLabel) {
              return { ...column, label: initialLabel }
            }

            return column
          })

        setPreferenceParams({
          limit: recordsPerPage || initialPerPage,
          sort: defaultSortColumn || initialSortOptions.sort,
          order: defaultSortOrder || initialSortOptions.order,
          ...(!!publicToken && {
            filters: !_isEmpty(filters) ? filters : {},
            autoScroll: autoScroll || false
          })
        })

        const diffColumns = _differenceWith(
          filteredInitialColumns,
          filterColumnsByRole(gridColumn),
          (a, b = {}) => a.id === b.id
        )

        const filteredColumns = filterNotAllowedColumns([
          ...filteredGridColumn,
          ...diffColumns
        ])

        const columns = filteredGridColumn
          ? filteredColumns.map(column => {
              const filteredInitialColumn = filteredInitialColumns.find(
                initialColumn => initialColumn.id === column.id
              )
              return {
                ...filteredInitialColumn,
                ...column,
                cellDataType:
                  filteredInitialColumn.cellDataType || column.cellDataType,
                align: filteredInitialColumn.align
              }
            })
          : filteredInitialColumns

        setColumns(columns)
        setFetchAllowed(true)
      }
    },
    // eslint-disable-next-line
    [response]
  )

  useEffect(
    () => {
      if ([preferenceStatus.EMPTY, preferenceStatus.ERROR].includes(status)) {
        setPreferenceParams({
          limit: initialPerPage,
          sort: initialSortOptions.sort,
          order: initialSortOptions.order
        })
        setColumns(filteredInitialColumns)
        setFetchAllowed(true)
      }
    },
    // eslint-disable-next-line
    [status]
  )

  return useMemo(
    () => ({
      columns,
      preferenceParams,
      isFetchAllowed,
      handlePerPageChange,
      handleSortChange,
      handleResetColumns,
      handleColumnsChange,
      toggleDisplayColumn,
      handleColumnMove,
      preferenceActions: {
        changeColumns: handleColumnsChange,
        toggleDisplayColumn,
        changeRecordsPerPage: handlePerPageChange,
        changeSorting: handleSortChange,
        changeFilter: handleFilterChange,
        changeAutoScroll: handleAutoScrollChange,
        resetColumns: handleResetColumns
      }
    }),
    [
      columns,
      preferenceParams,
      isFetchAllowed,
      handlePerPageChange,
      handleSortChange,
      handleResetColumns,
      handleColumnsChange,
      toggleDisplayColumn,
      handleFilterChange,
      handleAutoScrollChange,
      handleColumnMove
    ]
  )
}
