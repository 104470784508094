import { useMemo } from 'react'
import moment from 'moment'

import AgeCellContent from 'components/TableLibrary/AgeCellContent'

const DeviceAgeViewCell = ({ date, t }) => {
  const startDate = moment(date, 'YYYY-MM-DD')
  const endDate = moment()
  const daysDiff = endDate.diff(startDate, 'days')
  const monthsDiff = endDate.diff(startDate, 'months')
  const yearsDiff = endDate.diff(startDate, 'years')

  const color = useMemo(() => {
    if (yearsDiff < 3) return 'success'
    else if (yearsDiff >= 3 && yearsDiff < 5) return 'needsAttentionStatus'
    else return 'danger'
  }, [yearsDiff])

  const deviceAge = useMemo(() => {
    if (daysDiff < 90) {
      return `${daysDiff} Days`
    } else if (monthsDiff < 12) {
      return `${monthsDiff} Months`
    } else if (monthsDiff % 12 === 0) {
      return `${yearsDiff} Yrs`
    } else {
      return `${yearsDiff} Yrs, ${monthsDiff % 12} Months`
    }
  }, [daysDiff, monthsDiff, yearsDiff])

  return (
    <AgeCellContent
      date={date}
      color={color}
      headerText={t('Device Registration')}
      title={t('Added on')}
      content={deviceAge}
      iconClass="fa-heart-circle-bolt"
    />
  )
}
export default DeviceAgeViewCell
