import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'

import { Card } from 'components/Card'
import ContentSectionHeader from './ContentSectionHeader'

const styles = ({ palette, type, typography }) => ({
  header: {
    paddingLeft: 0,
    marginBottom: '20px',
    borderRadius: '4px',
    border: `solid 1px ${palette[type].sideModal.content.border}`,
    backgroundColor: palette[type].pages.media.general.card.header.background
  },
  headerText: {
    ...typography.header[type],
    lineHeight: 1,
    padding: '20px'
  },
  headerTextWithError: {
    paddingRight: 10
  },
  root: {
    padding: '0 20px 0 0',
    height: '100%'
  },
  fullWidth: {
    width: '100%',
    padding: 0
  },
  contentTitleWrap: {
    display: 'contents',
    justifyContent: 'space-between'
  },
  contentRoot: {
    margin: '0 0 15px',
    paddingBottom: 0,
    border: `5px solid ${palette[type].sideModal.content.border}`,
    background: palette[type].card.greyHeader.background,
    borderRadius: '4px'
  },
  contentHeader: {
    margin: 0,
    padding: '5px 12px 5px 15px',
    borderBottom: `solid 1px ${palette[type].sideModal.content.border}`,
    backgroundColor: palette[type].sideModal.groups.header.background
  },
  hideHeader: {
    display: 'none'
  },
  contentHeaderText: {
    ...typography.header[type],
    padding: 20,
    lineHeight: 1
  },
  contentWrap: {
    maxHeight: 'calc(100% - 16px)',
    height: 'calc(100% - 16px)',
    display: 'flex',
    flexDirection: 'column'
  },
  contentWrapWithHeaderCard: {
    height: 'calc(100% - 78px)'
  }
})

const BaseSection = ({
  filterComponent,
  titleSearchComponent,
  title,
  assignType,
  disableTypeSelection,
  fullWidth,
  withHeaderCard = true,
  onChange,
  children,
  classes,
  rootClassName = '',
  error,
  hasSelectedItems,
  scheduleType
}) => {
  const renderTitleComponent = useMemo(
    () => (
      <ContentSectionHeader
        filterComponent={filterComponent}
        titleSearchComponent={titleSearchComponent}
        assignType={assignType}
        disableTypeSelection={disableTypeSelection}
        onChange={onChange}
        hasSelectedItems={hasSelectedItems}
        scheduleType={scheduleType}
      />
    ),
    [
      filterComponent,
      titleSearchComponent,
      assignType,
      onChange,
      disableTypeSelection,
      hasSelectedItems,
      scheduleType
    ]
  )

  const headerClasses = useMemo(
    () => [classes.header, ...(!withHeaderCard ? [classes.hideHeader] : [])],
    [classes, withHeaderCard]
  )

  const headerTextClasses = useMemo(
    () => [classes.headerText, error ? [classes.headerTextWithError] : []],
    [classes, error]
  )

  const contentClasses = useMemo(
    () => ({
      root: classNames(classes.contentWrap, {
        [classes.contentWrapWithHeaderCard]: withHeaderCard
      })
    }),
    [classes, withHeaderCard]
  )

  const contentHeaderClasses = useMemo(() => [classes.contentHeader], [classes])

  const contentHeaderTextClasses = useMemo(() => [classes.contentHeaderText], [
    classes
  ])

  return (
    <Card
      icon={false}
      grayHeader
      shadow={false}
      radius={false}
      removeSidePaddings
      headerSidePaddings
      rootClassName={classNames(classes.root, rootClassName, {
        [classes.fullWidth]: fullWidth
      })}
      removeNegativeHeaderSideMargins
      title={title}
      headerClasses={headerClasses}
      headerTextClasses={headerTextClasses}
      error={error}
    >
      <Card
        icon={false}
        grayHeader
        shadow={false}
        radius={false}
        removeSidePaddings
        headerSidePaddings
        removeNegativeHeaderSideMargins
        titleComponentWrapClassName={classes.contentTitleWrap}
        rootClassName={classes.contentRoot}
        headerClasses={contentHeaderClasses}
        headerTextClasses={contentHeaderTextClasses}
        classes={contentClasses}
        titleComponent={renderTitleComponent}
      >
        {children}
      </Card>
    </Card>
  )
}

BaseSection.propTypes = {
  title: PropTypes.string.isRequired,
  filterComponent: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.object
  ]),
  titleSearchComponent: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.object
  ]).isRequired,
  assignType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  withHeaderCard: PropTypes.bool,
  disableTypeSelection: PropTypes.bool,
  hasSelectedItems: PropTypes.func
}

export default withStyles(styles)(memo(BaseSection))
