import { takeLatest } from 'redux-saga/effects'

import * as types from 'actions'
import playlistSaga from 'sagas/playlistSaga'

const actions = [types.CLONE_PLAYLIST_SUCCESS]

const playlistInvalidateCache = () =>
  actions.map(action => takeLatest(action, playlistSaga.invalidatePlaylistTags))

export default playlistInvalidateCache
