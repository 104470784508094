import { HTML_CONTENT } from 'constants/library'
import { MAX_NO_OF_VIDEOWALL } from './featureSettingConstants'
import { AS_IT_HAPPENS_FREQUENCY_VALUE } from './commonOptions'
import { MAX_BRIGHT_SIGN_DISPLAYS } from './deviceConstants'

const featureConstants = {
  [HTML_CONTENT]: 'HtmlCode',
  RSSFeed: 'RSSFeed',
  Feeds: 'Feeds',
  DemoFeeds: 'DemoFeeds',
  MediaRSS: 'MediaRSS',
  YouTube: 'YouTube',
  Radio: 'Radio',
  CustomWidget: 'CustomWidget',
  News: 'News',
  Twitch: 'Twitch',
  ZoneRestriction: 'ZoneRestriction',
  EmailContent: 'EmailContent',
  Videowall: 'Videowall',
  VideoTutorials: 'VideoTutorials'
}

export const libraryContent = {
  Feeds: 'Feeds',
  RSSFeed: 'RSSFeed',
  News: 'News',
  Twitch: 'Twitch',
  CustomWidget: 'CustomWidget',
  Radio: 'Radio',
  MediaRSS: 'MediaRSS',
  YouTube: 'YouTube',
  VideoTutorials: 'VideoTutorials'
}
export const MIN_RESTRICTED_ZONES = 1
export const MAX_RESTRICTED_ZONES = 99
export const DEFAULT_VIDEO_WALL = MAX_NO_OF_VIDEOWALL
export const DEFAULT_VIDEO_WALL_MATRIX = '4x3'

//DO NOT USE IDs, use feature names instead ( id may be changed in the future )
export const IMAGE_FEATURE_ID = 2
export const YOUTUBE_FEATURE_ID = 6
export const VIMEO_FEATURE_ID = 15
export const VIDEO_WALL_FEATURE_ID = 31
export const CONTENT_APPROVAL_FEATURE_ID = 45
export const RADIO_FEATURE_ID = 61
export const ZONE_RESTRICTION_FEATURE_ID = 62
export const MS_OFFICE_FEATURE_ID = 63
export const DESIGN_GALLERY_FEATURE_ID = 76
export const MENU_MAKER_FEATURE_ID = 101
export const EMAIL_CONTENT_FEATURE_ID = 82
//DO NOT USE IDs, use feature names instead ( id may be changed in the future )

export const featureNames = {
  Video: 'Video',
  Canva: 'Canva',
  Image: 'Image',
  IPAWS: 'IPAWS',
  CapAlert: 'CapAlert',
  AlertSystem: 'AlertSystem',
  EmailContent: 'EmailContent',
  ContentApproval: 'ContentApproval',
  Events: 'Events',
  File: 'File',
  DeviceOfflineNotification: 'DeviceOfflineNotification',
  Flash: 'Flash',
  QueueManagement: 'QueueManagement',
  Audio: 'Audio',
  PDF: 'PDF',
  Videowall: 'Videowall',
  Analytics: 'Analytics',
  AudioAnnouncements: 'AudioAnnouncements',
  BoxOffice: 'BoxOffice',
  Button: 'Button',
  Clock: 'Clock',
  CurrencyConverter: 'CurrencyConverter',
  CustomKiosk: 'CustomKiosk',
  CustomWidget: 'CustomWidget',
  Date: 'Date',
  DesignGallery: 'DesignGallery',
  DigitalBuildingDirectory: 'DigitalBuildingDirectory',
  DirectionalSignage: 'DirectionalSignage',
  Dockets: 'Dockets',
  DonorWalls: 'DonorWalls',
  Facebook: 'Facebook',
  Feeds: 'Feeds',
  Flickr: 'Flickr',
  Flightstats: 'Flightstats',
  GoogleDocs: 'GoogleDocs',
  HtmlCode: 'HtmlCode',
  Instagram: 'Instagram',
  InteractiveContent: 'InteractivePlaylist',
  Klipfolio: 'Klipfolio',
  LeaderBoard: 'LeaderBoard',
  LiveTransit: 'LiveTransit',
  MSOffice: 'MSOffice',
  MediaRSS: 'MediaRSS',
  MicrosoftPowerBi: 'MicrosoftPowerBi',
  News: 'News',
  Pinterest: 'Pinterest',
  PointOfInterest: 'PointOfInterest',
  Profiles: 'Profiles',
  ProgressMeter: 'ProgressMeter',
  QRCode: 'QRCode',
  Quote: 'Quote',
  Reviews: 'Reviews',
  RSSFeed: 'RSSFeed',
  Radio: 'Radio',
  SftpFolder: 'SftpFolder',
  ShorelineSightseeing: 'ShorelineSightseeing',
  Slack: 'Slack',
  Smugmug: 'Smugmug',
  Socialwall: 'Socialwall',
  SpaceBooking: 'SpaceBooking',
  StockPhotos: 'StockPhotos',
  StockVideo: 'StockVideo',
  StockQuote: 'StockQuote',
  SunCity: 'SunCity',
  Tableau: 'Tableau',
  Tables: 'Tables',
  Text: 'Text',
  Timer: 'Timer',
  Traffic: 'Traffic',
  Trivia: 'Trivia',
  Twitch: 'Twitch',
  Twitter: 'Twitter',
  VideoInput: 'VideoInput',
  Vimeo: 'Vimeo',
  Weather: 'Weather',
  WeatherRadar: 'WeatherRadar',
  WebUrl: 'WebUrl',
  WorkplacePosters: 'WorkplacePosters',
  YouTube: 'YouTube',
  UltraHD: '4K',
  FontLibrary: 'FontLibrary',
  SmartPlaylist: 'SmartPlaylist',
  ZoneRestriction: 'ZoneRestriction',
  CalendarAggregator: 'CalendarAggregator',
  DeviceNoc: 'DeviceNoc',
  MenuMaker: 'MenuMaker',
  ApplicationHealth: 'Application Health',
  ModeratingContent: 'ModeratingContent',
  TemplateGallery: 'TemplateGallery',
  DeviceNetworkSettings: 'DeviceNetworkSettings',
  VideoTutorials: 'VideoTutorials',
  BrandGuide: 'BrandGuide',
  ProofOfPlay: 'ProofOfPlay',
  AIVideoCreator: 'AIVideoCreator',
  MsTeams: 'MsTeams',
  Survey: 'Survey',
  Prezi: 'Prezi',
  MagicFolder: 'MagicFolder',
  Wayfinding: 'Wayfinding',
  SmartSchedule: 'SmartSchedule',
  AirQuality: 'AirQuality'
}

export const oauth2Features = {
  Office365: 'Office365',
  MSOffice: 'MSOffice',
  MsTeams: 'MsTeams',
  [featureNames.GoogleDocs]: featureNames.GoogleDocs
}

export const GALLERY_MEDIA_CATEGORY_ID = 4

export const EULA_APP_FEATURE = 'EULA_APP_FEATURE'
export const MENU_MAKER_APP_FEATURE = 'MENU_MAKER_APP_FEATURE'
export const EMAIL_NOTIFICATION_APP_FEATURE = 'EMAIL_NOTIFICATION_APP_FEATURE'
export const SCHEDULE_STATUS_APP_FEATURE = 'SCHEDULE_STATUS_APP_FEATURE'
export const DEVICE_NOC_FEATURE = 'DEVICE_NOC_FEATURE'
export const DEVICE_NOC_ALERT_FEATURE = 'DEVICE_NOC_ALERT_FEATURE'
export const SCHEDULE_MULTIPLE_ASSIGN_FEATURE =
  'SCHEDULE_MULTIPLE_ASSIGN_FEATURE'
export const APPLICATION_HEALTH_FEATURE = 'APPLICATION_HEALTH_FEATURE'
export const FAVORITE_CATEGORY_NAME = 'Favorite'
export const DEVICE_RESOLUTION_FEATURE = 'DEVICE_RESOLUTION_FEATURE'
export const DEVICE_CEC_FEATURE = 'DEVICE_CEC_FEATURE'
export const BRANDING_FEATURE = 'BRANDING_FEATURE'
export const AUTO_REPORTS_FEATURE = 'AUTO_REPORTS_FEATURE'
export const SPACES_BOOKING_FEATURE = 'SPACES_BOOKING_FEATURE'
export const OFFICE_365_FEATURE = 'OFFICE_365_FEATURE'
export const SAVING_FILTERS = 'SAVING_FILTERS'

export const aliasTooltip = {
  [MS_OFFICE_FEATURE_ID]:
    'This widget will convert MS Office files into a format compatible with Digital Signage'
}

export const defaultZoneRestrictionSettings = {
  id: ZONE_RESTRICTION_FEATURE_ID,
  settings: {
    number_of_zones: MAX_RESTRICTED_ZONES
  }
}

export const defaultVideowallSettings = {
  id: VIDEO_WALL_FEATURE_ID,
  settings: {
    number_of_displays: DEFAULT_VIDEO_WALL,
    video_wall_matrix: DEFAULT_VIDEO_WALL_MATRIX
  }
}

export const defaultFeatureSettingsByFeatureName = {
  [featureNames.ZoneRestriction]: defaultZoneRestrictionSettings,
  [featureNames.Videowall]: defaultVideowallSettings,
  [featureNames.ContentApproval]: {
    settings: { emails: [], frequency: AS_IT_HAPPENS_FREQUENCY_VALUE }
  },
  [featureNames.EmailContent]: { settings: { emails: [] } }
}

export const galleryMediaCategory = {
  icon: 'fa-sharp fa-regular fa-images',
  id: GALLERY_MEDIA_CATEGORY_ID,
  name: 'Gallery',
  sortOrder: 5,
  feature: []
}

export const designGalleryFeature = {
  alias: 'Design Gallery',
  color: 'rgba(5, 228, 215, 0.7)',
  description: 'Display design from design gallery',
  icon: 'fa-regular fa-palette',
  id: DESIGN_GALLERY_FEATURE_ID,
  name: 'DesignGallery',
  sortOrder: 0
}

export const menuMakerFeature = {
  id: MENU_MAKER_FEATURE_ID,
  name: 'MenuMaker',
  alias: 'Menu Maker',
  description: 'Display Menu Maker data',
  icon: 'fa-regular fa-utensils',
  color: 'rgba(255, 183, 77, 1)',
  sortOrder: 0
}

export const disabledForDev = [DEVICE_CEC_FEATURE]

export const disabledForStaging = [DEVICE_CEC_FEATURE]

export const disabledForProd = [DEVICE_CEC_FEATURE]

export const defaultFeatureIconSettings = {
  link: {
    revert: true,
    isAutoHeight: true
  },
  embedded_link: {
    revert: true,
    isAutoHeight: true
  },
  image: {
    revert: true
  },
  emoji: {
    revert: true
  }
}

export default featureConstants

export const fileSubFeatures = [
  featureNames.Image,
  featureNames.Video,
  featureNames.Audio,
  featureNames.PDF
]

export const eulaStatuses = {
  published: 'Published',
  draft: 'Draft',
  archive: 'Archive'
}

export const defaultFeatureSetting = {
  settings: null
}

export const VIDEOWALL_MATRIX_DELIMITER = 'x'

export const videowallDisplaysToMatrix = {
  1: `1${VIDEOWALL_MATRIX_DELIMITER}1`,
  2: `1${VIDEOWALL_MATRIX_DELIMITER}2`,
  3: `1${VIDEOWALL_MATRIX_DELIMITER}3`,
  4: `2${VIDEOWALL_MATRIX_DELIMITER}2`,
  5: `1${VIDEOWALL_MATRIX_DELIMITER}5`,
  6: `2${VIDEOWALL_MATRIX_DELIMITER}3`,
  7: `1${VIDEOWALL_MATRIX_DELIMITER}7`,
  8: `2${VIDEOWALL_MATRIX_DELIMITER}4`,
  9: `1${VIDEOWALL_MATRIX_DELIMITER}9`,
  10: `2${VIDEOWALL_MATRIX_DELIMITER}5`,
  11: `1${VIDEOWALL_MATRIX_DELIMITER}11`,
  12: `3${VIDEOWALL_MATRIX_DELIMITER}4`
}

export const defaultVideowallLayoutOption = [{ value: null, label: 'N/C' }]

export const brightSignDisplayMatrixOptions = [
  {
    value: `1${VIDEOWALL_MATRIX_DELIMITER}1`,
    label: `1${VIDEOWALL_MATRIX_DELIMITER}1(Single Display)`
  },
  {
    value: `1${VIDEOWALL_MATRIX_DELIMITER}2`,
    label: `1${VIDEOWALL_MATRIX_DELIMITER}2`
  },
  {
    value: `1${VIDEOWALL_MATRIX_DELIMITER}3`,
    label: `1${VIDEOWALL_MATRIX_DELIMITER}3`
  },
  {
    value: `1${VIDEOWALL_MATRIX_DELIMITER}4`,
    label: `1${VIDEOWALL_MATRIX_DELIMITER}4`
  },
  {
    value: `2${VIDEOWALL_MATRIX_DELIMITER}1`,
    label: `2${VIDEOWALL_MATRIX_DELIMITER}1`
  },
  {
    value: `3${VIDEOWALL_MATRIX_DELIMITER}1`,
    label: `3${VIDEOWALL_MATRIX_DELIMITER}1`
  },
  {
    value: `4${VIDEOWALL_MATRIX_DELIMITER}1`,
    label: `4${VIDEOWALL_MATRIX_DELIMITER}1`
  },
  {
    value: `2${VIDEOWALL_MATRIX_DELIMITER}2`,
    label: `2${VIDEOWALL_MATRIX_DELIMITER}2`
  }
]

export const defaultFeatureSettings = {
  [featureNames.ZoneRestriction]: {
    ...defaultFeatureSetting,
    settings: {
      number_of_zones: MAX_RESTRICTED_ZONES
    }
  },
  [featureNames.Videowall]: {
    ...defaultFeatureSetting,
    settings: {
      number_of_displays: DEFAULT_VIDEO_WALL,
      video_wall_matrix: DEFAULT_VIDEO_WALL_MATRIX
    }
  }
}

export const defaultBrightSignVideowallSetting = {
  ...defaultFeatureSetting,
  settings: {
    number_of_displays: MAX_BRIGHT_SIGN_DISPLAYS,
    video_wall_matrix: videowallDisplaysToMatrix[MAX_BRIGHT_SIGN_DISPLAYS]
  }
}

export const enabledBrandingFeatures = [
  featureNames.Weather,
  featureNames.Quote,
  featureNames.Timer,
  featureNames.Clock,
  featureNames.Events,
  featureNames.Flightstats,
  featureNames.Socialwall,
  featureNames.Date,
  featureNames.Text,
  featureNames.RSSFeed,
  featureNames.DigitalBuildingDirectory,
  featureNames.QRCode,
  featureNames.CurrencyConverter,
  featureNames.Facebook,
  featureNames.News,
  featureNames.Button,
  featureNames.Instagram,
  featureNames.Radio,
  featureNames.StockQuote,
  featureNames.DirectionalSignage,
  featureNames.Slack,
  featureNames.LeaderBoard,
  featureNames.Flickr,
  featureNames.BoxOffice,
  featureNames.Twitter,
  featureNames.EmailContent,
  featureNames.Dockets,
  featureNames.LiveTransit,
  featureNames.ProgressMeter,
  featureNames.DonorWalls,
  featureNames.Profiles,
  featureNames.Trivia,
  featureNames.AlertSystem,
  featureNames.GoogleDocs,
  featureNames.SpaceBooking,
  featureNames.QueueManagement,
  featureNames.AIVideoCreator,
  featureNames.Reviews
]
