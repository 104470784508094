import { takeLatest } from 'redux-saga/effects'

import * as types from 'actions'
import deviceSaga from 'sagas/deviceSaga'

const actions = [
  types.PUT_DEVICE_ITEM_SUCCESS,
  types.DELETE_DEVICE_ITEM_SUCCESS
]

const deviceInvalidateCache = () => {
  return actions.map(action => {
    return takeLatest(action, deviceSaga.invalidateDeviceTags)
  })
}

export default deviceInvalidateCache
