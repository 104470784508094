import React, { useCallback, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

import { routeByName } from 'constants'
import PageContainer from 'components/PageContainer'
import useToggle from 'hooks/useToggle'
import {
  useLazyGetMediaLibraryItemQuery,
  useLazyGetMediaLibraryItemsQuery
} from 'api/mediaApi'
import SelectImageDialog from '../AccountSettings/SettingsDetails/SelectImageDialog'
import useDetermineMediaFeatureId from 'hooks/useDetermineMediaFeatureId'
import { getUrlPrefix } from 'utils'
import { PreviewGrids } from 'components/Canvas'
import DesignGroupLoader from '../DesignGallery/components/commonBlocks/DesignGroupLoader'
import handleBottomScroll from 'utils/handleBottomScroll'
import Scrollbars from 'components/Scrollbars'
import { featureNames } from 'constants/featureConstants'

const useStyles = makeStyles(({ colors, type, fontWeight, spacing }) => ({
  previewImageRoot: {
    borderRadius: '4px',
    opacity: 1,
    border: `1px solid ${colors.background.third[type]}`
  },
  landscapePlaceholder: {
    minHeight: '71px'
  },
  landscapeImageError: {
    height: '242px'
  },
  gridsRoot: {
    gridAutoRows: '242px',
    padding: `${spacing(1)}px ${spacing(1)}px 12px ${spacing(1)}px`,
    gap: spacing(2)
  },
  createDesignGallery: {
    width: '100%',
    height: '100%',
    background: colors.background.third[type],
    borderRadius: '4px'
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    cursor: 'pointer'
  },
  addIconWrapper: {
    border: `3px solid ${colors.highlight}`,
    width: '104px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px'
  },
  verticalIconDimensions: {
    width: '214px',
    height: '183px',
    marginTop: '16px'
  },
  addIcon: {
    color: colors.highlight
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: fontWeight.bold,
    color: colors.light,
    textAlign: 'center',
    marginBottom: spacing(1)
  }
}))

const sizeProps = {
  cols: 4,
  width: 392,
  height: 242
}

const LIMIT = 10
const PAGE = 1

const getImageHeight = () => sizeProps.height

const InteractiveWayfindListView = ({ t }) => {
  const featureId = useDetermineMediaFeatureId(featureNames.Wayfinding)
  const classes = useStyles()
  const history = useHistory()
  const newMapImageModal = useToggle()
  const [response, setResponse] = useState([])

  const [getMedia, { data, isFetching }] = useLazyGetMediaLibraryItemQuery()
  const [
    getWayfinds,
    {
      data: { data: mapData, meta } = {},
      isFetching: isMapFetching,
      isUninitialized: isMapUninitialized
    }
  ] = useLazyGetMediaLibraryItemsQuery()

  const handleContentScrollEnd = useCallback(() => {
    if (meta?.currentPage >= meta?.lastPage) {
      return
    }
    getWayfinds({
      featureId,
      page: meta.currentPage + 1,
      limit: LIMIT
    })
  }, [featureId, getWayfinds, meta?.currentPage, meta?.lastPage])

  useEffect(() => {
    if (mapData) {
      setResponse(prev => [...prev, ...mapData])
    }
    // eslint-disable-next-line
  }, [meta?.currentPage])

  useEffect(() => {
    if (data) {
      history.push(getUrlPrefix(routeByName.interactiveWayfind.add), {
        data,
        isFetching
      })
    }
  }, [data, history, isFetching])

  const handleNavigate = useCallback(() => {
    newMapImageModal.setTrue()
  }, [newMapImageModal])

  const handlePreviewClick = useCallback(
    item => {
      item.defaultComponent
        ? handleNavigate()
        : history.push(
            getUrlPrefix(routeByName.interactiveWayfind.goToEdit(item.id)),
            {
              item
            }
          )
    },
    [handleNavigate, history]
  )

  useEffect(() => {
    if (featureId) {
      getWayfinds({ featureId, page: PAGE, limit: LIMIT })
    }
  }, [featureId, getWayfinds])

  const renderCreateDesign = (
    <div className={classes.createDesignGallery} onClick={handleNavigate}>
      <div className={classes.contentWrapper}>
        <div
          className={classNames(
            classes.addIconWrapper,
            classes.verticalIconDimensions
          )}
        >
          <i className={classNames('fa-solid fa-plus', classes.addIcon)} />
        </div>
        <div className={classes.textWrapper}>
          <span>{t('Select first layer and create new map')}</span>
        </div>
      </div>
    </div>
  )

  const gridResponse = response?.length
    ? [{ defaultComponent: renderCreateDesign }, ...response]
    : [{ defaultComponent: renderCreateDesign }]

  return (
    <PageContainer pageTitle={t('Interactive Wayfind')} subHeader={false}>
      <Scrollbars
        style={{ height: 630 }}
        onUpdate={handleBottomScroll(handleContentScrollEnd)}
      >
        <PreviewGrids
          isVisible
          isLoading={isMapFetching || isMapUninitialized}
          grids={gridResponse}
          onPreviewClick={handlePreviewClick}
          cols={sizeProps.cols}
          loaderComponent={
            <DesignGroupLoader type="Landscape" sizeProps={sizeProps} />
          }
          imageErrorClass={classes.landscapeImageError}
          gridsRootClassName={classes.gridsRoot}
          rootClassName={classes.previewImageRoot}
          placeHolderClass={classes.landscapePlaceholder}
          withPreviewComponent={false}
          getImageHeight={getImageHeight}
        />
      </Scrollbars>
      <SelectImageDialog
        open={newMapImageModal.toggled}
        onCloseModal={newMapImageModal.setFalse}
        onSelect={({ imageId }) => {
          getMedia(imageId)
          newMapImageModal.setFalse()
        }}
      />
    </PageContainer>
  )
}

export default withTranslation('translations')(InteractiveWayfindListView)
