import React, { useCallback, useEffect, useMemo } from 'react'
import { TableLibraryCell } from 'components/TableLibrary'
import Tooltip from 'components/Tooltip'
import { CircularProgress, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import { useFetchSupportTokenMutation } from 'api/deviceApi'
import useSnackbar from 'hooks/useSnackbar'

const styles = () => {
  return {
    tokenText: {
      display: 'flex',
      flexDirection: 'column',
      textTransform: 'capitalize',
      alignItems: 'center',
      gap: '5px'
    },
    activeTokenText: {
      cursor: 'pointer'
    },
    agGridCell: {
      borderBottom: 'none'
    }
  }
}

const iconColor = {
  rotated: 'green',
  pending: 'amber',
  failed: 'gray'
}

const DeviceSupportTokenViewCell = ({
  data,
  classes,
  supportTokenError: supportTokenErrorState,
  setSupportTokenError,
  tooltiptitle,
  isAgGrid = false,
  t
}) => {
  const { supportTokenUUID, supportTokenStatus } = data

  const { showSnackbar } = useSnackbar()

  const checkWithHeader = {
    rotated: t('Token Verified'),
    pending: t('Token Assigned')
  }

  const [
    postFetchDevice,
    {
      data: devicesData = {},
      isLoading: isDeviceFetching,
      error: supportTokenError
    }
  ] = useFetchSupportTokenMutation()

  const handleGetToken = () => {
    if (
      supportTokenStatus !== 'rotated' ||
      Object.keys(supportTokenErrorState).length
    )
      return

    postFetchDevice({ uuid: supportTokenUUID })
  }

  useEffect(() => {
    if (!devicesData.supportToken || isDeviceFetching) return
    navigator.clipboard.writeText(devicesData.supportToken)
    showSnackbar(t('Copied to clipboard'), 'success')
  }, [devicesData.supportToken, isDeviceFetching, showSnackbar, t])

  const getErrorTitle = useMemo(
    () => ({
      429: t('Token copy access restricted for n minutes', {
        min: supportTokenError?.data?.availableIn
      }),
      404: t('Oops.. Something went wrong')
    }),
    [supportTokenError?.data?.availableIn, t]
  )

  const handleError = useCallback(
    (code, supportTokenError) => {
      showSnackbar(getErrorTitle[code] || getErrorTitle[404], 'error')
      setSupportTokenError(supportTokenError)
    },
    [getErrorTitle, setSupportTokenError, showSnackbar]
  )

  useEffect(() => {
    if (supportTokenError?.code) {
      handleError(supportTokenError?.code, supportTokenError)
    }
  }, [handleError, supportTokenError])

  const showheader =
    !!checkWithHeader[supportTokenStatus] &&
    !Object.keys(supportTokenErrorState).length

  return (
    <TableLibraryCell
      align="center"
      rootClassName={classNames({
        [classes.agGridCell]: isAgGrid
      })}
    >
      <Tooltip
        arrow
        withHeader={showheader}
        headerText={checkWithHeader[supportTokenStatus]}
        title={tooltiptitle}
      >
        <span
          className={classNames(classes.tokenText, {
            [classes.activeTokenText]:
              supportTokenStatus === 'rotated' && !!supportTokenUUID
          })}
          {...(!!supportTokenUUID && { onClick: handleGetToken })}
        >
          {isDeviceFetching && <CircularProgress size={30} thickness={5} />}
          <i
            className="fa-regular fa-lock-keyhole"
            style={{
              color: iconColor[supportTokenStatus] || iconColor.failed
            }}
          />

          {supportTokenStatus ? supportTokenStatus : t('N/A')}
        </span>
      </Tooltip>
    </TableLibraryCell>
  )
}

export default withStyles(styles)(DeviceSupportTokenViewCell)
