import { createAppApi } from 'services/api'
import { getApiParamsByOriginalUser } from 'utils/localStorage'
import apiTags from 'constants/apiTags'
import { createSuccessInvalidator, getCRUDEndpoints } from 'utils/apiUtils'
import { mediaQueryParamsHelper } from 'utils/queryParamsHelpers'

export const mediaApi = createAppApi('mediaQuery', {
  tagTypes: [apiTags.mediaLibrary],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      name: 'Media',
      url: `/media`,
      builder,
      tags: [apiTags.mediaLibrary]
    }),
    validateUrlIframeHeader: builder.mutation({
      query: (data = {}) => ({
        url: '/media/headerValidation',
        method: 'POST',
        data
      })
    }),
    copyMedias: builder.mutation({
      query: (data = {}) => {
        const { impersonated, ...restData } = data
        return {
          url: '/media/bulkCopy',
          method: 'POST',
          data: restData,
          ...(impersonated && getApiParamsByOriginalUser('/media/bulkCopy'))
        }
      }
    }),
    shareMedias: builder.mutation({
      query: (data = {}) => ({
        url: '/media/bulkShare',
        method: 'POST',
        data
      })
    }),
    publishDesigns: builder.mutation({
      query: (data = {}) => ({
        url: '/media/bulkPublishDesignGalleryMedia',
        method: 'PUT',
        data
      })
    }),
    publishDesign: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/media/${id}/publishDesignGalleryMedia`,
        method: 'PUT',
        data
      })
    }),
    bulkAssignDesignGalleryCategories: builder.mutation({
      query: (data = {}) => ({
        url: '/designGallery/design/bulkAssignDesignGalleryCategories',
        method: 'POST',
        data
      })
    }),
    updateDesignMetadata: builder.mutation({
      query: ({ designId, ...data }) => ({
        url: `designGallery/design/${designId}/metadata`,
        method: 'PUT',
        data
      })
    }),
    getMediaLibraryItems: builder.query({
      providesTags: createSuccessInvalidator([apiTags.mediaLibrary]),
      query: (params = {}) => ({
        method: 'GET',
        url: '/media',
        params: mediaQueryParamsHelper(params)
      })
    }),
    getMediaGridItems: builder.query({
      providesTags: createSuccessInvalidator([apiTags.mediaLibrary]),
      query: (params = {}) => ({
        method: 'GET',
        url: '/media/gridView',
        params: mediaQueryParamsHelper(params)
      })
    }),
    getMediaGridEmbeddedItems: builder.query({
      providesTags: createSuccessInvalidator([apiTags.mediaLibrary]),
      query: (params = {}) => ({
        method: 'GET',
        url: '/media/gridViewEmbedded',
        params: mediaQueryParamsHelper(params)
      })
    }),
    getMediaListItems: builder.query({
      providesTags: createSuccessInvalidator([apiTags.mediaLibrary]),
      query: (params = {}) => ({
        method: 'GET',
        url: '/media/listView',
        params: mediaQueryParamsHelper(params)
      })
    }),
    getMediaLibraryItem: builder.query({
      query: id => ({
        method: 'GET',
        url: `/media/${id}`
      })
    }),
    cloneMediaAndUpdate: builder.mutation({
      query: (data = {}) => ({
        url: `/media/cloneAndUpdate`,
        method: 'POST',
        data
      })
    }),
    getStockVideoItems: builder.query({
      providesTags: createSuccessInvalidator([apiTags.mediaLibrary]),
      query: (params = {}) => ({
        method: 'GET',
        url: '/media/stockVideo',
        params: mediaQueryParamsHelper(params)
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newItems) => {
        currentCache.meta = newItems?.meta
        if (newItems?.meta?.currentPage > 1) {
          currentCache.items.push(...newItems?.items)
        } else {
          currentCache.items = newItems?.items
        }
      }
    })
  })
})

export const {
  useValidateUrlIframeHeaderMutation,
  useCopyMediasMutation,
  useShareMediasMutation,
  usePublishDesignsMutation,
  usePublishDesignMutation,
  useBulkAssignDesignGalleryCategoriesMutation,
  useUpdateDesignMetadataMutation,
  useLazyGetMediaLibraryItemsQuery,
  useLazyGetMediaGridItemsQuery,
  useLazyGetMediaGridEmbeddedItemsQuery,
  useLazyGetMediaListItemsQuery,
  useGetMediaListItemsQuery,
  useLazyGetMediaLibraryItemQuery,
  useGetMediaLibraryItemQuery,
  usePostMediaMutation,
  usePutMediaMutation,
  useCloneMediaAndUpdateMutation,
  useGetStockVideoItemsQuery,
  useLazyGetStockVideoItemsQuery
} = mediaApi
