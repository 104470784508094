import apiTags from 'constants/apiTags'
import { createAppApi } from 'services/api'
import { getCRUDEndpoints } from 'utils/apiUtils'

export const customParametersApi = createAppApi('customParameters', {
  tagTypes: [apiTags.customParameter],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      builder,
      url: '/customParameters',
      name: 'CustomParameter',
      tags: [apiTags.customParameter],
      config: {
        withMerge: true
      }
    })
  })
})

export const {
  useGetCustomParametersQuery,
  useGetCustomParametersMergeQuery,
  usePostCustomParameterMutation,
  usePutCustomParameterMutation,
  useDeleteCustomParameterMutation,
  useLazyGetCustomParametersQuery
} = customParametersApi
