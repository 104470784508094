import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { _get } from 'utils/lodash'

import { getDeviceTimeWithOffset } from 'utils/getDeviceTimeWithOffset'
import { VIRTUAL_DEVICE_ALIAS } from 'constants/deviceConstants'

const useStyles = makeStyles(({ type, typography }) => ({
  text: {
    ...typography.darkAccent[type],
    lineHeight: '20px'
  },
  textSmall: {
    ...typography.subtitle[type]
  }
}))

const DeviceLocationViewCell = ({ device, t }) => {
  const classes = useStyles()

  return (
    <>
      <Typography component="span" className={classes.text}>
        {_get(device.deviceType, 'alias') !== VIRTUAL_DEVICE_ALIAS
          ? `${device.city}, ${device.state}`
          : t('Virtual Cloud')}
      </Typography>
      <Typography
        component="span"
        className={classNames(classes.text, classes.textSmall)}
      >
        {getDeviceTimeWithOffset(device, t)}
      </Typography>
    </>
  )
}

export default DeviceLocationViewCell
