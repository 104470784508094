import { takeLatest } from 'redux-saga/effects'

import * as types from 'actions'
import templateSaga from 'sagas/templateSaga'

const actions = [
  types.POST_TEMPLATE_SUCCESS,
  types.PUT_TEMPLATE_SUCCESS,
  types.CLONE_TEMPLATE_SUCCESS
]

const templateInvalidateCache = () =>
  actions.map(action => takeLatest(action, templateSaga.invalidateTemplateTags))

export default templateInvalidateCache
