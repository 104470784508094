import React, { useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import TypeCircle from 'components/TypeCircle'
import { DateTimeView } from 'components/TableLibrary'

const useStyles = makeStyles(() => ({
  dateTimeRoot: {
    textAlign: 'center'
  },
  dateTimeText: {
    color: '#fff !important'
  },
  dateTimeTextSmall: {
    color: '#fff !important'
  }
}))

const DeviceConnectionAgCell = ({ data }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { synchronousConnection, socketConnectionTime } = data

  const wsStatusColor = useMemo(() => {
    return synchronousConnection ? '#43a047' : '#ffb300'
  }, [synchronousConnection])

  const wsTooltip = useMemo(() => {
    return (
      !synchronousConnection &&
      socketConnectionTime && (
        <DateTimeView
          date={socketConnectionTime}
          rootClassName={classes.dateTimeRoot}
          textClass={classes.dateTimeText}
          textSmallClass={classes.dateTimeTextSmall}
        />
      )
    )
  }, [classes, synchronousConnection, socketConnectionTime])

  return (
    <Grid container justifyContent="center">
      <TypeCircle
        color={wsStatusColor}
        tooltipHeader={t('Last Connection')}
        tooltipTitle={wsTooltip}
      />
    </Grid>
  )
}

export default DeviceConnectionAgCell
