import React from 'react'

import ContentLoader from 'react-content-loader'

import { withTheme } from '@material-ui/core'
const generateRects = () => {
  const rects = []
  let y = 15
  for (let i = 0; i < 45; i++) {
    rects.push(y)
    y += 45
  }
  return rects
}
const toggles = generateRects()
const DeviceFeaturesLoader = ({ theme }) => (
  <ContentLoader
    style={{
      width: '100%',
      height: 1000
    }}
    backgroundColor={theme.palette[theme.type].loader.background}
    foregroundColor={theme.palette[theme.type].loader.foreground}
  >
    {toggles.map((y, index) => (
      <React.Fragment key={index}>
        <rect x="0" y={y} rx="6" ry="6" width="100" height="18" />
        <rect x="210" y={y} rx="6" ry="6" width="30" height="18" />
        <rect x="260" y={y} rx="6" ry="6" width="100" height="18" />
        <rect x="445" y={y} rx="6" ry="6" width="30" height="18" />
        <rect x="0" y={y + 30} rx="6" ry="6" width="100%" height="1" />
      </React.Fragment>
    ))}
  </ContentLoader>
)

export const ScheduleDeviceFeaturesLoader = withTheme(({ theme }) => (
  <ContentLoader
    style={{
      width: '100%',
      height: 1000
    }}
    backgroundColor={theme.palette[theme.type].loader.background}
    foregroundColor={theme.palette[theme.type].loader.foreground}
  >
    {toggles.map((y, index) => (
      <React.Fragment key={index}>
        <rect x="20" y={y} rx="6" ry="6" width="110" height="18" />
        <rect x="150" y={y} rx="6" ry="6" width="20" height="18" />
        <rect x="200" y={y} rx="6" ry="6" width="110" height="18" />
        <rect x="330" y={y} rx="6" ry="6" width="20" height="18" />
        <rect x="380" y={y} rx="6" ry="6" width="110" height="18" />
        <rect x="520" y={y} rx="6" ry="6" width="20" height="18" />
        <rect x="570" y={y} rx="6" ry="6" width="110" height="18" />
        <rect x="700" y={y} rx="6" ry="6" width="20" height="18" />
        <rect x="750" y={y} rx="6" ry="6" width="110" height="18" />
        <rect x="880" y={y} rx="6" ry="6" width="20" height="18" />
        <rect x="930" y={y} rx="6" ry="6" width="110" height="18" />
        <rect x="1060" y={y} rx="6" ry="6" width="20" height="18" />
        <rect x="1110" y={y} rx="6" ry="6" width="110" height="18" />
        <rect x="1240" y={y} rx="6" ry="6" width="20" height="18" />
        <rect x="1290" y={y} rx="6" ry="6" width="110" height="18" />
        <rect x="1420" y={y} rx="6" ry="6" width="20" height="18" />
      </React.Fragment>
    ))}
  </ContentLoader>
))

export const ScheduleAssignSectionOneColumnLoader = withTheme(({ theme }) => (
  <ContentLoader
    style={{
      width: '100%',
      height: 1000
    }}
    backgroundColor={theme.palette[theme.type].loader.background}
    foregroundColor={theme.palette[theme.type].loader.foreground}
  >
    {toggles.map((y, index) => (
      <React.Fragment key={index}>
        <rect x="20" y={y - 4.5} rx="50%" ry="50%" width="28" height="28" />
        <rect x="60" y={y} rx="6" ry="6" width="110" height="18" />
        <rect x="320" y={y} rx="6" ry="6" width="25" height="18" />
      </React.Fragment>
    ))}
  </ContentLoader>
))

export const AssignDeviceFeaturesLoader = withTheme(({ theme }) => (
  <ContentLoader
    style={{
      width: '100%',
      height: 1000
    }}
    backgroundColor={theme.palette[theme.type].loader.background}
    foregroundColor={theme.palette[theme.type].loader.foreground}
  >
    {toggles.map((y, index) => (
      <React.Fragment key={index}>
        <rect x="20" y={y} rx="6" ry="6" width="220" height="18" />
        <rect x="270" y={y} rx="6" ry="6" width="20" height="18" />
        <rect x="320" y={y} rx="6" ry="6" width="220" height="18" />
        <rect x="570" y={y} rx="6" ry="6" width="20" height="18" />
        <rect x="620" y={y} rx="6" ry="6" width="220" height="18" />
        <rect x="870" y={y} rx="6" ry="6" width="20" height="18" />
      </React.Fragment>
    ))}
  </ContentLoader>
))

export const ProofOfPlayDevicesLoader = withTheme(({ theme }) => (
  <ContentLoader
    style={{
      width: '100%',
      height: 1000
    }}
    backgroundColor={theme.palette[theme.type].loader.background}
    foregroundColor={theme.palette[theme.type].loader.foreground}
  >
    {toggles.map((y, index) => (
      <React.Fragment key={index}>
        <rect x="20" y={y} rx="6" ry="6" width="150" height="18" />
        <rect x="180" y={y} rx="6" ry="6" width="20" height="18" />
        <rect x="230" y={y} rx="6" ry="6" width="150" height="18" />
        <rect x="400" y={y} rx="6" ry="6" width="20" height="18" />
        <rect x="450" y={y} rx="6" ry="6" width="150" height="18" />
        <rect x="610" y={y} rx="6" ry="6" width="20" height="18" />
      </React.Fragment>
    ))}
  </ContentLoader>
))

export const BoxButtonLoader = withTheme(({ theme }) => {
  return (
    <ContentLoader
      style={{
        width: '100%',
        height: 1000
      }}
      backgroundColor={theme.palette[theme.type].loader.background}
      foregroundColor={theme.palette[theme.type].loader.foreground}
    >
      {toggles.map((_, rowIndex) => {
        const y = rowIndex * 60
        return (
          <React.Fragment key={rowIndex}>
            <rect x="20" y={y} rx="0" ry="0" width="136" height="45" />
            <rect x="200" y={y} rx="0" ry="0" width="136" height="45" />
          </React.Fragment>
        )
      })}
    </ContentLoader>
  )
})

export default withTheme(DeviceFeaturesLoader)
