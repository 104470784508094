import React, { useState } from 'react'

import useTokenError from 'hooks/useTokenError'
import DeviceSupportTokenViewCell from '../DeviceTable/DeviceSupportTokenViewCell'

const DeviceSupportTokenAgCell = ({ row, t }) => {
  const [supportTokenError, setSupportTokenError] = useState({})
  const [timeoutTimer, setTimeoutTimer] = useState(0)

  const { getTooltipTitle } = useTokenError(
    t,
    timeoutTimer,
    setTimeoutTimer,
    supportTokenError,
    setSupportTokenError
  )

  return (
    <DeviceSupportTokenViewCell
      data={row}
      setSupportTokenError={setSupportTokenError}
      supportTokenError={supportTokenError}
      tooltiptitle={getTooltipTitle(row)}
      isAgGrid
      t={t}
    />
  )
}

export default DeviceSupportTokenAgCell
