import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core'

import Tooltip from 'components/Tooltip'

const useStyles = makeStyles(() => ({
  iconExclamation: {
    marginRight: '5px',
    color: '#c03829'
  }
}))

const BrowserVersionCell = ({ browserVersion, osVersion, t }) => {
  const classes = useStyles()

  let showBrowserTooltip = false

  if (osVersion?.startsWith('Ubuntu') && browserVersion?.startsWith('Chrome')) {
    const chromeVersion = parseFloat(browserVersion.replace('Chrome', ''))
    if (chromeVersion < 90) showBrowserTooltip = true
  }

  let browser = browserVersion

  if (browserVersion?.includes('.')) {
    const arr = browserVersion?.split('.')
    browser = `${arr[0]}.${arr[1]}`
  }

  return showBrowserTooltip ? (
    <Tooltip
      arrow
      title={t(
        'This device has an outdated browser (playback canvas), which limits available security updates and also may affect playback performance'
      )}
      headerText={t('Browser is outdated')}
      withHeader
    >
      <span>
        <i
          className={classNames(
            'fa-solid fa-octagon-exclamation',
            classes.iconExclamation
          )}
        />
        {browser || t('N/A')}
      </span>
    </Tooltip>
  ) : (
    browser || t('N/A')
  )
}

export default BrowserVersionCell
