import React, { useMemo } from 'react'
import { Grid, Table, TableBody, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { _get } from 'utils/lodash'
import { withTranslation } from 'react-i18next'

import {
  TableLibraryCell,
  TableLibraryHead,
  TableLibraryRow
} from 'components/TableLibrary'
import { checkData } from 'utils/tableUtils'
import { conflictSources } from '../../../constants/api'
import { TextWithTooltip } from 'components/Typography'

const styles = ({ type, colors, typography, spacing, palette }) => ({
  cellPaddings: {
    padding: '10px 20px !important'
  },
  firstColCellPaddings: {
    padding: '10px 20px 10px 10px !important'
  },
  cellText: {
    ...typography.lightAccent[type],
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  table: {
    border: '1px solid ' + colors.border[type],
    marginBottom: 16
  },
  tableHeaderCell: {
    paddingTop: '8px !important',
    fontWeight: 700,

    '& span': {
      padding: '0px !important',
      cursor: 'unset !important'
    }
  },
  helperCardWrap: {
    backgroundColor: palette[type].helperCard.background,
    border: '1px solid ' + colors.highlight,
    borderRadius: '4px',
    padding: '13px 15px 20px',
    marginBottom: spacing(1)
  },
  helperTitle: {
    fontSize: '12px',
    color: colors.highlight,
    fontStyle: 'italic',
    fontWeight: 'bold',
    lineHeight: '15px'
  },
  helperText: {
    fontSize: '12px',
    color: colors.highlight,
    lineHeight: '15px',
    whiteSpace: 'pre-line'
  }
})

const initialColumns = [
  { id: 'deviceName', label: 'Device Name' },
  { id: 'mediaName', label: 'Media Name', align: 'center' },
  { id: 'feature', label: 'Feature', align: 'center' }
]

const columnWidth = {
  deviceName: 220,
  mediaName: 220,
  feature: 200
}

const FeatureDisabledContent = ({
  conflictSource,
  dataParser,
  classes,
  error,
  t
}) => {
  const parsedData = useMemo(() => {
    if (dataParser) {
      return dataParser(error)
    }

    const data = !Array.isArray(error?.data) ? _get(error, 'data', []) : []
    const returnData = []

    Object.keys(data).forEach(key => {
      Object.keys(data[key]).forEach(errorKey => {
        returnData.push({
          deviceName: key,
          mediaName: errorKey,
          feature: data[key][errorKey][0]
        })
      })
    })
    return returnData
  }, [error, dataParser])

  const getHelperText = conflictSource => {
    switch (conflictSource) {
      case conflictSources.playlist:
        return t(
          'Please remove this entity from the device schedule or remove the specified content from the entity.',
          { entity: t('playlist') }
        )
      case conflictSources.smartPlaylist:
        return t(
          'Please remove this entity from the device schedule or remove the specified content from the entity.',
          { entity: t('smart playlist') }
        )
      case conflictSources.interactivePlaylist:
        return t(
          'Please remove this entity from the device schedule or remove the specified content from the entity.',
          { entity: t('interactive playlist') }
        )
      case conflictSources.createTemplate:
        return t(
          'Please remove this entity from the device schedule or remove the specified content from the entity.',
          { entity: t('template') }
        )
      default:
        return t('note feature not enabled modal')
    }
  }

  return (
    <>
      <Table className={classes.table}>
        <TableLibraryHead
          noType={true}
          editRows={false}
          disableSort
          rowCount={parsedData.length}
          columns={initialColumns}
          columnWidth={columnWidth}
          headerCellClassName={classes.tableHeaderCell}
        />
        <TableBody>
          {parsedData.map((row, index) => (
            <TableLibraryRow
              key={`feature-disable-${index}`}
              hover
              tabIndex={-1}
            >
              <TableLibraryCell className={classes.firstColCellPaddings}>
                <TextWithTooltip
                  maxWidth={180}
                  rootClassName={classes.cellText}
                >
                  {checkData(row.deviceName)}
                </TextWithTooltip>
              </TableLibraryCell>

              <TableLibraryCell className={classes.cellPaddings} align="center">
                <TextWithTooltip
                  maxWidth={180}
                  rootClassName={classes.cellText}
                >
                  {checkData(row.mediaName)}
                </TextWithTooltip>
              </TableLibraryCell>
              <TableLibraryCell className={classes.cellPaddings} align="center">
                <TextWithTooltip
                  maxWidth={160}
                  rootClassName={classes.cellText}
                >
                  {checkData(row.feature)}
                </TextWithTooltip>
              </TableLibraryCell>
            </TableLibraryRow>
          ))}
        </TableBody>
      </Table>
      <Grid item xs={12} className={classes.helperCardWrap}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.helperTitle}>{t('Note')}</Typography>
            <Typography className={classes.helperText}>
              {getHelperText(conflictSource)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default withTranslation('translations')(
  withStyles(styles)(FeatureDisabledContent)
)
