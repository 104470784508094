import { useCallback, useEffect } from 'react'
import moment from 'moment'
import { DATE_VIEW_FORMAT } from 'constants/dateTimeFormats'

const supportTokenStatus = {
  rotated: 'rotated',
  pending: 'pending',
  failed: 'failed'
}

const useTokenError = (
  t,
  timeoutTimer,
  setTimeoutTimer,
  supportTokenError,
  setSupportTokenError
) => {
  useEffect(() => {
    if (!timeoutTimer) return

    const intervalId = setInterval(() => {
      setTimeoutTimer(prev => (prev > 0 ? prev - 1 : 0))
    }, 1000 * 60)

    return () => clearInterval(intervalId)
  }, [setTimeoutTimer, timeoutTimer])

  const removeRestriction = useCallback(() => {
    setTimeout(() => {
      setSupportTokenError({})
    }, 1000 * 60 * 2)
    return t('Token copy access restricted for n minutes', {
      min: 2
    })
  }, [setSupportTokenError, t])

  const getErrorTitle = useCallback(
    errObj => {
      if (timeoutTimer) {
        return t('Token copy access restricted for n minutes', {
          min: timeoutTimer
        })
      }

      const { code, data } = errObj
      if (code === 429) {
        if (!data?.availableIn) return removeRestriction()
        const endDateTime = data?.availableIn
        const minutes = Math.floor(endDateTime / 60)
        const seconds = endDateTime % 60

        if (minutes === 0 && seconds === 0) {
          setSupportTokenError({})
        }

        setTimeoutTimer(minutes)
        return t('Token copy access restricted for n minutes', {
          min: `${minutes} min ${seconds} sec`
        })
      }
      if (code === 403) {
        return t('Your IP is unauthorized')
      }

      return removeRestriction()
    },
    [timeoutTimer, t, setTimeoutTimer, setSupportTokenError, removeRestriction]
  )

  // Memoized function to determine tooltip title based on status
  const getTooltipTitle = useCallback(
    row => {
      const status =
        supportTokenError.code ||
        row.supportTokenStatus ||
        supportTokenStatus.failed

      switch (status) {
        case supportTokenStatus.rotated:
        case supportTokenStatus.pending:
          return t('Last Updated t', {
            time: moment(row.supportTokenUpdatedAt).format(DATE_VIEW_FORMAT)
          })
        case supportTokenStatus.failed:
          return t('No Token Assigned')
        case 429:
        case 403:
          return getErrorTitle(supportTokenError)
        default:
          return getErrorTitle(supportTokenError)
      }
    },
    [supportTokenError, t, getErrorTitle]
  )

  return { getErrorTitle, getTooltipTitle }
}

export default useTokenError
