import usePermissions from 'hooks/api/usePermissions'

const PermissionWrapper = ({ children, permissions }) => {
  const { getPermissionByName } = usePermissions()

  if (!getPermissionByName(...permissions)) return null

  return children
}

export default PermissionWrapper
