import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Tooltip from 'components/Tooltip'
import { isOutdatedOSVersion } from 'utils/deviceUtils'

const useStyles = makeStyles(() => ({
  iconExclamation: {
    marginRight: '5px',
    color: '#c03829'
  }
}))

const DeviceOsVersionCell = ({ device }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { osVersion } = device
  const showTooltip = isOutdatedOSVersion(device)

  return showTooltip ? (
    <Tooltip
      arrow
      title={t(
        'This device has an outdated Operating System (OS), which limits available security updates and also may affect playback performance.'
      )}
      headerText={t('OS Version is outdated')}
      withHeader
    >
      <span>
        <i
          className={classNames(
            'fa-solid fa-octagon-exclamation',
            classes.iconExclamation
          )}
        />
        {osVersion || t('N/A')}
      </span>
    </Tooltip>
  ) : (
    osVersion || t('N/A')
  )
}

export default DeviceOsVersionCell
