import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ palette, fontSize, type, typography }) => ({
  root: {
    color: palette[type].tableLibrary.body.cell.color,
    fontSize: fontSize.primary,
    fontFamily: typography.fontFamily
  }
}))

const mapAlignValueToFlex = value => {
  switch (value) {
    case 'left':
      return 'flex-start'
    case 'right':
      return 'flex-end'
    default:
      return value
  }
}

const WrapperCell = ({ colDef, children }) => {
  const classes = useStyles()

  const { align = 'flex-start', direction = 'row' } = colDef

  return (
    <Grid
      container
      className={classes.root}
      justifyContent={mapAlignValueToFlex(align)}
      align={align}
      direction={direction}
    >
      {children}
    </Grid>
  )
}

export default WrapperCell
