import React, { useCallback } from 'react'
import { withTranslation } from 'react-i18next'
import { Grid, Paper, withStyles } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { getDeleteConfirmationMessage } from 'utils/generalUtils'
import { TextWithTooltip } from '../Typography'
import { TableLibraryRowActionButton } from '../TableLibrary'
import getUserRoleLevel from 'utils/getUserRoleLevel'
import { Checkbox } from 'components/Checkboxes'

const styles = theme => {
  const { palette, type, fontSize, lineHeight, fontWeight } = theme
  return {
    root: {
      padding: 10,
      margin: '5px 10px',
      borderRadius: 6,
      borderLeft: '5px solid #3983ff',
      border: `1px solid ${palette[type].tagCard.border}`,
      backgroundColor: palette[type].tagCard.background,
      boxShadow: palette[type].tagCard.boxShadow
    },
    label: {
      fontSize: fontSize.primary,
      fontWeight: fontWeight.bold,
      lineHeight: lineHeight.primary,
      color: palette[type].tagCard.label.color
    },
    checkboxWrap: {
      marginRight: 20
    }
  }
}

const RoomCard = ({
  t,
  room,
  classes,
  selected,
  onDelete,
  onToggleSelect,
  permissions,
  showConfirmation
}) => {
  const handleDeleteClick = useCallback(() => {
    showConfirmation(getDeleteConfirmationMessage(room.title, t), () => {
      onDelete(room)
    })
  }, [showConfirmation, room, onDelete, t])

  const handleClickSelect = useCallback(() => {
    onToggleSelect(room.id)
  }, [room.id, onToggleSelect])

  return (
    <Paper className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container alignItems="center">
            <Grid
              item
              className={classes.checkboxWrap}
              onClick={handleClickSelect}
            >
              <Checkbox checked={selected} />
            </Grid>
            <Grid item>
              <TextWithTooltip rootClassName={classes.label} maxWidth={100}>
                {room.title}
              </TextWithTooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <TableLibraryRowActionButton
                actionLinks={[
                  {
                    label: t('Edit action'),
                    to: `/${getUserRoleLevel()}/room-library/edit/${room.id}`,
                    data: { room },
                    render: permissions.update
                  },
                  { divider: true },
                  {
                    label: t('Delete Room action'),
                    icon: 'fa-regular fa-trash-can',
                    clickAction: handleDeleteClick,
                    render: permissions.delete
                  }
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withTranslation('translations')(
  withStyles(styles)(withSnackbar(RoomCard))
)
