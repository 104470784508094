import { takeLatest } from 'redux-saga/effects'

import * as types from 'actions'
import deviceSaga from 'sagas/deviceSaga'

const actions = [types.POST_APP_VERSION_SUCCESS, types.PUT_APP_VERSION_SUCCESS]

const appVersionInvalidateCache = () => {
  return actions.map(action => {
    return takeLatest(action, deviceSaga.invalidateAppVersionTags)
  })
}

export default appVersionInvalidateCache
